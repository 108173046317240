import { all, put, call, fork, take } from 'redux-saga/effects';
import * as api from '../../utils/api';
import {
  LOGIN_REQUEST,
  CHECKEXPIRE_REQUEST,
  REGISTER_REQUEST,
  PULL_USER_INFO_REQUEST,
  authActionCreators,
} from './actions';

export function* asyncUserLogin({ payload, resolve, reject }) {
  try {
    const response = yield call(api.login, payload);
    if (response.data) {
      yield put(authActionCreators.loginSuccess(response.data));
    }
    resolve(response.data);
  } catch (err) {
    reject(err)
  }
}

export function* watchUserLogin() {
  while (true) {
    const action = yield take(LOGIN_REQUEST);
    yield* asyncUserLogin(action);
  }
}

export function* asyncUserExpire({ payload, resolve, reject }) {
  try {
    const response = yield call(api.checkExpire, payload);
    if (response.data) {
      yield put(authActionCreators.checkExpireSuccess(response.data));
    }
    resolve(response.data);
  } catch (err) {
    reject(err)
  }
}

export function* watchUserExpire() {
  while (true) {
    const action = yield take(CHECKEXPIRE_REQUEST);
    yield* asyncUserExpire(action);
  }
}

export function* asyncUserRegister({ payload, resolve, reject }) {
  try {
    const response = yield call(api.register, payload);
    if (response.data) {
      yield put(authActionCreators.registerSuccess(response.data));
    }
    resolve(response.data);
  } catch (err) {
    reject(err)
  }
}

export function* watchUserRegister() {
  while (true) {
    const action = yield take(REGISTER_REQUEST);
    yield* asyncUserRegister(action);
  }
}

export function* asyncGetUser({ payload, resolve, reject }) {
  try {
    const response = yield call(api.getUser, payload);
    if (response.data) {
      yield put(authActionCreators.getUserSuccess(response.data));
    }
    resolve(response.data);
  } catch (err) {
    reject(err)
  }
}

export function* watchGetUser() {
  while (true) {
    const action = yield take(PULL_USER_INFO_REQUEST);
    yield* asyncGetUser(action);
  }
}

export default function* () {
  yield all([
    fork(watchUserRegister),
    fork(watchUserLogin),
    fork(watchUserExpire),
    fork(watchGetUser),
  ]);
}
