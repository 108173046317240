import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { authActionCreators } from '../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header';
import './style.scss'
import * as api from '../../utils/api';
import { globalAction } from '../../store/actions';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar'
import * as mapUtils from '../../components/MapView/MapUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils'

const useStyles = makeStyles(theme => ({
	button: {
    fontSize: 15,
    fontWeight: 400,
    marginTop: 20
  },
}));

const maxFileSize = 1 * 1024 * 1024 * 1024;

function useReferredState(initialValue) {
  const [state, setState] = React.useState(initialValue);
  const reference = React.useRef(state);

  const setReferredState = value => {
      reference.current = value;
      setState(value);
  };

  return [reference, setReferredState];
}


function DownloadLayout(props) {
  const classes = useStyles();

  const [datasetItem, setDatasetItem] = React.useState(null);
  const [downloading, setDownloading] = useReferredState(0); // 0 : initial, 1: downloading, 2: downloaded, 3: failed
  const [downloadProgress, setDownloadProgress] = React.useState(0);
  const [progressLabel, setProgressLabel] = React.useState("");
  const [downloadFileName, setDownloadFilename] = React.useState("");
  const [directDownloadExist, setDirectDownloadExist] = React.useState(false);

  let dataset_id;

	if (api.getParameterByName("dataset_id") && api.getParameterByName("dataset_id") !== "") {
		dataset_id = api.getParameterByName("dataset_id");
	}

  if (!dataset_id || dataset_id === "") {
    window.close();
  }

  useEffect(() => {
		return () => {
			setDownloading(0);
		}
	}, []);

  useEffect(() => {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
      if (props.userInfo.first_name !== "") {
        if (!api.canEditItem(props.userInfo)) {
          window.close();
        }
        else {
          getDatasetInfo();
        }
      }
    }
    else {
      window.close();
    }
	}, [props.userInfo]);

  async function getDatasetInfo() {
    let userToken = localStorage.getItem("userToken");
    let response = await api.getDatasetByID({token: userToken, id: dataset_id});

    if (response.data && !response.data.error && response.data.dataset) {
      setDatasetItem(response.data.dataset);
    }
    else {
      window.close();
    }
  }

  async function downloadDataset() {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
        let response = await api.getFilesByDatasetID({
            dataset_id: dataset_id,
            token: userToken});
        if (response.data && !response.data.error) {
            let files = response.data;
            if (!files || files.length === 0) {
                toast("Empty Dataset");
            }
            else {
              setDownloading(1);
              setDownloadProgress(0);
              setDirectDownloadExist(false);
              downloadFiles(new JSZip(), files, 0, 0, 0);
            }
        }
        else {
            window.close();
        }
    }
    else {
        window.close();
    }
  }

  function downloadDirectLink(fileName, url) {
    setDirectDownloadExist(true);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    toast("Downloading as " + fileName + " in browser directly.");
  }

  function saveFileAsZip(fileName, jsZip, callback) {
    toast("Downloading as " + fileName + " zip in browser.");
    jsZip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, fileName);
      callback();
    });
  }

  function downloadFiles(jsZip, files, index, downloadIndex, fileStorage) {
    if (downloading.current !== 1) return;
    if (index === files.length) {
      if (fileStorage > 0) {
        let fileName = datasetItem.name + '_' + downloadIndex + '.zip';
        saveFileAsZip(fileName, jsZip, function() {
          setDownloading(2);
        });
      }
      else {
        setDownloading(2);
      }
      return;
    }

    const url = encodeURI(mapUtils.getSubPath(datasetItem) + "/datasets/"+ datasetItem.id + "/" + files[index].name);

    if (datasetItem.datatype === "pointcloud" || datasetItem.datatype === "tiff") {
      setDownloadFilename(files[index].name);
      downloadDirectLink(files[index].name, url);
      setTimeout(function () {
        downloadFiles(jsZip, files, index + 1, downloadIndex, fileStorage);
      }, 5000);
    }
    else {
      if (fileStorage >= maxFileSize) {
        let fileName = datasetItem.name + '_' + downloadIndex + '.zip';
        saveFileAsZip(fileName, jsZip, function() {
          downloadFiles(new JSZip(), files, index, downloadIndex + 1, 0);
        });
      }
      else {
        setDownloadFilename(files[index].name);
        let isCalculatedSize = false;
        JSZipUtils.getBinaryContent(url, {
          progress: function(evt) {
            let percentage = parseInt(index * 100 / files.length + evt.percent / files.length);
            setDownloadProgress(percentage);
            setProgressLabel(percentage + "%");
            if (!isCalculatedSize) {
              isCalculatedSize = true;
              fileStorage += evt.total;
            }
          },
          callback: function(err, data) {
            if (downloading.current !== 1) return;
            if (!err) {
              jsZip.file(files[index].name, data, {binary:true});
            }
            else {
              toast("Download failed zip file " + files[index].name);
              console.log(err);
            }
            downloadFiles(jsZip, files, index + 1, downloadIndex, fileStorage);
          }
        });
      }
    }
  }

  return (
    <div>
      <Header
				hasSearch={false}
			/>
      <div>
        {datasetItem?
        <div className='download_layout'>
          <div className='download_label'>
            Download Dataset "{datasetItem.name}"
          </div>
          {downloading.current !== 0?
          <div className="download-progress-contaner">
            {downloading.current === 1?
            <ProgressBar now={downloadProgress} label={progressLabel}/>
            :null 
            }
            {downloading.current === 1?
            <div className='download-file-name'>
              {downloadFileName}
            </div>
            :downloading.current === 2?
            <div className='download-status'>
              {directDownloadExist?
              "Download Completed! But some files are downloading in browser yet."
              :
              "Download Completed!"
              }
              
            </div>
            :
            <div className='download-status'>
              Download Failed! Please retry to reload.
            </div>
            }
            
          </div>
          :
          <Button
            variant="contained"
            color="primary"
            onClick={downloadDataset}
            className={classes.button}
          >
            Download
          </Button>
          }
        </div>
        :null}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  userInfo: state.global.userInfo 
});

DownloadLayout.propTypes = {
}

export default compose(
  withRouter,
  connect(mapStateToProps, authActionCreators),
  connect(mapStateToProps, globalAction),
)(DownloadLayout);