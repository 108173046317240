import { createAction } from 'redux-actions';
import { createPromiseAction } from '../../utils/createPromiseAction';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const CHECKEXPIRE_REQUEST = 'auth/CHECKEXPIRE_REQUEST';
export const CHECKEXPIRE_SUCCESS = 'auth/CHECKEXPIRE_SUCCESS';
export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_REQUEST = 'auth/REGISTER_REQUEST';
export const PULL_USER_INFO_REQUEST = 'auth/PULL_USER_INFO_REQUEST';
export const PULL_USER_INFO_SUCCESS = 'auth/PULL_USER_INFO_SUCCESS';

export const authActionCreators = {
    login: createPromiseAction(LOGIN_REQUEST),
    loginSuccess: createAction(LOGIN_SUCCESS),
    checkExpire: createPromiseAction(CHECKEXPIRE_REQUEST),
    checkExpireSuccess: createAction(CHECKEXPIRE_SUCCESS),
    register: createPromiseAction(REGISTER_REQUEST),
    registerSuccess: createAction(REGISTER_SUCCESS),
    logout: createAction(LOGOUT_REQUEST),
    getUser: createPromiseAction (PULL_USER_INFO_REQUEST),
    getUserSuccess: createAction(PULL_USER_INFO_SUCCESS),
};