import React, { useState, useEffect } from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import configureStore, { history } from './store/configure';
import MainLayout from './containers/MainLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import logo from './logo.png';

export const store = configureStore();
export let DataContext = React.createContext();

function App() {
	const [title] = useState("PoleHawk");

	useEffect(() => {
		document.title = title;
		document.logo = logo;
	}, []);

	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<MainLayout />
				<ToastContainer
					position="bottom-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					draggable={false}
					pauseOnHover={false}
				/>
			</ConnectedRouter>
		</Provider>
	);
}

export default App;
