
import { createAction } from 'redux-actions';

export const SELECT_PROJECT = 'project/SELECT_PROJECT';
export const ALL_PROJECTS = 'project/ALL_PROJECTS';
export const ALL_DATASETS = 'project/ALL_DATASETS';
export const SET_MATERIAL = 'project/SET_MATERIAL';
export const SET_POINTSIZE = 'project/SET_POINTSIZE';
export const SET_DATASET = 'project/SET_DATASET';
export const SET_SORT_TYPE = 'project/SET_SORT_TYPE';
export const SET_SORT_ORDER = 'project/SET_SORT_ORDER';
export const ALL_ASSETS = 'project/ALL_ASSETS';
export const ANALYTICS_INFO = 'project/ANALYTICS_INFO';
export const LATEST_ANALYTICS = 'project/LATEST_ANALYTICS';
export const SET_LABEL_PROPERTY = 'project/SET_LABEL_PROPERTY';
export const SET_DEFAULT_CLASSIFICATION = 'project/SET_DEFAULT_CLASSIFICATION';

// Adding Pole
export const SET_ADDING_POSITION = 'project/SET_ADDING_POSITION';

export const projectAction = {
    selectProject : createAction(SELECT_PROJECT),
    setAllProjects : createAction(ALL_PROJECTS),
    setAllDatasets : createAction(ALL_DATASETS),
    setPointSize : createAction(SET_POINTSIZE),
    setMaterial : createAction(SET_MATERIAL),
    setDatasetList : createAction(SET_DATASET),
    setSortType : createAction(SET_SORT_TYPE),
    setSortOrder : createAction(SET_SORT_ORDER),
    setLabelProperty : createAction(SET_LABEL_PROPERTY),
    setAllAssets : createAction(ALL_ASSETS),
    setAnalyticsInfo : createAction(ANALYTICS_INFO),
    setLatestAnalytics : createAction(LATEST_ANALYTICS),
    setAddingPosition : createAction(SET_ADDING_POSITION),
    setClassificationList : createAction(SET_DEFAULT_CLASSIFICATION),
};