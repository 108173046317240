import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import { Pagination } from '@mui/material';
import * as mapUtils from '../../components/MapView/MapUtils';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { Checkbox, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import ProgressBar from 'react-bootstrap/ProgressBar'

import './style.scss';
import { toast } from 'react-toastify';

const Cesium = window.Cesium;

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        overflow: "auto"
	},
    info_section: {
        position: 'absolute',
        marginTop: '20px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 100px)',
    },
    panel_label : {
        color: "#2196f3",
        fontSize: 20,
        marginTop: 3,
        textAlign: 'left',
    },
    project_label: {
        color: "#9e9e9e",
        fontSize: 16,
        marginTop: 3,
        textAlign: 'left',
    },
    circular_progress: {
        width: 40,
        height: 40,
        position: 'absolute',
    },
    button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
    asset_search_name: {
        width: "calc(100% - 220px)",
        marginLeft: 15,
    },
    asset_search_name_viewer: {
        width: "calc(100% - 100px)",
        marginLeft: 15,
    }
}));

function AssetPanel(props) {
	const classes = useStyles();

    const [assetSearch, setAssetSearch] = React.useState("");
    const [currentAssetPage, setCurrentAssetPage] = React.useState(1);
    const [totalAssetPage, setTotalAssetPage] = React.useState(1);
    const [itemCountPage, setItemCountPage] = React.useState(50);

    const [showDetail, setShowDetail] = React.useState(false);
    const [assetDetail, setAssetDetail] = React.useState("");

    const [kmlLabelProperties, setKMLLabelProperties] = React.useState(["none", "default"]);
    const [currentLabelProperty, setCurrentLabelProperty] = React.useState("none");
    const [isShowingPropertyLabel, setShowingPropertyLabel] = React.useState(false);

    const [isAddingPole, setAddingPole] = React.useState(false);
	const [addingPoleName, setAddingPoleName] = React.useState("");
    const [addingPoleDesc, setAddingPoleDesc] = React.useState("");
    const [inpsectLevel, setInspectLevel] = React.useState(0);

    const [isAddingFromDataset, setAddingFromDataset] = React.useState(false);
    const [selectedKMLID, setSelectedKMLID] = React.useState(-1);

    const [addingAssets, setAddingAssets] = React.useState([]);
    const [addingMessage, setAddingMessage] = React.useState("");
    const [addingAssetIndex, setAddingAssetIndex] = React.useState(-1);
    const [assetDataName, setAssetDataName] = React.useState("");

    function redirectToLogin() {
        props.history.replace("/");
    }

    useEffect(() => {
		setTotalAssetPage(Math.ceil(props.allAssets.length / itemCountPage));

        let labelProperties = ["none", "default"];
        for (let i = 0; i < props.allAssets.length; i ++) {
            let detail = props.allAssets[i].detail;
            if (detail && detail !== "") {
                let properties = mapUtils.parseDescription(detail);
                for (let prIndex = 0; prIndex < properties.length; prIndex ++) {
                    if (labelProperties.indexOf(properties[prIndex]) >= 0) {
                        continue;
                    }
                    labelProperties.push(properties[prIndex]);
                }
            }
        }

        setKMLLabelProperties(labelProperties);
	}, [props.allAssets]);

    useEffect(() => {
		let filterList = props.allAssets.filter(function(value) {
			return isInListBySearch(value);
		});
		let totalPage = Math.ceil(filterList.length / itemCountPage);
		setTotalAssetPage(totalPage);

		if (currentAssetPage > totalPage) {
			setCurrentAssetPage(1);
		}
	}, [assetSearch]);

    function getPoleAutoName() {
        const filterPoleName = props.allAssets.filter(function(value) {
            return value.name.toLowerCase().indexOf("pole") === 0;
        });

        let maxID = 1;
        for (let i = 0; i < filterPoleName.length; i ++) {
            let id = parseInt(filterPoleName[i].name.substring(4));

            if (!isNaN(id)) {
                maxID = Math.max(id + 1, maxID);
            }
        }

        return "Pole" + maxID;
    }

    useEffect(() => {
		if (window.addingPole && props.addingPosition) {
            // Show Pole Dialog
            window.addingPole = false;
            setAddingPole(true);
            setAddingPoleName(getPoleAutoName());
            setAddingPoleDesc("");
        }
	}, [props.addingPosition]);

    function onChangeAssetSearch(evt) {
        setAssetSearch(evt.target.value);
    }

    function isAllVisible() {
        return props.allAssets.findIndex( asset => Boolean(asset.visible) === true) >= 0;
    }

    function deleteAllAssetsByDialog() {
        props.onDeleteDialog("Clear Assets", "Do you want to clear all assets?", function() {
            deleteAllAsset();
        })
    }

    async function deleteAllAsset() {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.deleteAllAssets({
                token: userToken});
            if (response.data && !response.data.error) {
                props.setAllAssets([]);
            }
            else {
                redirectToLogin();
            }
        }
    }

    async function switchVisible() {
        let visible = isAllVisible();
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.updateAssetVisibleAll({
                visible: !visible, 
                token: userToken});
            if (response.data && !response.data.error) {
                let userAssets = JSON.parse(JSON.stringify(props.allAssets));
                for (let i = 0; i < userAssets.length; i ++) {
                    userAssets[i].visible = !visible;
                }
                props.setAllAssets(userAssets);
            }
            else {
                redirectToLogin();
            }
        }
    }

    function chooseLabel() {
        setCurrentLabelProperty(props.labelProperty);
        setShowingPropertyLabel(true);
    }

    function addPoleManual() {
        props.setAddingPosition(undefined);
        window.addingPole = true;
    }

    function addPole() {
        const pageItems = props.allDatasets.filter(function(value) {
            return value.datatype === "kml"
        });

        if (pageItems.length > 0) {
            setSelectedKMLID(-1);
            setAddingFromDataset(true);
        }
    }

    function isInListBySearch(asset) {
		if (assetSearch === "") return true;

        let currentLabel = asset.name;
			
        if (props.labelProperty !== "default" && props.labelProperty !== "none") {
            if (asset.detail && asset.detail !== "") {
                currentLabel = asset.detail;
            }
        }
		
		return currentLabel.toLowerCase().indexOf(assetSearch.toLowerCase()) !== -1;
	}

    function flyToPole(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            let item = window.loadedAssets.get(parseInt(event.currentTarget.dataset.id));
            if (item && item.object) {
                if (item.object.show) {
                    mapUtils.flyToEntity(window.MAIN_CESIUM_VIEWER, item.object);
                }
                else {
                    let index = props.allAssets.findIndex( asset => asset.id + "" === event.currentTarget.dataset.id );

                    if (index >= 0) {
                        let positions = JSON.parse(props.allAssets[index].positions);

                        mapUtils.flyPosition(window.MAIN_CESIUM_VIEWER, {
                            longitude: Cesium.Math.toDegrees(positions[0]),
                            latitude: Cesium.Math.toDegrees(positions[1]),
                            altitude: positions[2] + 500
                        });

                    }
                }
            }
        }
    }

    function showInfoPole(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {    
            let index = props.allAssets.findIndex( asset => asset.id + "" === event.currentTarget.dataset.id );

            if (index >= 0) {
                let detail = "<div>Name: " + props.allAssets[index].name + "</div>";
                if (props.allAssets[index].positions) {
                    let positions = JSON.parse(props.allAssets[index].positions);

                    if (positions.length > 0) {
                        detail += "<div>Longitude: " + Cesium.Math.toDegrees(positions[0]).toFixed(5) + "&#xb0;</div>";
                    }
                    if (positions.length > 1) {
                        detail += "<div>Latitude: " + Cesium.Math.toDegrees(positions[1]).toFixed(5) + "&#xb0;</div>";
                    }
                }
                detail += (props.allAssets[index].detail !== null)?"<div>Description: " + props.allAssets[index].detail + "</div>":"";
                if (detail && detail !== "") {
                    setAssetDetail(detail);
                    setShowDetail(true);
                }

            }
        }
    }

    function getNameByProperty(asset) {
        let currentLabel = asset.name;
			
        if (props.labelProperty !== "default" && props.labelProperty !== "none") {
            let labelText = mapUtils.getDescriptionValue(asset.detail, props.labelProperty);
            if (labelText !== "") {
                currentLabel = labelText;
            }
        }

        return currentLabel;
    }

    function getAssetList() {
        const pageItems = props.allAssets.filter(function(value) {
            return isInListBySearch(value);
        }).slice((currentAssetPage - 1) * itemCountPage, currentAssetPage * itemCountPage);

        const items = pageItems.map((item, index) => (
            <div key={index}>
                <div className={`asset_item ${'asset_item_' + item.inspect}`} title={item.name}>
                    <div className={"asset_icon"}>
                        <div className='icon asset_pole_icon'></div>
                    </div>
                    <div className='asset_item_name'>
                        {getNameByProperty(item)}
                    </div>
                    <div className='asset_item_menu'>
                        <div className={"asset_icon can_hover_icon"} onClick={flyToPole} data-id={item.id}>
                            <div className='icon asset_fly_icon'></div>
                        </div>
                        <div className={"asset_icon can_hover_icon"} onClick={showInfoPole} data-id={item.id}>
                            <div className='icon asset_info_icon'></div>
                        </div>
                    </div>
                </div>
            </div>
        ));

        return (
            <div className="asset_scroll_list">
                {items}
            </div>
        );
    }

    function hideDetailDialog() {
        setAssetDetail("");
        setShowDetail(false);
    }

    function getDetailModal() {
        return (
            <Modal show={showDetail} animation={false} className="modal-asset-detail">
                <Modal.Header>
                    <Modal.Title>Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="asset-detail" dangerouslySetInnerHTML={{__html: assetDetail}}>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideDetailDialog} className={classes.button}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function hideLabelDialog() {
        setShowingPropertyLabel(false);
    }

    function handleCurrentLabelProperty(event, value) {
        setCurrentLabelProperty(value);
    }

    function chooseLabelProperty() {
        setShowingPropertyLabel(false);
        setLabelProperty(currentLabelProperty);
    }

    async function setLabelProperty() {
        props.setLabelProperty(currentLabelProperty);

        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.updateLabelProperty({token: userToken, label_property: currentLabelProperty});

            if (response.data && !response.data.error) {
                props.setUserInfo(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

    function hideAddingPoleDialog() {
        setAddingPole(false);
        window.addingPole = false;
        props.setAddingPosition(undefined);

        if (window.addingPoleItem && window.MAIN_CESIUM_VIEWER) {
            window.MAIN_CESIUM_VIEWER.entities.remove(window.addingPoleItem);
        }
        window.addingPoleItem = undefined;
    }

    function onChangeAddingPoleName(evt) {
        setAddingPoleName(evt.target.value);
    }

    function onChangeAddingPoleDesc(evt) {
        setAddingPoleDesc(evt.target.value);
    }

    function handleChangeInspectLevel(event) {
		setInspectLevel(parseInt(event.target.value));
	}

    async function addPoleItem() {
        if (addingPoleName === "") return;

        let position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(props.addingPosition);
        position = [position.longitude, position.latitude, position.height];

        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createAsset({
                name: addingPoleName, 
                detail: addingPoleDesc, 
                inspect: inpsectLevel, 
                positions : JSON.stringify(position), 
                visible: true,
                token: userToken});
            if (response.data && !response.data.error) {
                let addedAsset = response.data;

                let userAssets = JSON.parse(JSON.stringify(props.allAssets));
                if (addedAsset.isUpdate) {
                    let index = userAssets.findIndex( asset => asset.id === addedAsset.asset.id );
                    if (index >= 0) userAssets[index] = addedAsset.asset;
                }
                else {
                    userAssets.push(addedAsset.asset);
                }
                props.setAllAssets(userAssets);
                hideAddingPoleDialog();
            }
        }
    }

    function getAddingPoleDialog() {
        return(
            <Modal show={isAddingPole} animation={false} className="modal-annotation-create">
                <Modal.Header>
					<div className='creating_component_dialog'>
						<Modal.Title>Add Pole</Modal.Title>
						<div className='close_button' onClick={hideAddingPoleDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='project-name-box'>
                        <p className="project-name-title">Name</p>
                        <input type="text" className="project-name-input" 
                        value={addingPoleName} 
                        onChange={onChangeAddingPoleName}></input>
                    </div>
                    <div className='project-name-box'>
                        <p className="project-name-title">Description</p>
                        <textarea className="project-name-input" 
                        value={addingPoleDesc} 
                        onChange={onChangeAddingPoleDesc}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
				<div className='severity_selection_type'>
					<div className='selection_label'>Inspect Level 0-5</div>
					<div className='selection_list'>
                        <div className='asset_inspect_level_1'>
                            <Radio
                                checked={inpsectLevel === 5}
                                onChange={handleChangeInspectLevel}
                                value="5"
                                name="radio-buttons"
                                sx={{
                                    color: "grey",
                                    '&.Mui-checked': {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
                        <div className='inspect_level_2'>
                            <Radio
                                checked={inpsectLevel === 4}
                                onChange={handleChangeInspectLevel}
                                value="4"
                                name="radio-buttons"
                                sx={{
                                    color: "grey",
                                    '&.Mui-checked': {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
                        <div className='inspect_level_3'>
                            <Radio
                                checked={inpsectLevel === 3}
                                onChange={handleChangeInspectLevel}
                                value="3"
                                name="radio-buttons"
                                sx={{
                                    color: "grey",
                                    '&.Mui-checked': {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
                        <div className='inspect_level_4'>
                            <Radio
                                checked={inpsectLevel === 2}
                                onChange={handleChangeInspectLevel}
                                value="2"
                                name="radio-buttons"
                                sx={{
                                    color: "grey",
                                    '&.Mui-checked': {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
                        <div className='inspect_level_5'>
                        <Radio
                                checked={inpsectLevel === 1}
                                onChange={handleChangeInspectLevel}
                                value="1"
                                name="radio-buttons"
                                sx={{
                                    color: "grey",
                                    '&.Mui-checked': {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
                        <div className='asset_inspect_level_6'>
                        <Radio
                                checked={inpsectLevel === 0}
                                onChange={handleChangeInspectLevel}
                                value="0"
                                name="radio-buttons"
                                sx={{
                                    color: "grey",
                                    '&.Mui-checked': {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
					</div>
				</div>
                <Button variant="contained"
                    color="primary" onClick={addPoleItem} className={classes.button}>
                    Add
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function getLabelDialog() {
        return (
            <Modal show={isShowingPropertyLabel} animation={false} className="modal-kmz-label">
                <Modal.Header>
                    <Modal.Title>Choose Label Property</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={currentLabelProperty}
                        onChange={handleCurrentLabelProperty}
                        className="modal-kmz-radiogroup"
                    >
                        {
                            kmlLabelProperties.map((property, index) => (
                                <FormControlLabel key={index} value={property} control={<Radio />} label={property.toUpperCase()} />
                            ))
                        }
                    </RadioGroup>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideLabelDialog} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={chooseLabelProperty} className={classes.button}>
                    Choose
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function handleKMLDataset(event, value) {
        setSelectedKMLID(value);
    }

    function getKMLFileList() {
        const pageItems = props.allDatasets.filter(function(value) {
            return value.datatype === "kml"
        });

        return (
            <RadioGroup
                name="controlled-radio-buttons-group"
                value={selectedKMLID + ""}
                onChange={handleKMLDataset}
                className="modal-dataset-radiogroup"
            >
                {
                    pageItems.map((item, index) => (
                        <FormControlLabel key={index} value={item.id + ""} control={<Radio />} label={item.name} />
                    ))
                }
            </RadioGroup>
        );
    }

    function hideDatasetAddDialog() {
        setAddingFromDataset(false);
        setSelectedKMLID(-1);
    }

    function addPoleFromDataset() {
        if (selectedKMLID + "" !== "-1") {
            const items = props.allDatasets.filter(function(value) {
                return value.id + "" === selectedKMLID + "";
            });

            if (items.length > 0) {
                getDatasetFiles(items[0]);
            }
            
            setAddingFromDataset(false);
        }
        else {
            toast("Please select Dataset to add")
        }
    }

    async function getDatasetFiles(dataset) {
        setAddingMessage("Getting Files");
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getFilesByDatasetID({
                dataset_id: dataset.id,
                token: userToken});
            if (response.data && !response.data.error) {
                if (response.data.length > 0) {
                    addAsset(dataset, response.data, 0, [], []);
                }
                else {
                    toast("No Files.");
                    setAddingMessage("");
                }
            }
            else {
                toast("Failed getting files. Please Retry to relogin.");
                setAddingMessage("");
            }
        }
    }

    function addAsset(dataset, files, index, assets, cartographicPositions) {
        if (files.length === index) {
            if (window.MAIN_CESIUM_VIEWER && window.MAIN_CESIUM_VIEWER.terrainProvider && cartographicPositions.length > 0) {
                setAddingMessage("Getting Terrain elevations.");
                Cesium.sampleTerrain(window.MAIN_CESIUM_VIEWER.terrainProvider, 11, cartographicPositions)
                .then(function(samples) {
                    for (let i = 0; i < samples.length; i ++) {
                        if (samples[i]) {
                            assets[i].positions = [
                                samples[i].longitude,
                                samples[i].latitude,
                                samples[i].height
                            ]
                        }
                    }
                    setAddingMessage("Start adding assets.");
                    startAddingAssets(assets, dataset.name);
                });
            }
            else {
                setAddingMessage("Start adding assets.");
                startAddingAssets(assets, dataset.name);
            }
        }
        else {
            setAddingMessage("Getting Files " + files[index].name);
            let url = mapUtils.getSubPath(dataset) + "/datasets/" + dataset.id + "/" + files[index].name;
            new Cesium.DataSourceCollection().add(Cesium.KmlDataSource.load(url, {
                camera: window.MAIN_CESIUM_VIEWER.scene.camera,
				canvas: window.MAIN_CESIUM_VIEWER.scene.canvas,
            })).then(function (dataSource) {
                let entities = dataSource.entities.values;
                entities.forEach(entity => {
                    if (!entity.position) return;
                    let position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(entity.position._value);
                    cartographicPositions.push(position);
                    if (position.altitude < 0.1) {
                        position.altitude = 0;
                    }
                    assets.push({
                        name: (entity.name)?entity.name:entity.id,
                        detail: (entity.description)?entity.description._value:"",
                        positions: [
                            position.longitude,
                            position.latitude,
                            position.altitude
                        ]
                    })
                });
                addAsset(dataset, files, index + 1, assets, cartographicPositions);
            });
        }
    }

    function startAddingAssets(assets, name) {
        if (assets.length > 0) {
            setAssetDataName(name);
            setAddingAssets(assets);
            setAddingAssetIndex(-1);
            addAssetOnServer(assets, 0, name, []);
        }

        setAddingMessage("");
    }

    async function addAssetOnServer(assets, index, name, addedAssets) {
        if (assets.length === index) {
            let userAssets = JSON.parse(JSON.stringify(props.allAssets));
            for (let i = 0; i < addedAssets.length; i ++) {
                if (addedAssets[i].isUpdate) {
                    let index = userAssets.findIndex( asset => asset.id === addedAssets[i].asset.id );
                    if (index >= 0) userAssets[index] = addedAssets[i].asset;
                }
                else {
                    userAssets.push(addedAssets[i].asset);
                }
            }
            props.setAllAssets(userAssets);
            toast("Finished Adding assets from " + name);
            setAddingAssets([]);
            setAddingAssetIndex(-1);
            setAssetDataName("");
        }
        else {
            setAddingAssetIndex(index);
            let userToken = localStorage.getItem("userToken");
            if (userToken) {
                let response = await api.createAsset({
                    name: assets[index].name, 
                    detail: assets[index].detail, 
                    inspect: 0, 
                    visible: false,
                    positions : JSON.stringify(assets[index].positions), 
                    token: userToken});
                if (response.data && !response.data.error) {
                    addedAssets.push(response.data);
                    addAssetOnServer(assets, index + 1, name, addedAssets);
                }
            }
        }
    }

    function getAddingAssetDialog() {
        return (
            <Modal show={addingAssets.length > 0 || addingMessage !== ""} animation={false} className="modal-asset-label">
                <Modal.Header>
                    <Modal.Title>Adding Assets From {assetDataName}</Modal.Title>
                </Modal.Header>
                {(addingAssets.length > 0 && addingAssetIndex >= 0)?
                <Modal.Body>
                <div className="asset-progress-contaner">
                    <ProgressBar now={Math.floor(addingAssetIndex / addingAssets.length * 100)} 
                    label={Math.floor(addingAssetIndex / addingAssets.length * 100) + "%"}/>
                </div>
                <div className="asset-progress-label">
                    Adding Asset {addingAssets[addingAssetIndex].name}
                </div>
                </Modal.Body>
                :(addingMessage !== "")?
                <Modal.Body>
                <div className="asset-progress-contaner">
                    <ProgressBar now={0}/>
                </div>
                <div className="asset-progress-label">
                    {addingMessage}
                </div>
                </Modal.Body>
                :null}
            </Modal>
        );
    }

    function getDatasetAddPoleDialog() {
        return (
            <Modal show={isAddingFromDataset} animation={false} className="modal-kmz-dataset">
                <Modal.Header>
                    <Modal.Title>Choose KML Dataset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {getKMLFileList()}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideDatasetAddDialog} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={addPoleFromDataset} className={classes.button}>
                    Add
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function handleAssetPage(event, value) {
        setCurrentAssetPage(value);
    }

	return (
		<div className={classes.root}>
            <div className={classes.panel_label}>Asset Manager</div>
            <div className='asset_filter_part'>
                <div className={(api.canEditItem(props.userInfo))?classes.asset_search_name:classes.asset_search_name_viewer}>
                    <input type="text" className="search-name-input" 
                    value={assetSearch} 
                    onChange={onChangeAssetSearch}></input>
                </div>
                <div className='asset-list-menu-box'>
                    <div className="image_button_icon can_hover_icon" title="Visible/Hide" onClick={switchVisible}>
                        {(isAllVisible())? <div className='icon visible_icon'></div> : <div className='icon invisible_icon'></div>}
                    </div>
                    {api.canEditItem(props.userInfo)?
                    <div className="image_button_icon can_hover_icon" title="Delete All Asset" onClick={deleteAllAssetsByDialog}><div className='icon delete_asset_icon'></div></div>
                    :null}
                    <div className="image_button_icon can_hover_icon" title="Choose Label" onClick={chooseLabel}><div className='icon label_icon'></div></div>
                    {api.canEditItem(props.userInfo)?
                    <div className="image_button_icon can_hover_icon" title="Add Asset" onClick={addPole}><div className='icon add_asset_icon'></div></div>
                    :null}
                    {api.canEditItem(props.userInfo)?
                    <div className="image_button_icon can_hover_icon" title="Add Pole" onClick={addPoleManual}><div className='icon add_button_icon'></div></div>
                    :null}
                </div>
            </div>
            {props.allAssets.length > 0?
            <div className='asset_part'>
                <div className='asset_list'>
                    {getAssetList()}
                </div>
                <Pagination
                className='image-annotation-page-item'
                page={currentAssetPage}
                count={totalAssetPage}
                onChange={handleAssetPage}
                variant="outlined"
                color="primary"
                sx={{
                    '& .MuiPaginationItem-root': {
                        background: '#898989 !important',
                        color: "#ffffff !important",
                        fontSize: 13,
                    },
                    '& .Mui-selected': {
                        background: '#03a9f4 !important',
                        color: "#ffffff !important",
                        fontSize: 13,
                    },
                }} />
            </div>
            :null}
            {getDetailModal()}
            {getLabelDialog()}
            {getAddingPoleDialog()}
            {getDatasetAddPoleDialog()}
            {getAddingAssetDialog()}
		</div>
	);
}

const mapStateToProps = state => ({
    allDatasets : state.project.allDatasets,
	allAssets : state.project.allAssets,
    labelProperty : state.project.labelProperty,
    addingPosition : state.project.addingPosition,
    userInfo: state.global.userInfo
});

AssetPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(AssetPanel);
