import { initialState } from './selectors';
import {
  TOGGLE_DRAWER, TOGGLE_RESET, TAB_SELECTION, UPLOAD_FILE_SUCCESS, SET_USER_INFO, SET_SHOW_MENU
} from './actions';

export default function global(state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawerVisibility: !state.drawerVisibility
      }
    case TOGGLE_RESET:
      return {
        ...state,
        drawerVisibility: false
      }
    case TAB_SELECTION:
      return {
        ...state,
        tabIndex: payload
      }

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadResponse: payload
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: payload
      }
    case SET_SHOW_MENU:
      return {
        ...state,
        isShowMenu: payload
      }
    default:
      return state;
  }
}
