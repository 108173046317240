import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { CircularProgress, Pagination, Slider, Switch } from '@mui/material';
import * as mapUtils from '../../components/MapView/MapUtils';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { toast } from 'react-toastify';
import { Checkbox, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import ProgressBar from 'react-bootstrap/ProgressBar';

import './style.scss';
import { connect } from 'react-redux';
import { sample } from 'lodash';
import DatePicker from "react-datepicker";

const Cesium = window.Cesium;

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
    dataset_info: {
        fontSize: 15,
        fontWeight: 400,
        display: "flex",
    },
    button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
    name: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 10,
        marginLeft: 10,
        width: "calc(60% - 80px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip"
    },
    classificationName: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 6,
        marginLeft: 10,
        width: "calc(100% - 80px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    annotationName: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 6,
        marginLeft: 10,
        width: "calc(60% - 80px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    annotationDescription: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 6,
        marginLeft: 10,
        width: "calc(40% - 105px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    annotationImageDescription: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 6,
        marginLeft: 10,
        width: "calc(40% - 25px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    annotationDescription_viewer: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 6,
        marginLeft: 10,
        width: "calc(40% - 45px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    annotationImageDescription_viewer: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 6,
        marginLeft: 10,
        width: "calc(40% - 5px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    datatype: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 13,
        marginTop: 11,
        marginLeft: 10,
        width: "calc(40% - 50px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip"
    },
    circular_progress: {
        margin : 5
    },
    progress_label: {
        position: "absolute",
        fontSize: 10,
        color: 'white',
        margin: 5,
        width: 30,
        height: 30,
        paddingTop: 9,
        textAlign: 'center',
    },
    expandable: {
        marginLeft: 30,
    },
    expanded_list_title: {
        height: 38,
    },
    selected: {
        color : "#00bcd4",
    }
}));

function ProjectDatasetItem(props) {
	const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorViewMode, setAnchorViewMode] = React.useState(null);
    const [anchorFilterImage, setAnchorFilterImage] = React.useState(null);
    const [anchorPointSize, setAnchorPointSize] = React.useState(null);
    const [anchorLabelMode, setAnchorLabelMode] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isViewModeMenuOpen = Boolean(anchorViewMode);
    const isAnchorFilterImage = Boolean(anchorFilterImage);
    const isPointSizeMenuOpen = Boolean(anchorPointSize);
    const isLabelModeOpen = Boolean(anchorLabelMode);

    const [currentImagePage, setCurrentImagePage] = React.useState(1);
    const [totalImagePage, setTotalImagePage] = React.useState(Math.ceil(props.item.files.length / 50));
    const [itemCountPage, setItemCountPage] = React.useState(50);

    const [filterArrays, setFilterArrays] = React.useState([0, 2, 3, 4, 5]);
    const [filterTypeArrays, setFilterTypeArrays] = React.useState([]);
	const [filterStatusArrays, setFilterStatusArrays] = React.useState(["not_annotated"]);

    const [anchorFilter, setAnchorFilter] = React.useState(null);
	const isFilterMenuOpen = Boolean(anchorFilter);

    const [kmlLabelProperties, setKMLLabelProperties] = React.useState(["none", "default"]);
    const [currentLabelProperty, setCurrentLabelProperty] = React.useState("none");
    const [isShowingPropertyLabel, setShowingPropertyLabel] = React.useState(false);

    const [addingAssets, setAddingAssets] = React.useState([]);
    const [addingAssetIndex, setAddingAssetIndex] = React.useState(-1);
    const [assetDataName, setAssetDataName] = React.useState("");

    const [filterOption, setFilterOption] = React.useState("annotation");

    const [labelMode, setLabelMode] = React.useState("all");

	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);

    const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);
    const [isExpandedDateFilter, setExpandedDateFilter] = React.useState(false);
	const [isExpandedTypeFilter, setExpandedTypeFilter] = React.useState(false);
	const [isExpandedStatusFilter, setExpandedStatusFilter] = React.useState(false);
	const [isExpandedSeverityFilter, setExpandedSeverityFilter] = React.useState(false);
	const [isExpandedTextFilter, setExpandedTextFilter] = React.useState(false);

    const [isExpandReportLayers, setExpandReportLayers] = React.useState(false);
    const [isExpandReportRules, setExpandReportRules] = React.useState(false);
    const [isExpandReportSeverity, setExpandReportSeverity] = React.useState(false);
    const [isExpandReportAnnotations, setExpandReportAnnotations] = React.useState(false);
    const [reportLayersList, setReportLayersList] = React.useState([
        {
            value: "measurement",
            label: "Measurement Point",
        },
        {
            value: "measurement_label",
            label: "Measurement Label",
        },
        {
            value: "measurement_line",
            label: "Measurement Line",
        }
    ]);
    const [reportRulesList, setReportRulesList] = React.useState([]);
    const [filterReportLayerList, setFilterReportLayerList] = React.useState(["measurement", "measurement_label", "measurement_line"]);
    const [filterReportRuleList, setFilterReportRuleList] = React.useState([]);
	const [filterReportSeverityList, setFilterReportSeverityList] = React.useState(["red", "orange", "yellow"]);

    const [imageSearch, setImageSearch] = React.useState("");
    const [checkedAll, setTypeCheckAll] = React.useState(true);

    useEffect(() => {
		if (!props.item.files) return;
        if (props.item.item.datatype !== "img") return;
		let filterList = props.item.files.filter(function(value) {
			return filterArrays.indexOf(value.inspect) >= 0 && hasAnnotation(value) && isInListBySearch(value);
		});

		setTotalImagePage(Math.ceil(filterList.length / itemCountPage));
		setCurrentImagePage(1);
        if (props.onImageFilterDateChange) {
            props.onImageFilterDateChange(props.item.item.id, {
                start_date: (startDate)?startDate.getTime():undefined,
                end_date: (endDate)?endDate.getTime():undefined,
                filter_option: filterOption,
            })
        }
	}, [filterArrays, filterTypeArrays, filterStatusArrays, startDate, endDate, filterOption, imageSearch]);

    useEffect(() => {
		let annotationTypeArrays = [];
		let annotationStatusArrays = [];
        if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            annotationTypeArrays = mapUtils.generateAnnotationTypeList();
        }
        else {
			annotationTypeArrays = JSON.parse(props.userInfo.annotation_type_list);
            
        }
        setAnnotationTypeList(annotationTypeArrays);
		setFilterTypeArrays(annotationTypeArrays.map((annotationType) => annotationType.value));

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            annotationStatusArrays = mapUtils.generateAnnotationStatusList();
        }
        else {
			annotationStatusArrays = JSON.parse(props.userInfo.annotation_status_list);
            
        }
        setAnnotationStatusList(annotationStatusArrays);
		let filterArray = annotationStatusArrays.map((annotationStatus) => annotationStatus.value);
		filterArray.push("not_annotated");
		setFilterStatusArrays(filterArray);
	}, [props.userInfo]);

    useEffect(() => {
		showHideReportEntities();
	}, [filterReportLayerList, filterReportRuleList, filterReportSeverityList]);

    useEffect(() => {
		if (props.item && props.item.labelMode) {
            setLabelMode(props.item.labelMode);
        }
	}, [props.item]);

    function isInListBySearch(file) {
		if (!imageSearch || imageSearch === "") return true;

        let annotationNameSearch = false;
        let annotationList = props.item.annotationList;
		if (annotationList) {
			let filterdAnnotation = annotationList.filter(function(value) {
				return value.file_id + "" === file.id + "" && value.name.toLowerCase().indexOf(imageSearch.toLowerCase()) !== -1;
			});

			if (filterdAnnotation.length > 0) annotationNameSearch = true;
		}
		
		return file.name.toLowerCase().indexOf(imageSearch.toLowerCase()) !== -1 || annotationNameSearch;
	}

    function hasAnnotation(file) {
        let annotationList = props.item.annotationList;
		if (!annotationList || !filterStatusArrays) {
			if (filterStatusArrays) {
				return filterStatusArrays.indexOf("not_annotated") >= 0;
			}
			return true;
		}
		if (startDate || endDate) {
			if (filterOption === "annotation") {
				let filterdAnnotation = annotationList.filter(function(value) {
					return value.file_id + "" === file.id + "" 
						&& (!startDate || (new Date(value.updated_at.substring(0, 10))).getTime() >= startDate.getTime())
						&& (!endDate || (new Date(value.updated_at.substring(0, 10))).getTime() <= endDate.getTime());
				});

				if (filterdAnnotation.length === 0) return false;
			}
			else if (filterOption === "image") {
				let locationInfo = mapUtils.getImageLocation(file.location);
				if (locationInfo && locationInfo.date) {
					if (startDate) {
						if (new Date(locationInfo.date.substring(0, 10).replaceAll(":", "-")).getTime() < startDate.getTime()) return false;
					}
					if (endDate) {
						if (new Date(locationInfo.date.substring(0, 10).replaceAll(":", "-")).getTime() > endDate.getTime()) return false;
					}
				}
			}
		}
		
		let filterdAnnotation = annotationList.filter(function(value) {
			return value.file_id + "" === file.id + "";
		});

        if (filterdAnnotation.length === 0) return filterStatusArrays.indexOf("not_annotated") >= 0;

		return filterdAnnotation.filter(function(value) {
			return filterTypeArrays.indexOf(value.annotation_type) >= 0 && filterStatusArrays.indexOf(value.annotation_status) >= 0;
		}).length > 0;
	}

    function handleImagePage(event, value) {
        setCurrentImagePage(value);
    }

    function expandDataset() {
        if (props.onExpandable) {
            props.onExpandable();
        }
    }

    function visibleDataset() {
        if (props.onVisible) {
            props.onVisible(true);
        }
    }

    function hideDataset() {
        if (props.onVisible) {
            props.onVisible(false);
        }
    }

    function deleteDatasetFromProject() {
        if (props.onDelete) {
            props.onDelete();
        }
        handleMenuClose();
    }

    function downloadDataset() {
        let selectedDatasetID = props.item.item.id;
        handleMenuClose();
        if (selectedDatasetID !== null) {
            window.open("/download?dataset_id=" + selectedDatasetID);
        }
    }

    function editDataset() {
        if (props.onEdit) {
            props.onEdit();
        }
        handleMenuClose();
    }


    function getItemIcon() {
        if (props.item.item.status !== "finish") {
            if (props.item.item.status === "processing") {
                return (<div><div className={classes.progress_label}>{props.item.item.progressing}%</div><CircularProgress size={30} className={classes.circular_progress}/></div>);
            }
            else {
                return (<CircularProgress size={30} className={classes.circular_progress}/>);
            }
        }
        else if (props.item.item.datatype === "pointcloud") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon pointcloud_icon'></div></div>);
        }
        else if (props.item.item.datatype === "tiff") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon tiff_icon'></div></div>);
        }
        else if (props.item.item.datatype === "img") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon img_icon'></div></div>);
        }
        else if (props.item.item.datatype === "kml") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon kml_icon'></div></div>);
        }
        else if (props.item.item.datatype === "annotations") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon annotation_icon'></div></div>);
        }
        else if (props.item.item.datatype === "Report") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon report_item_icon'></div></div>);
        }
        else if (props.item.item.datatype === "dem") {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={expandDataset}><div className='icon dem_item_icon'></div></div>);
        }
        return (<div className="dataitem_icon unknown"></div>);
    }

    function getVisibleIcon() {
        if (props.item.visible) {
            return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={hideDataset}><div className='icon visible_icon'></div></div>);
        }
        return (<div className="dataitem_icon can_hover_icon no_boder_line" onClick={visibleDataset}><div className='icon invisible_icon '></div></div>);
    }

    function getTypeValue() {
        return (props.item.item.datatype === "")? "Not Set":
                (props.item.item.datatype === "pointcloud")?"3D Point Cloud":
                (props.item.item.datatype === "dem" && props.item.item.is_report)?"Report DEM":
                (props.item.item.datatype === "dem" && !props.item.item.is_report)?"DEM":
                (props.item.item.datatype === "tiff")?"Orthomosaic":
                (props.item.item.datatype === "kml")?"KML":
                (props.item.item.datatype === "img")?"Image":
                (props.item.item.datatype === "annotations")?"Annotations":
                (props.item.item.datatype === "Report" && props.item.item.report_type === "lidar")?"Lidar Report":
                (props.item.item.datatype === "Report" && props.item.item.report_type === "image")?"Image Report":
                (props.item.item.datatype === "Report" && props.item.item.report_type === "map")?"Map Report":
                props.item.item.datatype;
    }

    function selectDataset(event) {
        event.persist();
        if (event.detail === 1) {
            window.selectedDatasetID = props.item.item.id;
            if (props.onExpandable) {
                props.onExpandable();
            }
        }
        else {
            flyToLayer();
        }
    }

    function expandAnnotations() {
        if (props.onExpandAnnotations) {
            props.onExpandAnnotations();
        }
    }

    function expandClassification() {
        if (props.onExpandClassification) {
            props.onExpandClassification();
        }
    }

    function expandImages() {
        if (props.onExpandIamges) {
            props.onExpandIamges();
        }
    }

    function changeLabelDisplay() {
        if (props.onChangeLabelDisplay) {
            props.onChangeLabelDisplay();
        }
    }

    function ClassificationEyeComponent(classificationProps) {
        const [visibleClassification, setVisibleClassification] = React.useState(classificationProps.classification.visible);

        function onClickEyeIcon() {
            if (props.onClassificationVisible) {
                props.onClassificationVisible(classificationProps.classification.index, !visibleClassification);
                setVisibleClassification(!visibleClassification);
            }
        }

        return (
            <div className={"small_icon can_hover_icon no_boder_line"} onClick={onClickEyeIcon}>
                {visibleClassification?
                <div className='icon visible_icon'></div>:
                <div className='icon invisible_icon'></div>}
            </div>
        );
    }

    function getClassificationItem() {
        let classificationList = props.item.classificationList
        if (classificationList.length > 0) {
            const items = classificationList.map((classification, index) => (
                <div key={index} className="detail_expandable_item">
                    <div className="classification_color" style={{background: classification.color}}></div>
                    <div className={classes.classificationName}>{classification.index} - {classification.name}</div>
                    <ClassificationEyeComponent
                        classification={classification}
                    />
                </div>
            ));

            return items;
        }
    }

    function deleteAnnotation(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            if (props.onDeleteAnnotation) {
                props.onDeleteAnnotation(event.currentTarget.dataset.id)
            }
        }
    }

    function deleteAllAnnotations(event) {
        if (props.onDeleteAllAnnotation) {
            props.onDeleteAllAnnotation()
        }
        handleMenuClose();
    }

    function editAnnotation(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            if (props.onEditAnnotation) {
                props.onEditAnnotation(event.currentTarget.dataset.id)
            }
        }
    }

    function flyAnnotationByMenu(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            let annotation = window.annotationList.get(parseInt(event.currentTarget.dataset.id));
            if (annotation && annotation.length > 0 && annotation[0].show) {
                if (annotation[0].show) {
                    mapUtils.flyToEntity(window.MAIN_CESIUM_VIEWER, annotation[0]);
                }
                else {
                    if (annotation[0].position) {
                        let center = Cesium.Cartographic.fromCartesian(annotation[0].position._value);
                        mapUtils.flyPosition(window.MAIN_CESIUM_VIEWER, {
                            longitude: Cesium.Math.toDegrees(center.longitude),
                            latitude: Cesium.Math.toDegrees(center.latitude),
                            altitude: center.height + 2000
                        });
                    }
                }
            }
        }
    }

    function updateVisibleAnnotation(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            if (props.onVisibleAnnotation) {
                props.onVisibleAnnotation(event.currentTarget.dataset.id)
            }
        }
    }

    function flyAnnotation(event) {
        event.persist();
        
        if (event.detail === 2) {
            if (event.currentTarget && event.currentTarget.dataset.id) {
                let annotation = window.annotationList.get(parseInt(event.currentTarget.dataset.id));
                if (annotation && annotation.length > 0) {
                    if (annotation[0].show) {
                        mapUtils.flyToEntity(window.MAIN_CESIUM_VIEWER, annotation[0]);
                    }
                    else {
                        if (annotation[0].position) {
                            let center = Cesium.Cartographic.fromCartesian(annotation[0].position._value);
                            mapUtils.flyPosition(window.MAIN_CESIUM_VIEWER, {
                                longitude: Cesium.Math.toDegrees(center.longitude),
                                latitude: Cesium.Math.toDegrees(center.latitude),
                                altitude: center.height + 2000
                            });
                        }
                    }
                }
            }
            
        }
    }

    function getAnnotationItemCount() {
        if (props.item.annotationList && props.item.annotationList.length > 0) {
            let annotationItems = props.item.annotationList.filter(function (value) {
                return filterTypeArrays.indexOf(value.annotation_type) >= 0;
            });
            return annotationItems.length;
        }
        return 0;
    }

    function getAnnotationDescription(annotation) {
        if (!annotation.description || annotation.description === "") {
            if (annotation.type === "distance" || annotation.type === "polyline") {
                let position0, position1;
                let positions = JSON.parse(annotation.positions);
                let xyzDistance = 0;
                for (let i = 0; i < positions.length - 5; i += 3) {
                    position0 = new Cesium.Cartesian3(positions[i], positions[i + 1], positions[i + 2]);
                    position1 = new Cesium.Cartesian3(positions[i + 3], positions[i + 4], positions[i + 5]);
                    xyzDistance += Cesium.Cartesian3.distance(position0, position1);
                }
                return mapUtils.getLabelOfMeasure(xyzDistance, props.userInfo.measurement_units);
            }
            return "";
        }
        return annotation.description;
    }

    function getAnnotationItems() {
        if (props.item.annotationList && props.item.annotationList.length > 0) {
            let annotationItems = props.item.annotationList.filter(function (value) {
                return filterTypeArrays.indexOf(value.annotation_type) >= 0;
            });
            const items = annotationItems.map((annotation, index) => (
                <div key={index} className="detail_expandable_item">
                    <div className={`small_icon ${'severity_border_' + annotation.severity_level}`}><div className='icon annotation_icon'></div></div>
                    <div className={classes.annotationName} data-id={annotation.id} onClick={flyAnnotation}>{annotation.name}</div>
                    <div className={(api.canEditItem(props.userInfo))?classes.annotationDescription:classes.annotationDescription_viewer}>{getAnnotationDescription(annotation)}</div>
                    {api.canEditItem(props.userInfo)?
                    <div className="small_icon can_hover_icon no_boder_line" data-id={annotation.id} onClick={deleteAnnotation}><div className='icon delete_icon'></div></div>
                    :null}
                    {api.canEditItem(props.userInfo)?
                    <div className="small_icon can_hover_icon no_boder_line" data-id={annotation.id} onClick={editAnnotation}><div className='icon edit_icon'></div></div>
                    :null}
                    <div className={"small_icon can_hover_icon no_boder_line"} data-id={annotation.id} onClick={updateVisibleAnnotation}>
                        {annotation.visible?
                        <div className='icon visible_icon'></div>:
                        <div className='icon invisible_icon'></div>}
                    </div>
                    <div className="small_icon can_hover_icon no_boder_line" data-id={annotation.id} onClick={flyAnnotationByMenu}><div className='icon fly_icon'></div></div>
                </div>
            ));

            return items;
        }
        return (null);
    }

    function getImageAnnotationItems() {
        if (props.item.annotationList && props.item.annotationList.length > 0) {
            let annotationItems = props.item.annotationList.filter(function (value) {
                return filterTypeArrays.indexOf(value.annotation_type) >= 0;
            });
            const items = annotationItems.map((annotation, index) => (
                <div key={index} className="detail_expandable_item">
                    <div className={`small_icon ${'severity_border_' + annotation.severity_level}`}><div className='icon annotation_icon'></div></div>
                    <div className={classes.annotationName} data-id={annotation.id} onClick={flyAnnotation}>{annotation.name}</div>
                    <div className={(api.canEditItem(props.userInfo))?classes.annotationImageDescription:classes.annotationImageDescription_viewer}>{annotation.description}</div>
                    {api.canEditItem(props.userInfo)?
                    <div className="small_icon can_hover_icon no_boder_line" data-id={annotation.id} onClick={deleteAnnotation}><div className='icon delete_icon'></div></div>
                    :null}
                </div>
            ));

            return items;
        }
        return (null);
    }

    function getImagesItem() {
        if (props.item.files && props.item.files.length > 0) {
            const pageItems = props.item.files.filter(function(value) {
				return filterArrays.indexOf(value.inspect) >= 0 && hasAnnotation(value) && isInListBySearch(value);
			}).slice((currentImagePage - 1) * itemCountPage, currentImagePage * itemCountPage);
            
            const items = pageItems.map((file, index) => (
                <div key={index} className="detail_expandable_item">
                    <div className={`small_icon ${'image_item_icon_' + file.inspect}`}><div className='icon img_icon'></div></div>
                    <div className={`project_item_label ${'project_item_label_' + file.inspect}`}>{file.name}</div>
                    <div className="small_icon can_hover_icon right_icon no_boder_line" data-id={file.id} onClick={handleShowMenu}><div className='icon popup_menu_icon'></div></div>
                </div>
            ));

            return items;
        }
        return (null);
    }

    function getKMZReportAnnoationCount(file) {
        let item = window.loadedObjects.get(props.item.item.datatype + "_" + file.id);

        if (item && item.object && item.object.entities) {
            let values = item.object.entities.values;

            if (reportRulesList.length === 0 && hasGoogleEarthKMZ(file)) {
                let rulesList = [];
                for (let i = 0; i < values.length; i ++) {
                    if (values[i].rule_value && rulesList.indexOf(values[i].rule_value) < 0) {
                        rulesList.push(values[i].rule_value);
                    }
                }
                setReportRulesList(rulesList);
                setFilterReportRuleList(rulesList);
            }

            let annotations = values.filter(function(value) {
                return value.annotation_value === "measurement";
            });
            if (annotations.length > 0) return annotations.length;

            return item.object.entities.values.length;
        }

        return -1;
    }

    function hasGoogleEarthKMZ(file) {
        let item = window.loadedObjects.get(props.item.item.datatype + "_" + file.id);

        if (item && item.object && item.object.entities) {
            let values = item.object.entities.values;
            let annotations = values.filter(function(value) {
                return value.annotation_value === "measurement";
            });
            if (annotations.length > 0) return true;

            return false
        }

        return false;
    }

    function getReportFileName(file) {
        if (file.name.endsWith(".las")) {
            return "LAS Report";
        }
        else if (file.name.endsWith(".kmz")) {
            return "KMZ Report / Annotations (" + getKMZReportAnnoationCount(file) + ")";
        }
        else if (file.name.endsWith(".csv")) {
            return "CSV Report";
        }
        else if (file.name.endsWith(".pdf")) {
            return "PDF Report";
        }
        else if (file.name.endsWith("_with_overlay.zip")) {
            return "JPG Report (With Overlays)";
        }
        else if (file.name.endsWith("_no_overlay.zip")) {
            return "JPG Report (No Overlays)";
        }

        return "LAS Report"
    }

    function canReportExpandItem() {
        let files = props.item.files;

        for (let i = 0; i < files.length; i ++) {
            if (files[i].name.endsWith(".kmz") && getKMZReportAnnoationCount(files[i]) === -1) {
                return false;
            } 
        }
        return true;
    }

    function hideReport(event) {
        if (event.currentTarget && event.currentTarget.dataset.id && props.onVisibleReport) {
            props.onVisibleReport(event.currentTarget.dataset.id, false)
        }
    }

    function showReport(event) {
        if (event.currentTarget && event.currentTarget.dataset.id && props.onVisibleReport) {
            props.onVisibleReport(event.currentTarget.dataset.id, true)
        }
    }

    function hideImageReport(event) {
        if (event.currentTarget && event.currentTarget.dataset.id && props.onVisibleImageReport) {
            props.onVisibleImageReport(event.currentTarget.dataset.id, false)
        }
    }

    function showImageReport(event) {
        if (event.currentTarget && event.currentTarget.dataset.id && props.onVisibleImageReport) {
            props.onVisibleImageReport(event.currentTarget.dataset.id, true)
        }
    }

    function hideMapReport(event) {
        if (event.currentTarget && event.currentTarget.dataset.id && props.onVisibleImageReport) {
            props.onVisibleMapReport(event.currentTarget.dataset.id, false)
        }
    }

    function showMapReport(event) {
        if (event.currentTarget && event.currentTarget.dataset.id && props.onVisibleImageReport) {
            props.onVisibleMapReport(event.currentTarget.dataset.id, true)
        }
    }

    function getReportMapVisibleIcon(file) {
        let reportFiles = (props.item.item.reportFiles)?JSON.parse(props.item.item.reportFiles):[0, 0, 0, 0, 0, 0];
        if (file.name.endsWith(".kmz")) {
            let visible = (reportFiles.length > 3)?Boolean(reportFiles[3]):false;

            if (visible) {
                return (<div className="small_icon can_hover_icon no_boder_line" data-id="kmz" onClick={hideMapReport}><div className='icon visible_icon'></div></div>);
            }
            return (<div className="small_icon can_hover_icon no_boder_line" data-id="kmz" onClick={showMapReport}><div className='icon invisible_icon '></div></div>);
        }
        
        return (null);
    }

    function getReportImageVisibleIcon(file) {
        let reportFiles = (props.item.item.reportFiles)?JSON.parse(props.item.item.reportFiles):[0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (file.name.endsWith(".kmz")) {
            let visible = (reportFiles.length > 5)?Boolean(reportFiles[5]):false;

            if (visible) {
                return (<div className="small_icon can_hover_icon no_boder_line" data-id="kmz" onClick={hideImageReport}><div className='icon visible_icon'></div></div>);
            }
            return (<div className="small_icon can_hover_icon no_boder_line" data-id="kmz" onClick={showImageReport}><div className='icon invisible_icon '></div></div>);
        }
        
        return (null);
    }

    function getReportVisibleIcon(file) {
        let reportFiles = (props.item.item.reportFiles)?JSON.parse(props.item.item.reportFiles):[0, 0, 0, 0, 0, 0, 0, 0];
        if (file.name.endsWith(".kmz")) {
            let visible = (reportFiles.length > 7)?Boolean(reportFiles[7]):false;

            if (visible) {
                return (<div className="small_icon can_hover_icon no_boder_line" data-id="kmz" onClick={hideReport}><div className='icon visible_icon'></div></div>);
            }
            return (<div className="small_icon can_hover_icon no_boder_line" data-id="kmz" onClick={showReport}><div className='icon invisible_icon '></div></div>);
        }
        else if (file.name.endsWith(".las")) {
            let visible = (reportFiles.length > 4)?Boolean(reportFiles[4]):false;

            if (visible) {
                return (<div className="small_icon can_hover_icon no_boder_line" data-id="las" onClick={hideReport}><div className='icon visible_icon'></div></div>);
            }
            return (<div className="small_icon can_hover_icon no_boder_line" data-id="las" onClick={showReport}><div className='icon invisible_icon '></div></div>);
        }
        
        return (null);
    }

    function ReportAnnotationItemComponent(annotationProps) {
        const [annotationShow, setAnnotationShow] = React.useState(annotationProps.annotation.show);

        return (
            <div className="report_expandable_annotation_detail_item">
                <div className={`small_icon ${'severity_border_' + annotationProps.annotation.severity_value}`}><div className='icon annotation_icon'></div></div>
                <div className="report_annotation_item_detail_label">{annotationProps.annotation.name}</div>
                {annotationShow?
                    <div className="small_icon can_hover_icon no_boder_line" onClick={function() {
                        annotationProps.annotation.show = !annotationProps.annotation.show;
                        setAnnotationShow(!annotationShow)
                    }}><div className='icon visible_icon'></div></div>
                    :
                    <div className="small_icon can_hover_icon no_boder_line" onClick={function() {
                        annotationProps.annotation.show = !annotationProps.annotation.show;
                        setAnnotationShow(!annotationShow)
                    }}><div className='icon invisible_icon '></div></div>
                }
                <div className="small_icon can_hover_icon right_icon no_boder_line" onClick={function() {
                    mapUtils.flyToEntity(window.MAIN_CESIUM_VIEWER, annotationProps.annotation);
                }}><div className='icon fly_icon'></div></div>
            </div>
        );
    }

    function getReportAnnotationsDetailList(file, reportValue) {
        let item = window.loadedObjects.get(props.item.item.datatype + "_" + file.id);

        if (item && item.object && item.object.entities) {
            let values = item.object.entities.values;
            let annotations = values.filter(function(value) {
                return value.annotation_value === reportValue 
                && filterReportLayerList.indexOf(reportValue) >= 0 
                && filterReportRuleList.indexOf(value.rule_value) >= 0
                && filterReportSeverityList.indexOf(value.severity_value) >= 0;
            });

            return annotations.map(function(annotation, index) {
                return (
                    <div key={index}>
                        <ReportAnnotationItemComponent
                            annotation={annotation}
                        />
                    </div>
                );
            })
        }

        return (<div></div>);
    }

    function getCountReportFilter(file, reportValue) {
        let item = window.loadedObjects.get(props.item.item.datatype + "_" + file.id);

        if (item && item.object && item.object.entities) {
            let values = item.object.entities.values;
            let annotations = values.filter(function(value) {
                return value.annotation_value === reportValue 
                && filterReportLayerList.indexOf(reportValue) >= 0 
                && filterReportRuleList.indexOf(value.rule_value) >= 0
                && filterReportSeverityList.indexOf(value.severity_value) >= 0;
            });

            return annotations.length;
        }

        return 0;
    }

    function getReportAnnotationsList(file) {
        const items = reportLayersList.map(function(reportLayer, index) {
            return (
                <div key={index}>
                    <div className="report_expandable_annotation_item">
                        <div className="small_icon no_boder_line"><div className='icon annotation_icon'></div></div>
                        <div className="report_annotation_item_label">{reportLayer.label + "(" + getCountReportFilter(file, reportLayer.value) + ")"}</div>
                    </div>
                    {getReportAnnotationsDetailList(file, reportLayer.value)}
                </div>
            );
        });
        return items;
    }

    function getReportItems() {
        if (props.item.files && props.item.files.length > 0) {
            const items = props.item.files.map(function(file, index) {
                if (props.item.item.report_type === "lidar" && file.name.endsWith(".kmz") && hasGoogleEarthKMZ(file)) {
                    return (
                        <div key={index}>
                            <div className="report_expandable_item">
                                <div className="small_icon no_boder_line" onClick={() => {setExpandReportAnnotations(!isExpandReportAnnotations)}}><div className='icon annotation_icon'></div></div>
                                <div className="report_item_label" onClick={() => {setExpandReportAnnotations(!isExpandReportAnnotations)}}>{getReportFileName(file)}</div>
                                {props.item.item.report_type === "lidar"?
                                getReportVisibleIcon(file)
                                :props.item.item.report_type === "map"?
                                getReportMapVisibleIcon(file)
                                :getReportImageVisibleIcon(file)}
                                <div className="small_icon can_hover_icon right_icon no_boder_line" data-id={file.id} onClick={handleShowMenu}><div className='icon popup_menu_icon'></div></div>
                            </div>
                            {isExpandReportAnnotations?
                                getReportAnnotationsList(file)
                            :null}
                        </div>
                    );
                }

                return (
                    <div key={index} className="report_expandable_item">
                        <div className="small_icon no_boder_line"><div className='icon annotation_icon'></div></div>
                        <div className="report_item_label">{getReportFileName(file)}</div>
                        {props.item.item.report_type === "lidar"?
                        getReportVisibleIcon(file)
                        :props.item.item.report_type === "map"?
                        getReportMapVisibleIcon(file)
                        :getReportImageVisibleIcon(file)}
                        <div className="small_icon can_hover_icon right_icon no_boder_line" data-id={file.id} onClick={handleShowMenu}><div className='icon popup_menu_icon'></div></div>
                    </div>
                );
            });

            return items;
        }
        return (null);
    }

    function getReportFileByID(id) {
        if (props.item.files && props.item.files.length > 0) {
            const items = props.item.files;

            for (let i = 0; i < items.length; i ++) {
                if (items[i].id + "" === id + "") return items[i];
            }
        }
        return {
            name: ''
        };
    }

    function handleShowMenu(event) {
        if (reportRulesList.length === 0 && props.item.item.report_type === "lidar") {
            for (let i = 0; i < props.item.files.length; i ++) {
                let file = props.item.files[i];
                if (file.name.endsWith(".kmz") && hasGoogleEarthKMZ(file)) {
                    let item = window.loadedObjects.get(props.item.item.datatype + "_" + file.id);
                    if (item && item.object && item.object.entities) {
                        let values = item.object.entities.values;

                        let rulesList = [];
                        for (let i = 0; i < values.length; i ++) {
                            if (values[i].rule_value && rulesList.indexOf(values[i].rule_value) < 0) {
                                rulesList.push(values[i].rule_value);
                            }
                        }
                        setReportRulesList(rulesList);
                        setFilterReportRuleList(rulesList);
                        break;
                    }
                }
            }
        }

        if (event.currentTarget && event.currentTarget.dataset.id) {
            window.selectedMenuFile = event.currentTarget.dataset.id;
        }
        else {
            window.selectedMenuFile = null;
        }
        if (event.currentTarget)
		    setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(null);
        setTimeout(function() {
            window.selectedMenuFile = null;
        }, 500);
	}

    function handleViewMode(event) {
        setAnchorViewMode(event.currentTarget);
    }

    function handleViewModeClose() {
        setAnchorViewMode(null);
	}

    function handleFilterImageMenu(event) {
        setAnchorFilterImage(event.currentTarget);
    }

    function handleFilterImageMenuClose() {
        setAnchorFilterImage(null);
	}

    function handlePointSize(event) {
        setAnchorPointSize(event.currentTarget);
    }

    function handlePointSizeClose() {
        setAnchorPointSize(null);
	}

    function hillshadeMode() {
        if (props.onChangeMaterialMode) {
            props.onChangeMaterialMode("hillshade");
        }
        handleViewModeClose();
        handleMenuClose();
    }

    function rgbMode() {
        if (props.onChangeMaterialMode) {
            props.onChangeMaterialMode("rgba");
        }
        handleViewModeClose();
        handleMenuClose();
    }

    function enableTerrain() {
        if (props.onChangeEnableTerrain) {
            props.onChangeEnableTerrain(true);
        }
        handleMenuClose();
    }

    function disableTerrain() {
        if (props.onChangeEnableTerrain) {
            props.onChangeEnableTerrain(false);
        }
        handleMenuClose();
    }

    function heightMode() {
        if (props.onChangeMaterialMode) {
            props.onChangeMaterialMode("elevation");
        }
        handleViewModeClose();
        handleMenuClose();
    }

    function classificationMode() {
        if (props.onChangeMaterialMode) {
            props.onChangeMaterialMode("classification");
        }
        handleViewModeClose();
        handleMenuClose();
    }

    function redirectToLogin() {
        props.history.replace("/");
    }

    function reCreateLidarReport() {
        handleMenuClose();

        if (props.onRecreateLidarReport) {
            props.onRecreateLidarReport(props.item.item.id + "", props.selectedProject.project.id + "");
        }
    }

    function reCreateImageReport() {
        handleMenuClose();
        
        if (props.onRecreateImageReport) {
            props.onRecreateImageReport(props.item.item.id + "", props.selectedProject.project.id + "");
        }
    }

    function reCreateMapReport() {
        handleMenuClose();
        
        if (props.onRecreateMapReport) {
            props.onRecreateMapReport(props.item.item.id + "", props.selectedProject.project.id + "");
        }
    }

    function reprocessDem() {
        handleMenuClose();
        
        if (props.onReprocessDem) {
            props.onReprocessDem(props.item.item.id + "", props.selectedProject.project.id + "");
        }
    }

    function reprocessDataset() {
        handleMenuClose();
        
        if (props.onReprocessDataset) {
            props.onReprocessDataset(props.item.item.id);
        }
    }

    function flyToLayer() {
        if (window.selectedMenuFile && props.item.item.datatype !== "Report") {
            let item = window.loadedObjects.get(props.item.item.datatype + "_" + Number(window.selectedMenuFile));
            if (item) {
                mapUtils.flyPosition(window.MAIN_CESIUM_VIEWER, {
                    longitude: item.location[0],
                    latitude: item.location[1],
                    altitude: item.location[2] + 500
                });
            }
        }
        else if (props.item.item) {
            let fly = mapUtils.flyToLayer(props.item);
            if (!fly) {
                toast("Not Loaded yet. Please wait.");
            }
        }

        if (!props.item.enableTerrain && window.MAIN_CESIUM_VIEWER.scene.terrainProvider && window.MAIN_CESIUM_VIEWER.scene.terrainProvider._scheme !== "tms") {
            window.MAIN_CESIUM_VIEWER.scene.setTerrain(window.worldTerrain);
            disableTerrain();
        }
        handleMenuClose();
    }

    function downloadReportCSV() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name.endsWith(".csv")) {
                    fetch(encodeURI(mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function downloadReportPDF() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name.endsWith(".pdf")) {
                    fetch(encodeURI(mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function downloadWithOverlay() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name.endsWith("_with_overlay.zip")) {
                    fetch(encodeURI(mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function downloadNoOverlay() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name.endsWith("_no_overlay.zip")) {
                    fetch(encodeURI(mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function downloadReportLAS() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name.endsWith(".las")) {
                    fetch(encodeURI(mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function downloadReportKMZ() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name.endsWith(".kmz")) {
                    let link;
                    if (hasGoogleEarthKMZ(files[i])) {
                        let fileName = files[i].name.substring(0, files[i].name.lastIndexOf('.'));
                        link = mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + fileName + "_googleearth.kmz";
                    }
                    else {
                        link = mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name;
                    }

                    fetch(encodeURI(link), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function downloadDem() {
        if (props.item.item.status !== "finish") {
            toast("Not yet Generated");
        }
        else {
            const files = props.item.files;
            for (let i = 0; i < files.length; i ++) {
                if (files[i].name === mapUtils.normalizeName(props.item.item.name) + ".tif") {
                    fetch(encodeURI(mapUtils.getSubPath(props.item.item) + "/datasets/"+ props.item.item.id + "/" + files[i].name), {cache: "no-store"}).then(response => {
                        response.blob().then(blob => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = files[i].name;
                            alink.click();
                        })
                    })
                }
            }
        }
        
        handleMenuClose();
    }

    function getAddingAssetDialog() {
        return (
            <Modal show={addingAssets.length > 0} animation={false} className="modal-asset-label">
                <Modal.Header>
                    <Modal.Title>Adding Assets From {assetDataName}</Modal.Title>
                </Modal.Header>
                {(addingAssets.length > 0 && addingAssetIndex >= 0)?
                <Modal.Body>
                <div className="asset-progress-contaner">
                    <ProgressBar now={Math.floor(addingAssetIndex / addingAssets.length * 100)} 
                    label={Math.floor(addingAssetIndex / addingAssets.length * 100) + "%"}/>
                </div>
                <div className="asset-progress-label">
                    Adding Asset {addingAssets[addingAssetIndex].name}
                </div>
                </Modal.Body>
                :null}
            </Modal>
        );
    }

    function addAsset() {
        if (props.item) {
            let files = props.item.files;
            let assets = [];
            let cartographicPositions = [];
            if (files && files.length > 0) {
                for (let i = 0; i < files.length; i ++) {
                    let item = window.loadedObjects.get(props.item.item.datatype + "_" + Number(files[i].id));
                    if (item) {
                        let entities = item.object.entities.values;
                        entities.forEach(entity => {
                            if (!entity.position) return;
                            let position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(entity.position._value);
                            cartographicPositions.push(position);
                            if (position.altitude < 0.1) {
                                position.altitude = 0;
                            }
                            assets.push({
                                name: (entity.name)?entity.name:entity.id,
                                detail: (entity.description)?entity.description._value:"",
                                positions: [
                                    position.longitude,
                                    position.latitude,
                                    position.altitude
                                ]
                            })
                        });
                    }
                }
            }
            if (window.MAIN_CESIUM_VIEWER && window.MAIN_CESIUM_VIEWER.terrainProvider && cartographicPositions.length > 0) {
                Cesium.sampleTerrain(window.MAIN_CESIUM_VIEWER.terrainProvider, 11, cartographicPositions)
                .then(function(samples) {
                    for (let i = 0; i < samples.length; i ++) {
                        if (samples[i]) {
                            assets[i].positions = [
                                samples[i].longitude,
                                samples[i].latitude,
                                samples[i].height
                            ]
                        }
                    }
                    startAddingAssets(assets, props.item.item.name);
                });
            }
            else {
                startAddingAssets(assets, props.item.item.name);
            }
            
        }
        handleMenuClose();
    }

    function startAddingAssets(assets, name) {
        if (assets.length > 0) {
            setAssetDataName(name);
            setAddingAssets(assets);
            setAddingAssetIndex(-1);
            addAssetOnServer(assets, 0, name, []);
        }
    }

    async function addAssetOnServer(assets, index, name, addedAssets) {
        if (assets.length === index) {
            let userAssets = JSON.parse(JSON.stringify(props.allAssets));
            for (let i = 0; i < addedAssets.length; i ++) {
                if (addedAssets[i].isUpdate) {
                    let index = userAssets.findIndex( asset => asset.id === addedAssets[i].asset.id );
                    if (index >= 0) userAssets[index] = addedAssets[i].asset;
                }
                else {
                    userAssets.push(addedAssets[i].asset);
                }
            }
            props.setAllAssets(userAssets);
            toast("Finished Adding assets from " + name);
            setAddingAssets([]);
            setAddingAssetIndex(-1);
            setAssetDataName("");
        }
        else {
            setAddingAssetIndex(index);
            let userToken = localStorage.getItem("userToken");
            if (userToken) {
                let response = await api.createAsset({
                    name: assets[index].name, 
                    detail: assets[index].detail, 
                    inspect: 0, 
                    visible: false,
                    positions : JSON.stringify(assets[index].positions), 
                    token: userToken});
                if (response.data && !response.data.error) {
                    addedAssets.push(response.data);
                    addAssetOnServer(assets, index + 1, name, addedAssets);
                }
            }
        }
    }

    function enterToImage() {
        if (window.selectedMenuFile && props.onGoImageViewer) {
            props.onGoImageViewer(props.item.item.id, window.selectedMenuFile);
        }
        else {
            if (props.item.files.length > 0) {
                props.onGoImageViewer(props.item.item.id, props.item.files[0].id);
            }
        }
        handleMenuClose();
    }

    function updateMaterialPointSize(event, data) {
        if (props.onChangePointSize) {
            props.onChangePointSize(data);
        }
    }

    function chooseLabel() {
        if (props.item.item) {
            let files = props.item.files;
            let labelProperties = ["none", "default"];
            for (let i = 0; i < files.length; i ++) {
                let item = window.loadedObjects.get(props.item.item.datatype + "_" + files[i].id);
                if (item && item.object) {
                    let entities = item.object.entities.values;
                    for (let j = 0; j < entities.length; j ++) {
                        if (entities[j].description) {
                            let properties = mapUtils.parseDescription(entities[j].description._value);
                            for (let prIndex = 0; prIndex < properties.length; prIndex ++) {
                                if (labelProperties.indexOf(properties[prIndex]) >= 0) {
                                    continue;
                                }
                                labelProperties.push(properties[prIndex]);
                            }
                        }
                    }
                }
            }
            setKMLLabelProperties(labelProperties);
            setCurrentLabelProperty(props.item.labelProperty);
            setShowingPropertyLabel(true);
            
        }
        handleMenuClose();
    }

    function hideKMZLabelDialog() {
        setShowingPropertyLabel(false);
    }

    function chooseKMZLabel() {
        setShowingPropertyLabel(false);

        if (props.onLabelPropertyChange) {
            props.onLabelPropertyChange(props.item.item.id, currentLabelProperty);
        }
    }

    function handleCurrentLabelProperty(event, value) {
        setCurrentLabelProperty(value);
    }

    function getKMZLabelDialog() {
        return (
            <Modal show={isShowingPropertyLabel} animation={false} className="modal-kmz-label">
                <Modal.Header>
                    <Modal.Title>Choose Label Property</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={currentLabelProperty}
                        onChange={handleCurrentLabelProperty}
                        className="modal-kmz-radiogroup"
                    >
                        {
                            kmlLabelProperties.map((property, index) => (
                                <FormControlLabel key={index} value={property} control={<Radio />} label={property.toUpperCase()} />
                            ))
                        }
                    </RadioGroup>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideKMZLabelDialog} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={chooseKMZLabel} className={classes.button}>
                    Choose
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function getRenderMenuItem() {
        let renderMenu;
        const menuId = 'primary-action-menu';
        const menuViewMode = 'primary-action-viewmenu';
        const menuFilterImageMode = 'primary-action-filtermenu';
        const menuPointSize = 'primary-action-pointsize';
        const markPointSize = [
            {
              value: 1,
              label: "1px",
            },
            {
                value: 30,
                label: "30px",
            }
        ];
        if (props.item.item.datatype === "pointcloud") {
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleViewMode} className="sidebar-viewmode-submenu"><div className='viewmode_icon'></div>View Mode <ArrowRightIcon className="arrow_icon"/></MenuItem>
                    <Menu
                        anchorEl={anchorViewMode}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id={menuViewMode}
                        className="sidebar-viewmodeMenu-style"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={isViewModeMenuOpen}
                        onClose={handleViewModeClose}
                    >
                        <MenuItem onClick={rgbMode}>RGB</MenuItem>
                        <MenuItem onClick={heightMode}>Height</MenuItem>
                        <MenuItem onClick={classificationMode}>Classification</MenuItem>
                    </Menu>
                    <MenuItem onClick={handlePointSize} className="sidebar-viewmode-submenu">
                        <div className='pointsize_icon'></div>Point Size {props.item.pointSize}px<ArrowRightIcon className="arrow_icon"/></MenuItem>
                    <Menu
                        anchorEl={anchorPointSize}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id={menuPointSize}
                        className="sidebar-viewmodeMenu-style"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={isPointSizeMenuOpen}
                        onClose={handlePointSizeClose}
                    >
                        <Slider
                            name="Point Size"
                            size="small"
                            value={props.item.pointSize}
                            aria-label="Small"
                            valueLabelDisplay="on"
                            step={1}
                            min={1}
                            max={30}
                            className="point_size_slider"
                            onChange={updateMaterialPointSize}
                            marks={markPointSize}
                        ></Slider>
                    </Menu>
                    <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={downloadDataset}><div className='download_menu_icon'></div>Download</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={editDataset}><div className='edit_menu_icon'></div>Edit</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reprocessDataset}><div className='recreate_icon'></div>Reprocess</MenuItem>
                    :null}
                </Menu>
            );
            
        }
        else if (props.item.item.datatype === "annotations") {
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    <MenuItem onClick={(event) => {
                        setAnchorLabelMode(event.currentTarget);
                    }} className="sidebar-viewmode-submenu">
                        <div className='label_icon'></div>Choose Label<ArrowRightIcon className="arrow_icon"/>
                    </MenuItem>
                    <Menu
                        anchorEl={anchorLabelMode}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id={menuViewMode}
                        className="sidebar-viewmodeMenu-style"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={isLabelModeOpen}
                        onClose={() => {setAnchorLabelMode(null)}}
                    >
                        <MenuItem>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={labelMode}
                                onChange={(event, value) => {
                                    setLabelMode(value);
                                    if (props.onChangeLabelMode) {
                                        props.onChangeLabelMode(value)
                                    }
                                }}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                <FormControlLabel value="xyz" control={<Radio />} label="XYZ Measurements" />
                                <FormControlLabel value="xy" control={<Radio />} label="XY Measurements" />
                                <FormControlLabel value="z" control={<Radio />} label="Z Measurements" />
                            </RadioGroup>
                        </MenuItem>
                    </Menu>
                    {api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteAllAnnotations}><div className='delete_menu_icon'></div>Delete All</MenuItem>
                    :null}
                </Menu>
            );
            
        }
        else if (props.item.item.datatype === "tiff") {
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={downloadDataset}><div className='download_menu_icon'></div>Download</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={editDataset}><div className='edit_menu_icon'></div>Edit</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reprocessDataset}><div className='recreate_icon'></div>Reprocess</MenuItem>
                    :null}
                </Menu>
            );
        }
        else if (props.item.item.datatype === "dem") {
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleViewMode} className="sidebar-viewmode-submenu"><div className='viewmode_icon'></div>View Mode <ArrowRightIcon className="arrow_icon"/></MenuItem>

                    <Menu
                        anchorEl={anchorViewMode}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id={menuViewMode}
                        className="sidebar-viewmodeMenu-style"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={isViewModeMenuOpen}
                        onClose={handleViewModeClose}
                    >
                        <MenuItem onClick={rgbMode}>Height Map</MenuItem>
                        <MenuItem onClick={hillshadeMode}>HillShade</MenuItem>
                    </Menu>

                    {/* {!props.item.enableTerrain?
                        <MenuItem onClick={enableTerrain}><div className='dem_icon'></div>Enable Terrain</MenuItem>
                    :null}

                    {props.item.enableTerrain?
                        <MenuItem onClick={disableTerrain}><div className='dem_icon'></div>DisableTerrain</MenuItem>
                    :null} */}

                    <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo) && props.item.item.is_report?
                        <MenuItem onClick={downloadDem}><div className='download_icon'></div>Download DEM</MenuItem>
                    :null}

                    {!window.selectedMenuFile && api.canEditItem(props.userInfo) && !props.item.item.is_report?
                        <MenuItem onClick={downloadDataset}><div className='download_menu_icon'></div>Download</MenuItem>
                    :null}

                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && !props.item.item.is_report && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={editDataset}><div className='edit_menu_icon'></div>Edit</MenuItem>
                    :null}
                    {!window.selectedMenuFile && !props.item.item.is_report && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reprocessDataset}><div className='recreate_icon'></div>Reprocess</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.is_report && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reprocessDem}><div className='recreate_icon'></div>Reprocess</MenuItem>
                    :null}
                </Menu>
            );
        }
        else if (props.item.item.datatype === "kml") {
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={chooseLabel}><div className='label_icon'></div>Choose Label</MenuItem>
                    {/* <MenuItem onClick={addAsset}><div className='asset_menu_icon'></div>Add to Assets</MenuItem> */}
                    <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={downloadDataset}><div className='download_menu_icon'></div>Download</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={editDataset}><div className='edit_menu_icon'></div>Edit</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reprocessDataset}><div className='recreate_icon'></div>Reprocess</MenuItem>
                    :null}
                </Menu>
            );
        }
        else if ((props.item.item.datatype === "Report" || props.item.item.is_report === 1) && props.item.item.report_type === "image") {
            let reportFiles = (props.item.item.reportFiles)?JSON.parse(props.item.item.reportFiles):[0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId} 
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    {reportFiles[0]?
                        <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[0]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".kmz"))?
                        <MenuItem onClick={downloadReportKMZ}><div className='download_icon'></div>Download Report KMZ</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[1]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".csv"))?
                        <MenuItem onClick={downloadReportCSV}><div className='download_icon'></div>Download Report CSV</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[2]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".pdf"))?
                        <MenuItem onClick={downloadReportPDF}><div className='download_icon'></div>Download Report PDF</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[3]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith("_with_overlay.zip"))?
                        <MenuItem onClick={downloadWithOverlay}><div className='download_icon'></div>Download Report JPG(With Overlays)</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[4]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith("_no_overlay.zip"))?
                        <MenuItem onClick={downloadNoOverlay}><div className='download_icon'></div>Download Report JPG(No Overlays)</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reCreateImageReport}><div className='recreate_icon'></div>Recreate</MenuItem>
                    :null}
                </Menu>
            );
        }
        else if ((props.item.item.datatype === "Report" || props.item.item.is_report === 1) && props.item.item.report_type === "map") {
            let reportFiles = (props.item.item.reportFiles)?JSON.parse(props.item.item.reportFiles):[0, 0, 0, 0, 0, 0];
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    {reportFiles[0]?
                        <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[0]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".kmz"))?
                        <MenuItem onClick={downloadReportKMZ}><div className='download_icon'></div>Download Report KMZ</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[1]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".csv"))?
                        <MenuItem onClick={downloadReportCSV}><div className='download_icon'></div>Download Report CSV</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[2]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".pdf"))?
                        <MenuItem onClick={downloadReportPDF}><div className='download_icon'></div>Download Report PDF</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reCreateMapReport}><div className='recreate_icon'></div>Recreate</MenuItem>
                    :null}
                </Menu>
            );
        }
        else if ((props.item.item.datatype === "Report" || props.item.item.is_report === 1) && props.item.item.report_type === "lidar") {
            let reportFiles = (props.item.item.reportFiles)?JSON.parse(props.item.item.reportFiles):[0, 0, 0, 0, 0, 0, 0, 0];
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={menuId}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    {reportFiles[0] || reportFiles[3]?
                        <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[3]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".kmz"))?
                        <MenuItem onClick={handleFilterImageMenu} className="sidebar-viewmode-submenu"><div className='filter_submode_icon'></div>Filter Annotations</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[3]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".kmz"))?
                        <Menu
                            anchorEl={anchorFilterImage}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            id={menuFilterImageMode}
                            className="sidebar-viewmodeMenu-style"
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            open={isAnchorFilterImage}
                            onClose={handleFilterImageMenuClose}
                        >
                            <div className='image_filter'>
                                <div className='expand_filter_component'>
                                    <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                        setExpandReportLayers(!isExpandReportLayers);
                                        setExpandReportRules(false);
                                        setExpandReportSeverity(false);
                                    }}>
                                        {!isExpandReportLayers?
                                            <div className='icon below_icon'></div>
                                        :
                                            <div className='icon up_icon'></div>
                                        }
                                    </div>
                                    <label className='expand_filter_label' onClick={() => {
                                        setExpandReportLayers(!isExpandReportLayers);
                                        setExpandReportRules(false);
                                        setExpandReportSeverity(false);
                                    }}>Layers Filter</label>
                                </div>
                                {isExpandReportLayers?
                                <div className='filter_menu'>
                                    {getReportAnnotationLayerMenu()}
                                </div>
                                :null}

                                <div className='expand_filter_component'>
                                    <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                        setExpandReportLayers(false);
                                        setExpandReportRules(!isExpandReportRules);
                                        setExpandReportSeverity(false);
                                    }}>
                                        {!isExpandReportRules?
                                            <div className='icon below_icon'></div>
                                        :
                                            <div className='icon up_icon'></div>
                                        }
                                    </div>
                                    <label className='expand_filter_label' onClick={() => {
                                        setExpandReportLayers(false);
                                        setExpandReportRules(!isExpandReportRules);
                                        setExpandReportSeverity(false);
                                    }}>Rule Filter</label>
                                </div>
                                {isExpandReportRules?
                                <div className='filter_menu'>
                                    {getReportAnnotationRuleMenu()}
                                </div>
                                :null}
                                
                                <div className='expand_filter_component'>
                                    <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                        setExpandReportLayers(false);
                                        setExpandReportRules(false);
                                        setExpandReportSeverity(!isExpandReportSeverity);
                                    }}>
                                        {!isExpandReportSeverity?
                                            <div className='icon below_icon'></div>
                                        :
                                            <div className='icon up_icon'></div>
                                        }
                                    </div>
                                    <label className='expand_filter_label' onClick={() => {
                                        setExpandReportLayers(false);
                                        setExpandReportRules(false);
                                        setExpandReportSeverity(!isExpandReportSeverity);
                                    }}>Severity Filter</label>
                                </div>
                                {isExpandReportSeverity?
                                <div className='filter_menu'>
                                    <CustomCheckbox checked={filterReportSeverityList.indexOf("red") >= 0} className="filter_inspect_5_checkbox" onClick={changeReportFilterSeverity} data-id={"red"}/>
                                    <CustomCheckbox checked={filterReportSeverityList.indexOf("orange") >= 0} className="filter_inspect_4_checkbox" onClick={changeReportFilterSeverity} data-id={"orange"}/>
                                    <CustomCheckbox checked={filterReportSeverityList.indexOf("yellow") >= 0} className="filter_inspect_3_checkbox" onClick={changeReportFilterSeverity} data-id={"yellow"}/>
                                </div>
                                :null}
                            </div>
                        </Menu>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[0]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".las"))?
                        <div>
                            <MenuItem onClick={handleViewMode} className="sidebar-viewmode-submenu"><div className='viewmode_icon'></div>View Mode <ArrowRightIcon className="arrow_icon"/></MenuItem>
                            <Menu
                                anchorEl={anchorViewMode}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                id={menuViewMode}
                                className="sidebar-viewmodeMenu-style"
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                open={isViewModeMenuOpen}
                                onClose={handleViewModeClose}
                            >
                                <MenuItem onClick={rgbMode}>RGB</MenuItem>
                                <MenuItem onClick={heightMode}>Height</MenuItem>
                                <MenuItem onClick={classificationMode}>Classification</MenuItem>
                            </Menu>
                            <MenuItem onClick={handlePointSize} className="sidebar-viewmode-submenu">
                                <div className='pointsize_icon'></div>Point Size {props.item.pointSize}px<ArrowRightIcon className="arrow_icon"/></MenuItem>
                            <Menu
                                anchorEl={anchorPointSize}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                id={menuPointSize}
                                className="sidebar-viewmodeMenu-style"
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                open={isPointSizeMenuOpen}
                                onClose={handlePointSizeClose}
                            >
                                <Slider
                                    name="Point Size"
                                    size="small"
                                    value={props.item.pointSize}
                                    aria-label="Small"
                                    valueLabelDisplay="on"
                                    step={1}
                                    min={1}
                                    max={30}
                                    className="point_size_slider"
                                    onChange={updateMaterialPointSize}
                                    marks={markPointSize}
                                ></Slider>
                            </Menu>
                            <MenuItem onClick={downloadReportLAS}><div className='download_icon'></div>Download Report LAS</MenuItem>
                        </div>
                        
                    :null}
                    {(!window.selectedMenuFile && reportFiles[1]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".pdf"))?
                        <MenuItem onClick={downloadReportPDF}><div className='download_icon'></div>Download Report PDF</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[2]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".csv"))?
                        <MenuItem onClick={downloadReportCSV}><div className='download_icon'></div>Download Report CSV</MenuItem>
                    :null}
                    {(!window.selectedMenuFile && reportFiles[3]) || (window.selectedMenuFile && getReportFileByID(window.selectedMenuFile).name.endsWith(".kmz"))?
                        <MenuItem onClick={downloadReportKMZ}><div className='download_icon'></div>Download Report KMZ</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reCreateLidarReport}><div className='recreate_icon'></div>Recreate</MenuItem>
                    :null}
                </Menu>
            );
        }
        else if (props.item.item.datatype === "img") {
            renderMenu = (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={"primary-action-filtermenu"}
                    className="sidebar-actionmenu-style"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={flyToLayer}><div className='fly_icon'></div>Fly To Layer</MenuItem>
                    <MenuItem onClick={enterToImage}><div className='annotation_manage'></div>Image Inspector</MenuItem>
                    {!window.selectedMenuFile?
                        <MenuItem onClick={handleFilterImageMenu} className="sidebar-viewmode-submenu"><div className='filter_submode_icon'></div>Filter Images</MenuItem>
                    :null}
                    <Menu
                        anchorEl={anchorFilterImage}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id={menuFilterImageMode}
                        className="sidebar-viewmodeMenu-style"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        open={isAnchorFilterImage}
                        onClose={handleFilterImageMenuClose}
                    >
                        <div className='image_filter'>
                            <div className='expand_filter_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedDateFilter(!isExpandedDateFilter);
                                    setExpandedTypeFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedSeverityFilter(false);
                                    setExpandedTextFilter(false);
                                }}>
                                    {!isExpandedDateFilter?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <label className='expand_filter_label' onClick={() => {
                                    setExpandedDateFilter(!isExpandedDateFilter);
                                    setExpandedTypeFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedSeverityFilter(false);
                                    setExpandedTextFilter(false);
                                }}>Date Filter</label>
                            </div>
                            {isExpandedDateFilter?
                            <div className='flex_part filter_menu'>
                                <div>
                                    <div className='image_filter_date flex_part report_date'>
                                        <div className='calendar_icon '></div>
                                        <DatePicker selected={startDate} 
                                            onChange={(date) => setStartDate(date)} 
                                            placeholderText={'All Dates'}
                                            popperProps={{
                                                positionFixed: true
                                            }}
                                        />
                                        <div className='selection_label hover_text' onClick={() => setStartDate(null)}>Start</div>
                                    </div>
                                    <div className='image_filter_date flex_part report_date'>
                                        <div className='calendar_icon '></div>
                                        <DatePicker selected={endDate} 
                                            onChange={(date) => setEndDate(date)} 
                                            placeholderText={'All Dates'}
                                            popperProps={{
                                                positionFixed: true
                                            }}
                                        />
                                        <div className='selection_label hover_text' onClick={() => setEndDate(null)}>End</div>
                                    </div>
                                </div>
                                <RadioGroup
                                    name="controlled-radio-buttons-group"
                                    value={filterOption}
                                    onChange={selectFilterOption}
                                >
                                    <FormControlLabel value="annotation" control={<Radio />} label="Annotation" />
                                    <FormControlLabel value="image" control={<Radio />} label="Image" />
                                </RadioGroup>
                            </div>
                            :null}

                            <div className='expand_filter_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedTypeFilter(!isExpandedTypeFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedSeverityFilter(false);
                                    setExpandedTextFilter(false);
                                }}>
                                    {!isExpandedTypeFilter?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <label className='expand_filter_label' onClick={() => {
                                    setExpandedTypeFilter(!isExpandedTypeFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedSeverityFilter(false);
                                    setExpandedTextFilter(false);
                                }}>Type Filter</label>
                            </div>
                            {isExpandedTypeFilter?
                            <div className='filter_menu'>
                                {getAnnotationTypeMenu()}
                            </div>
                            :null}
                            <div className='expand_filter_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedStatusFilter(!isExpandedStatusFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedTypeFilter(false);
                                    setExpandedSeverityFilter(false);
                                    setExpandedTextFilter(false);
                                }}>
                                    {!isExpandedStatusFilter?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <label className='expand_filter_label' onClick={() => {
                                    setExpandedStatusFilter(!isExpandedStatusFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedTypeFilter(false);
                                    setExpandedSeverityFilter(false);
                                    setExpandedTextFilter(false);
                                }}>Status Filter</label>
                            </div>
                            {isExpandedStatusFilter?
                            <div className='filter_menu'>
                                {getAnnotationStatusMenu()}
                            </div>
                            :null}
                            <div className='expand_filter_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedSeverityFilter(!isExpandedSeverityFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedTypeFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedTextFilter(false);
                                }}>
                                    {!isExpandedSeverityFilter?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <label className='expand_filter_label' onClick={() => {
                                    setExpandedSeverityFilter(!isExpandedSeverityFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedTypeFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedTextFilter(false);
                                }}>Severity Filter</label>
                            </div>
                            {isExpandedSeverityFilter?
                            <div className='filter_menu'>
                                <CustomCheckbox checked={filterArrays.indexOf(5) >= 0} className="filter_inspect_5_checkbox" onClick={changeFilterInspect} data-id={5}/>
                                <CustomCheckbox checked={filterArrays.indexOf(4) >= 0} className="filter_inspect_4_checkbox" onClick={changeFilterInspect} data-id={4}/>
                                <CustomCheckbox checked={filterArrays.indexOf(3) >= 0} className="filter_inspect_3_checkbox" onClick={changeFilterInspect} data-id={3}/>
                                <CustomCheckbox checked={filterArrays.indexOf(2) >= 0} className="filter_inspect_2_checkbox" onClick={changeFilterInspect} data-id={2}/>
                                <CustomCheckbox checked={filterArrays.indexOf(0) >= 0} className="filter_inspect_0_checkbox" onClick={changeFilterInspect} data-id={0}/>
                            </div>
                            :null}
                            <div className='expand_filter_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedTextFilter(!isExpandedTextFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedTypeFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedSeverityFilter(false);
                                }}>
                                    {!isExpandedTextFilter?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <label className='expand_filter_label' onClick={() => {
                                    setExpandedTextFilter(!isExpandedTextFilter);
                                    setExpandedDateFilter(false);
                                    setExpandedTypeFilter(false);
                                    setExpandedStatusFilter(false);
                                    setExpandedSeverityFilter(false);
                                }}>Text Filter</label>
                            </div>
                            {isExpandedTextFilter?
                            <div className='filter_menu'>
                                <input type="text" className="search-name-input" 
                                    value={imageSearch} 
                                    placeholder="Search Name"
                                    onChange={onChangeSearchImage}></input>
                            </div>
                            :null}
                        </div>
                    </Menu>
                    <MenuItem onClick={handlePointSize} className="sidebar-viewmode-submenu">
                        <div className='visible_icon'></div>Show Label
                            <Switch className="label_display"
                                checked={props.item.showLabel}
                                onChange={changeLabelDisplay}/>
                    </MenuItem>
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={downloadDataset}><div className='download_menu_icon'></div>Download</MenuItem>
                    :null}
                    {!window.selectedMenuFile && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={deleteDatasetFromProject}><div className='delete_menu_icon'></div>Delete</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={editDataset}><div className='edit_menu_icon'></div>Edit</MenuItem>
                    :null}
                    {!window.selectedMenuFile && props.item.item.status === "finish" && api.canEditItem(props.userInfo)?
                        <MenuItem onClick={reprocessDataset}><div className='recreate_icon'></div>Reprocess</MenuItem>
                    :null}
                </Menu>
            );
        }

        return renderMenu;
    }

    function handleFilterMenu(event) {
        if (event.currentTarget)
		    setAnchorFilter(event.currentTarget);
	}

	function handleFilterMenuClose() {
		setAnchorFilter(null);
	}

    function changeFilterInspect(event) {
		let cloneFilterArrays = filterArrays.filter(function(value) {
			return true;
		});
		let inspect = parseInt(event.currentTarget.dataset.id);
		let index = cloneFilterArrays.indexOf(inspect);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(inspect);
		}
		
		setFilterArrays(cloneFilterArrays);
        if (props.onImageFilterChange) {
            props.onImageFilterChange(props.item.item.id, cloneFilterArrays);
        }
	}

    function checkAllAnnotationType() {
		if (checkedAll) {
			setFilterTypeArrays([]);
		}
		else {
			setFilterTypeArrays(annotationTypeList.map((annotationType) => annotationType.value));
		}

		setTypeCheckAll(!checkedAll);
	}

    function getAnnotationTypeMenu() {
		const items = annotationTypeList.map((annotationType, index) => (
			<div key={index} onClick={changeFilterType} data-id={annotationType.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterTypeArrays.indexOf(annotationType.value) >= 0}/>
				<div className='filter_image_type'>{annotationType.label}</div>
			</div>
        ));

        items.unshift((
			<div key={-1} onClick={checkAllAnnotationType} className='image_filter_annotation_type'>
				<CustomCheckbox checked={checkedAll}/>
				<div className='filter_image_type'>Check/Uncheck All</div>
			</div>
        ));

        return items;
	}

    function showHideReportEntities() {
        if (props.item && props.item.files) {
            for (let i = 0; i < props.item.files.length; i ++) {
                let file = props.item.files[i];
                if (file.name.endsWith(".kmz")) {
                    let item = window.loadedObjects.get(props.item.item.datatype + "_" + file.id);
    
                    if (item && item.object && item.object.entities) {
                        let values = item.object.entities.values;
                        values.forEach(entity => {
                            if (filterReportLayerList.indexOf(entity.annotation_value) >= 0 
                            && filterReportRuleList.indexOf(entity.rule_value) >= 0
                            && filterReportSeverityList.indexOf(entity.severity_value) >= 0) {
                                entity.show = true;
                            }
                            else {
                                entity.show = false;
                            }
                        });
                    }
                }
            }
        }
    }

    function changeReportFilterLayer(event) {
		let cloneFilterArrays = filterReportLayerList.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterReportLayerList(cloneFilterArrays);
	}

    function getReportAnnotationLayerMenu() {
		const items = reportLayersList.map((reportLayer, index) => (
			<div key={index} onClick={changeReportFilterLayer} data-id={reportLayer.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterReportLayerList.indexOf(reportLayer.value) >= 0}/>
				<div className='filter_image_type'>{reportLayer.label}</div>
			</div>
        ));

        return items;
	}

    function changeReportFilterRule(event) {
		let cloneFilterArrays = filterReportRuleList.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterReportRuleList(cloneFilterArrays);
	}

    function getReportAnnotationRuleMenu() {
		const items = reportRulesList.map((reportRule, index) => (
			<div key={index} onClick={changeReportFilterRule} data-id={reportRule} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterReportRuleList.indexOf(reportRule) >= 0}/>
				<div className='filter_image_type'>{reportRule}</div>
			</div>
        ));

        return items;
	}

    function changeReportFilterSeverity(event) {
		let cloneFilterArrays = filterReportSeverityList.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterReportSeverityList(cloneFilterArrays);
	}

    function getAnnotationStatusMenu() {
		const items= annotationStatusList.map((annotationStatus, index) => (
			<div key={index} onClick={changeFilterStatus} data-id={annotationStatus.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterStatusArrays.indexOf(annotationStatus.value) >= 0}/>
				<div className='filter_image_type'>{annotationStatus.label}</div>
			</div>
        ));

        items.push((
			<div key={-1} onClick={changeFilterStatus} data-id={"not_annotated"} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterStatusArrays.indexOf("not_annotated") >= 0}/>
				<div className='filter_image_type'>Not Annotated</div>
			</div>
		));

        return items;
	}

    function onChangeSearchImage(evt) {
        setImageSearch(evt.target.value);
        if (props.onImageFilterTextChange) {
            props.onImageFilterTextChange(props.item.item.id, evt.target.value);
        }
    }

    function changeFilterStatus(event) {
		let cloneFilterArrays = filterStatusArrays.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterStatusArrays(cloneFilterArrays);

        if (props.onImageFilterStatusChange) {
            props.onImageFilterStatusChange(props.item.item.id, cloneFilterArrays);
        }
	}

    function changeFilterType(event) {
		let cloneFilterArrays = filterTypeArrays.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterTypeArrays(cloneFilterArrays);
        if (props.onImageFilterTypeChange) {
            props.onImageFilterTypeChange(props.item.item.id, cloneFilterArrays);
        }
	}

    function selectFilterOption(event, value) {
		setFilterOption(value);
	}

    function getFilterImageMenu() {
		let renderMenu;
		const menuId = 'primary-action-filtermenu';

		renderMenu = (
			<Menu
				anchorEl={anchorFilter}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={menuId}
				className="sidebar-actionmenu-style"
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				open={isFilterMenuOpen}
				onClose={handleFilterMenuClose}
			>
                <div className='image_filter'>
                    <div className='expand_filter_component'>
                        <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                            setExpandedDateFilter(!isExpandedDateFilter);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
                        }}>
                            {!isExpandedDateFilter?
                                <div className='icon below_icon'></div>
                            :
                                <div className='icon up_icon'></div>
                            }
                        </div>
                        <label className='expand_filter_label' onClick={() => {
                            setExpandedDateFilter(!isExpandedDateFilter);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
                        }}>Date Filter</label>
                    </div>
                    {isExpandedDateFilter?
                    <div className='flex_part filter_menu'>
                        <div>
                            <div className='image_filter_date flex_part report_date'>
                                <div className='calendar_icon '></div>
                                <DatePicker selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText={'All Dates'}
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                                <div className='selection_label hover_text' onClick={() => setStartDate(null)}>Start</div>
                            </div>
                            <div className='image_filter_date flex_part report_date'>
                                <div className='calendar_icon '></div>
                                <DatePicker selected={endDate} 
                                    onChange={(date) => setEndDate(date)} 
                                    placeholderText={'All Dates'}
                                    popperProps={{
                                        positionFixed: true
                                    }}
                                />
                                <div className='selection_label hover_text' onClick={() => setEndDate(null)}>End</div>
                            </div>
                        </div>
                        <RadioGroup
                            name="controlled-radio-buttons-group"
                            value={filterOption}
                            onChange={selectFilterOption}
                        >
                            <FormControlLabel value="annotation" control={<Radio />} label="Annotation" />
                            <FormControlLabel value="image" control={<Radio />} label="Image" />
                        </RadioGroup>
                    </div>
                    :null}

                    <div className='expand_filter_component'>
                        <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                            setExpandedTypeFilter(!isExpandedTypeFilter);
                            setExpandedDateFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
                        }}>
                            {!isExpandedTypeFilter?
                                <div className='icon below_icon'></div>
                            :
                                <div className='icon up_icon'></div>
                            }
                        </div>
                        <label className='expand_filter_label' onClick={() => {
                            setExpandedTypeFilter(!isExpandedTypeFilter);
                            setExpandedDateFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
                        }}>Type Filter</label>
                    </div>
                    {isExpandedTypeFilter?
                    <div className='filter_menu'>
                        {getAnnotationTypeMenu()}
                    </div>
                    :null}
                    <div className='expand_filter_component'>
                        <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                            setExpandedStatusFilter(!isExpandedStatusFilter);
                            setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
                        }}>
                            {!isExpandedStatusFilter?
                                <div className='icon below_icon'></div>
                            :
                                <div className='icon up_icon'></div>
                            }
                        </div>
                        <label className='expand_filter_label' onClick={() => {
                            setExpandedStatusFilter(!isExpandedStatusFilter);
                            setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
                        }}>Status Filter</label>
                    </div>
                    {isExpandedStatusFilter?
                    <div className='filter_menu'>
                        {getAnnotationStatusMenu()}
                    </div>
                    :null}
                    <div className='expand_filter_component'>
                        <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                            setExpandedSeverityFilter(!isExpandedSeverityFilter);
                            setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedTextFilter(false);
                        }}>
                            {!isExpandedSeverityFilter?
                                <div className='icon below_icon'></div>
                            :
                                <div className='icon up_icon'></div>
                            }
                        </div>
                        <label className='expand_filter_label' onClick={() => {
                            setExpandedSeverityFilter(!isExpandedSeverityFilter);
                            setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedTextFilter(false);
                        }}>Severity Filter</label>
                    </div>
                    {isExpandedSeverityFilter?
                    <div className='filter_menu'>
                        <CustomCheckbox checked={filterArrays.indexOf(5) >= 0} className="filter_inspect_5_checkbox" onClick={changeFilterInspect} data-id={5}/>
                        <CustomCheckbox checked={filterArrays.indexOf(4) >= 0} className="filter_inspect_4_checkbox" onClick={changeFilterInspect} data-id={4}/>
                        <CustomCheckbox checked={filterArrays.indexOf(3) >= 0} className="filter_inspect_3_checkbox" onClick={changeFilterInspect} data-id={3}/>
                        <CustomCheckbox checked={filterArrays.indexOf(2) >= 0} className="filter_inspect_2_checkbox" onClick={changeFilterInspect} data-id={2}/>
                        <CustomCheckbox checked={filterArrays.indexOf(0) >= 0} className="filter_inspect_0_checkbox" onClick={changeFilterInspect} data-id={0}/>
                    </div>
                    :null}
                    <div className='expand_filter_component'>
                        <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                            setExpandedTextFilter(!isExpandedTextFilter);
                            setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                        }}>
                            {!isExpandedTextFilter?
                                <div className='icon below_icon'></div>
                            :
                                <div className='icon up_icon'></div>
                            }
                        </div>
                        <label className='expand_filter_label' onClick={() => {
                            setExpandedTextFilter(!isExpandedTextFilter);
                            setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                        }}>Text Filter</label>
                    </div>
                    {isExpandedTextFilter?
                    <div className='filter_menu'>
                        <input type="text" className="search-name-input" 
                            value={imageSearch} 
                            placeholder="Search Name"
                            onChange={onChangeSearchImage}></input>
                    </div>
                    :null}
                </div>
			</Menu>
		);

		return renderMenu;
	}

	return (
		<div className={`project_dataset_item ${(props.hasTopBorder)?'top_border':null}`}>
			<div className={classes.dataset_info}>
                {getItemIcon()}
                {!props.isMinimize?
                <div className={[classes.name, (props.item.item.id === window.selectedDatasetID)?classes.selected:""].join(' ')} onClick={selectDataset}>{props.item.item.name}</div>
                :null
                }
                {!props.isMinimize?
                <div className={classes.datatype}>{getTypeValue()}</div>
                :null}
                {getVisibleIcon()}
                <div className="dataitem_icon can_hover_icon no_boder_line" onClick={handleShowMenu}><div className='icon popup_menu_icon'></div></div>
            </div>
            {props.item.expanded && !props.isMinimize?
                <div className={classes.expandable}>
                    {(props.item.item.datatype === "img" || props.item.item.datatype === "annotations")?
                    <div>
                        <div className={classes.expanded_list_title}>
                            <div className="expanded_icon can_hover_icon no_boder_line" onClick={expandAnnotations}><div className='icon annotation_icon'></div></div>
                            <div className="expanded_title">Annotations ({getAnnotationItemCount()})</div>
                        </div>
                        {props.item.annotationExpanded?
                            props.item.item.datatype === "img"?
                            getImageAnnotationItems()
                            :getAnnotationItems()
                        :null}
                    </div>
                    :null}
                    {(props.item.item.datatype === "pointcloud" && props.item.materialMode === "classification")?
                        <div>
                            <div className={classes.expanded_list_title}>
                                <div className="expanded_icon can_hover_icon no_boder_line" onClick={expandClassification}><div className='icon classification_icon'></div></div>
                                <div className="expanded_title">Classification</div>
                            </div>
                            {props.item.classExpanded?
                                getClassificationItem()
                            :null}
                        </div>
                    :null}
                    {(props.item.item.datatype === "Report" && canReportExpandItem())?
                        <div>
                            {getReportItems()}
                        </div>
                    :null}
                    {(props.item.item.datatype === "img")?
                        <div>
                            <div className={classes.expanded_list_title}>
                                <div className="expanded_icon can_hover_icon no_boder_line" onClick={expandImages}><div className='icon img_icon'></div></div>
                                <div className="expanded_title">Images</div>
                                <div className="expanded_icon can_hover_icon right_icon no_boder_line" title="Filter Images" onClick={handleFilterMenu}><div className='icon filter_icon'></div></div>
                            </div>
                            {props.item.imagesExpanded?
                                <div>
                                    {getImagesItem()}
                                    <Pagination
                                        className='image-page-item'
                                        defaultPage={1}
                                        count={totalImagePage}
                                        onChange={handleImagePage}
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            '& .MuiPaginationItem-root': {
                                                background: '#898989 !important',
                                                color: "#ffffff !important",
                                                fontSize: 13,
                                            },
                                            '& .Mui-selected': {
                                                background: '#03a9f4 !important',
                                                color: "#ffffff !important",
                                                fontSize: 13,
                                            },
                                        }} />
                                </div>
                            :null}
                        </div>
                    :null}
                </div>
            :null}
            {getRenderMenuItem()}
            {getFilterImageMenu()}
            {(props.item.item.datatype === "kml")?getKMZLabelDialog():null}
            {(props.item.item.datatype === "kml")?getAddingAssetDialog():null}
		</div>
	);
}

const mapStateToProps = state => ({
	allAssets : state.project.allAssets,
    selectedProject: state.project.selectedProject,
    userInfo: state.global.userInfo
});

ProjectDatasetItem.propTypes = {
}
export default compose(
	withRouter,
    connect(mapStateToProps, projectAction),
    connect(mapStateToProps, globalAction)
)(ProjectDatasetItem);
