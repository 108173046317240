import React  from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import Header from '../../components/Header';
import * as api from '../../utils/api';

import './style.scss'
import { isExpireTokenLocal } from '../../utils/utilFunctions';
import ImageAnnotationView from '../../components/ImageAnnotationView';

function ImageLayout(props) {
	if (api.isSharing()) {
		//sharing
	}
	else if (isExpireTokenLocal()) {
		return <Redirect to="/" />
	}

	return (
		<div>
			<Header
				hasSearch={false}
				hasBack={false}
			/>
			<ImageAnnotationView
			/>
		</div>
	);
}

const mapStateToProps = state => ({
});

ImageLayout.propTypes = {
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(ImageLayout);