import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { globalAction } from '../../store/actions';
import { authActionCreators } from '../../store/actions';
import './style.scss';
import { promisify } from '../../utils/promisify';
import Button from '@material-ui/core/Button';
import * as api from '../../utils/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {Modal} from 'react-bootstrap';

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
	  height: 40,
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
	  border: "1px solid white"
    })
};

const userTypeList = [
	{
		value : "user",
		label: "User",
	},
	{
		value : "viewer",
		label: "Viewer",
	}
];

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	view: {
		paddingLeft: 50,
		paddingRight: 50,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	inline: {
		display: "inline-block",
	},
	button: {
		fontSize: 15,
		fontWeight: 400,
		marginTop: 10,
		float: "right",
	},
}));

function Profile(props) {
	const classes = useStyles();

	const [showPassword, setShowPassword] = React.useState(false);
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phonenumber, setPhonenumber] = React.useState("");
	const [company, setCompany] = React.useState("");
	const [country, setCountry] = React.useState("");
	const [role, setRole] = React.useState("user");

	const [oldPassword, setOldPassword] = React.useState("");
	const [newPassword, setNewPassword] = React.useState("");
	const [confirmPassword, setConfirmPassword] = React.useState("");
	const [passwordMatched, setPasswordMatched] = React.useState(true);

	const [matchPasswordString, setMatchPasswordString] = React.useState(false);
	const [matchPasswordCapital, setMatchPasswordCapital] = React.useState(false);
	const [matchPasswordSymbol, setMatchPasswordSymbol] = React.useState(false);
	const [matchPasswordSpace, setMatchPasswordSpace] = React.useState(false);

	const [showWarning, setShowWarning] = React.useState(false);

	function onOldPasswordChange(evt) { setOldPassword(evt.target.value); }

	function containsUppercase(str) {
		return /[A-Z]/.test(str);
	}

	function containsSpecialChars(str) {
		return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
	}

	function checkPasswordCondition(passwordString) {
		if (passwordString === 0) {
			setMatchPasswordString(false);
			setMatchPasswordCapital(false);
			setMatchPasswordSymbol(false);
			setMatchPasswordSpace(false);
			return;
		}
		if (passwordString.length >= 8 && passwordString.length <= 20) {
			setMatchPasswordString(true);
		}
		else {
			setMatchPasswordString(false);
		}

		if (containsUppercase(passwordString)) {
			setMatchPasswordCapital(true);
		}
		else {
			setMatchPasswordCapital(false);
		}
		
		if (containsSpecialChars(passwordString)) {
			setMatchPasswordSymbol(true);
		}
		else {
			setMatchPasswordSymbol(false);
		}

		if (passwordString.indexOf(" ") < 0) {
			setMatchPasswordSpace(true);
		}
		else {
			setMatchPasswordSpace(false);
		}
	}

	function onNewPasswordChange(evt) { 
		setNewPassword(evt.target.value); 
		setPasswordMatched(true);
		checkPasswordCondition(evt.target.value)
	}

	function onConfirmPasswordChange(evt) { 
		setConfirmPassword(evt.target.value); 
		setPasswordMatched(true);
	}

	function onFirstNameChange(evt) { setFirstName(evt.target.value); }

	function onLastNameChange(evt) { setLastName(evt.target.value); }

	function onEmailChange(evt) { 
		if (props.isOther) {
			setEmail(evt.target.value);
		}
		else {
			setEmail(email);
			setShowWarning(true);
		}
	}

	function onPhonenumberChange(evt) { setPhonenumber(evt.target.value); }

	function onCompanyChange(evt) { setCompany(evt.target.value); }

	function onCountryChange(evt) { setCountry(evt.target.value); }

	React.useEffect(() => {
		if (!props.isOther) {
			let userToken = localStorage.getItem("userToken");
			if (userToken) {
				promisify(props.getUser, {token: userToken})
					.then((res) => {
						setEmail(res.email);
						setFirstName(res.first_name);
						setLastName(res.last_name);
						setPhonenumber(res.phone_number);
						setCompany(res.company);
						setCountry(res.country);
						setRole(res.role);
						props.setUserInfo(res);
					})
					.catch((err) => {
						redirectToLogin();
					})
			}
			else {
				redirectToLogin();
			}
		}
		else {
			if (props.isUpdate && props.profileInfo) {
				setEmail(props.profileInfo.email);
				setFirstName(props.profileInfo.first_name);
				setLastName(props.profileInfo.last_name);
				setPhonenumber(props.profileInfo.phone_number);
				setCompany(props.profileInfo.company);
				setCountry(props.profileInfo.country);
				setRole(props.profileInfo.role);
			}
			else {
				setEmail("");
				setFirstName("");
				setLastName("");
				setPhonenumber("");
				setCompany("");
				setCountry("");
				setRole("user");
			}
		}
	}, []);

	function redirectToLogin() {
        props.history.replace("/");
    }

	function checkInput(checkPassword) {
		if (email === "") {
			toast("Email Should be not empty");
			return true;
		}

		if (firstName === "") {
			toast("First Name Should be not empty");
			return true;
		}

		if (lastName === "") {
			toast("Last Name Should be not empty");
			return true;
		}

		if (phonenumber === "") {
			toast("Phone Number Should be not empty");
			return true;
		}

		if (company === "") {
			toast("Organization Should be not empty");
			return true;
		}

		if (country === "") {
			toast("Country Should be not empty");
			return true;
		}

		if (checkPassword) {
			if (newPassword !== confirmPassword) {
				setPasswordMatched(false);
				return true;
			}
			if (newPassword === "") {
				toast("Password Should be not empty");
				return true;
			}

			if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
				toast("Please check the password strength.");
				return true;
			}
		}
	}

	async function updateProfile() {
		let userToken = localStorage.getItem("userToken");
		if (checkInput(false)) return;
		
        if (userToken) {
			if (props.isOther) {
				let response = await api.updateUser({token: userToken, first_name : firstName, last_name: lastName, phone_number: phonenumber, email: email, company: company, country: country, role: role, child_user_id: props.profileInfo.id});
				if (response.data && !response.data.error) {
					toast("Update User succeed");
					props.onClose();
				}
				else {
					redirectToLogin();
				}
			}
			else {
				let response = await api.updateUser({token: userToken, first_name : firstName, last_name: lastName, phone_number: phonenumber, email: email, company: company, country: country});
				if (response.data && !response.data.error) {
					props.setUserInfo(response.data);
					toast("Update Profile succeed");
					props.onClose();
				}
				else {
					redirectToLogin();
				}
			}
        }
        else {
            redirectToLogin();
        }
	}

	async function updatePassword() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			if (props.isUpdate && !props.isOther) {
				if (newPassword !== confirmPassword) {
					setPasswordMatched(false);
					return;
				}

				if (newPassword === "") {
					toast("Password Should be not empty");
					return;
				}

				if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
					toast("Please check the password strength.");
					return;
				}

				let response = await api.updatePassword({token: userToken, old_password : oldPassword, new_password: newPassword});
				if (response.data && !response.data.error) {
					props.setUserInfo(response.data);
					if (props.onClose) {
						props.onClose();
					}
					toast("Update Password succeed");
				}
				else if (response.data && response.data.error) {
					toast(response.data.error);
				}
			}
            else if (props.isUpdate) {
				if (newPassword !== confirmPassword) {
					setPasswordMatched(false);
					return;
				}
				if (newPassword === "") {
					toast("Password Should be not empty");
					return;
				}

				if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
					toast("Please check the password strength.");
					return;
				}

				let response = await api.updatePassword({token: userToken, old_password : oldPassword, new_password: newPassword, child_user_id: props.profileInfo.id});
				if (response.data && !response.data.error) {
					toast("Update Password succeed");
					if (props.onClose) {
						props.onClose();
					}
				}
				else if (response.data && response.data.error) {
					toast(response.data.error);
				}
			}
			else if (props.isOther && props.userInfo) {
				if (checkInput(true)) return;

				let response = await api.register({
					token: userToken,
					email: email,
					firstname: firstName,
					lastname: lastName,
					phonenumber: phonenumber,
					password: newPassword,
					company: company,
					country: country,
					owner_user_id: props.userInfo.id,
					role: role,
				});
				if (response.data && !response.data.error) {
					toast("Create User Succeed");
					if (props.onClose) {
						props.onClose();
					}
				}
				else if (response.data && response.data.error) {
					toast(response.data.error);
				}
			}
        }
        else {
            redirectToLogin();
        }
	}

	function selectUserRole(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setRole(newValue.value);
			}
		}
	}

	function onClickShowPassword() {
		setShowPassword(!showPassword);
	}

	function hideWarningDialog() {
		setShowWarning(false);
	}

	return (
		<div className={clsx(classes.view)}
		>
			<div className={clsx(classes.inline)}>
				<form autoComplete="off">
					<p className="label">First Name</p>
					<input type="text" className={`input-class ${firstName === "" ? 'red_text_border' : ''}`} value={firstName} required onChange={onFirstNameChange} autoComplete="off"/>

					<p className="label">Last Name</p>
					<input type="text" className={`input-class ${lastName === "" ? 'red_text_border' : ''}`} value={lastName} required onChange={onLastNameChange} autoComplete="off"/>
					
					<p className="label">Email Address</p>
					<input type="email" className={`input-class ${email === "" ? 'red_text_border' : ''}`} value={email} required onChange={onEmailChange} autoComplete="off"/>

					<p className="label">Phone Number</p>
					<input type="phonenumber" className={`input-class ${phonenumber === "" ? 'red_text_border' : ''}`} required value={phonenumber} onChange={onPhonenumberChange} autoComplete="off"/>

					<p className="label">Organization</p>
					<input type="text" className={`input-class ${company === "" ? 'red_text_border' : ''}`} value={company} required onChange={onCompanyChange} autoComplete="off"/>

					<p className="label">Country</p>
					<input type="text" className={`input-class ${country === "" ? 'red_text_border' : ''}`} value={country} required onChange={onCountryChange} autoComplete="off"/>

					{props.isOther?
						<div>
							<p className="label">User Role</p>
							<div className='profile_select'>
								<Select
									isMulti={false}
									onChange={selectUserRole}
									styles={selectStyle}
									options={userTypeList}
									isSearchable={false}
									menuPlacement="top"
									value = {
										userTypeList.filter(function (option) {
											return option.value === role;
										})
									}
								></Select>
							</div>
						</div>
					:null}
					{props.isUpdate?
					<Button
						variant="contained"
						color="secondary"
						onClick={updateProfile}
						className={classes.button}
					>
						{props.isOther?"Update User":"Update Profile"}
					</Button>
					:null}
					{!props.isUpdate?
						<div id="password_part">
							<div className='flex_part'>
								<div className='passwort_input_part'>
									{props.isUpdate?
									<div>
										<p className="label" id="old_password">Old Password</p>
										<div className='flex_part'>
											<input type="text" className={`input-class input-profile-password ${oldPassword === "" ? 'red_text_border' : showPassword?'':'password_security'}`} value={oldPassword} onChange={onOldPasswordChange} autoComplete="new-password"/>
											<div className={"password_visible_icon can_hover_icon no_boder_line"} onClick={onClickShowPassword}>
												{showPassword?
												<div className='icon visible_icon'></div>:
												<div className='icon invisible_icon'></div>}
											</div>
										</div>
										
									</div>
									:null}
									<p className="label">{props.isUpdate?"New Password":"Password"}</p>
									<div className='flex_part'>
										<input type="text" className={`input-class input-profile-password ${newPassword === "" ? 'red_text_border' : showPassword?'':'password_security'}`} value={newPassword} onChange={onNewPasswordChange} autoComplete="new-password"/>
										<div className={"password_visible_icon can_hover_icon no_boder_line"} onClick={onClickShowPassword}>
											{showPassword?
											<div className='icon visible_icon'></div>:
											<div className='icon invisible_icon'></div>}
										</div>
									</div>
									
									<p className="label">Confirm Password</p>
									<div className='flex_part'>
										<input type="text" className={`input-class input-profile-password ${confirmPassword === "" ? 'red_text_border' : showPassword?'':'password_security'}`} value={confirmPassword} onChange={onConfirmPasswordChange} autoComplete="new-password"/>
										<div className={"password_visible_icon can_hover_icon no_boder_line"} onClick={onClickShowPassword}>
											{showPassword?
											<div className='icon visible_icon'></div>:
											<div className='icon invisible_icon'></div>}
										</div>
									</div>
								</div>

								<div className='reset_password_condition'>
									<div>Password must include:</div>
									<div className='password_condition_explain'>
										{matchPasswordString?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>8-20 Characters</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordCapital?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>At least one capital letter</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordSymbol?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>At least one symbol</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordSpace?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>No Spaces</div>
									</div>
								</div>
							</div>
							
							<div className="warning_password">
								{passwordMatched?
								null:
								<p>Password Not Matched</p>}
							</div>
							<Button
								variant="contained"
								color="secondary"
								onClick={updatePassword}
								className={classes.button}
							>
							{props.isUpdate?"Update Password":props.isOther?"Create User":"Create Profile"}
							</Button>
						</div>
					:null}
				</form>
			</div>
			<Modal show={showWarning} animation={false} className="modal-verify-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideWarningDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='verification_message'>
						To change primary email address, please sign into your billing portal from <a href="http://www.PoleHawk.com" target="_blank">http://www.PoleHawk.com</a>
                    </div>
                </Modal.Body>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
	userInfo: state.global.userInfo
});

Profile.propTypes = {
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, authActionCreators),
)(Profile);