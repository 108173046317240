export function isExpireTokenLocal() {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
        let checkTime = localStorage.getItem("checkTime");
        if (new Date().getTime() - checkTime > 12 * 60 * 60 * 1000) {
            return true;
        }
        else {
            return false;
        }
    }

    return true;
}