import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { authActionCreators } from '../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { promisify } from '../../utils/promisify';
import * as api from '../../utils/api';
import logo from '../../assets/images/logo.png';
import { globalAction, projectAction } from '../../store/actions';
import './style.scss';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import ReactCodeInput from 'react-verification-code-input';
import { toast } from 'react-toastify';
import { CircularProgress} from '@mui/material';

const useStyles = makeStyles(theme => ({
	show: {
		display: 'block !important',
	},
	hide: {
		display: 'none !important',
	}
}));

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);

function SignInScreen(props) {
	const classes = useStyles();
	const [isProgress, setProgressing] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [userID, setUserID] = React.useState(-1);
	const [userEmail, setUserEmail] = React.useState();
	const [showVerify, setShowVerify] = React.useState(false);
	const [verificationCode, setVerificationCode] = React.useState("");
	const [codeStatus, setCodeStatus] = React.useState(0); // 0:failed, 1: checking, 2: succeed, 3: expired

	const [showPolicy, setShowPolicy] = React.useState(false);
	const [responseInfo, setResponseInfo] = React.useState(null);

	const [showReset, setShowReset] = React.useState(false);
	const [resetPassword, setResetPassword] = React.useState("");
	const [resetEmail, setResetEmail] = React.useState("");
	const [resetConfirmPassword, setResetConfirmPassword] = React.useState("");
	const [passwordMatched, setPasswordMatched] = React.useState(true);

	const [matchPasswordString, setMatchPasswordString] = React.useState(false);
	const [matchPasswordCapital, setMatchPasswordCapital] = React.useState(false);
	const [matchPasswordSymbol, setMatchPasswordSymbol] = React.useState(false);
	const [matchPasswordSpace, setMatchPasswordSpace] = React.useState(false);

	const [showSubscribe, setShowSubscrbe] = React.useState(false);
	const [subscribeMessage, setSubscribeMessage] = React.useState("");

	const [loginStep, setLoginStep] = React.useState(0);

	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phonenumber, setPhonenumber] = React.useState("");
	const [company, setCompany] = React.useState("");
	const [country, setCountry] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [agreeTerms, setAgreeTerms] = React.useState(false);
	const [newPassword, setNewPassword] = React.useState("");
	const [confirmPassword, setConfirmPassword] = React.useState("");

	const [showPassword, setShowPassword] = React.useState(false);
	const [showResetPassword, setShowResetPassword] = React.useState(false);
	const [showRegisterPassword, setShowRegisterPassword] = React.useState(false);

	function onRegisterNewPasswordChange(evt) {
		setNewPassword(evt.target.value);
		setPasswordMatched(true);
		checkPasswordCondition(evt.target.value);
	}

	function onRegisterConfirmPasswordChange(evt) {
		setConfirmPassword(evt.target.value);
		setPasswordMatched(true);
	}

	function onFirstNameChange(evt) { 
		setFirstName(evt.target.value); 
		setErrorMessage("");
	}

	function onLastNameChange(evt) { 
		setLastName(evt.target.value); 
		setErrorMessage("");
	}

	function onEmailChange(evt) {
		setEmail(evt.target.value);
		setErrorMessage("");
	}

	function onPhonenumberChange(evt) {
		setPhonenumber(evt.target.value); 
		setErrorMessage("");
	}

	function onCompanyChange(evt) {
		setCompany(evt.target.value); 
		setErrorMessage("");
	}

	function onCountryChange(evt) { 
		setCountry(evt.target.value); 
		setErrorMessage("");
	}

	function handleTermsChange() {
		setAgreeTerms(!agreeTerms);
	}

	function gotoTerms() {
		window.open("https://www.polehawk.com/terms-of-service", '_blank');
	}

	async function loginSharing(password) {
		let share_token = api.getParameterByName("share_token");
		let project_id = api.getParameterByName("project_id");
		let response = await api.checkPasswordLink({project_id :project_id, token: share_token, password: password});
		if (response.data && !response.data.error) {
			api.saveShareInfo(share_token, project_id, response.data.password);
			props.history.replace("/map" + props.location.search);
		}
		else if (response.data) {
			setErrorMessage(response.data.error);
		}
	}

	async function handleSubmit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		setProgressing(true);
		setErrorMessage("");
		if (api.isSharing()) {
			loginSharing(password);
			setProgressing(false);
			return;
		}

		if (loginStep === 0) {
			let response = await api.checkEmail({email: email});
			setProgressing(false);

			if (response.data && response.data.success) {
				setLoginStep(2);
				setShowPassword(false);
			}
			else if (response.data && response.data.error) {
				if (response.data.verify) {
					setShowVerify(true);
					setUserID(response.data.id);
					setUserEmail(response.data.email);
					setVerificationCode("");
					setCodeStatus(0);
				}
				else if (response.data.subscription_check) {
					setShowSubscrbe(true);
					setSubscribeMessage(response.data.error);
				}
				else {
					setErrorMessage(response.data.error);
				}
			}
			else if (response.data && response.data.subscribe && response.data.profile) {
				let profile = response.data.profile;
				setPhonenumber(profile.phone);
				setFirstName(profile.name.split(" ")[0]);
				if (profile.name.split(" ").length > 1) {
					setLastName(profile.name.substring(profile.name.indexOf(' ') + 1));
				}
				else {
					setLastName("");
				}
				if (profile.address) {
					if (profile.address.country) {
						setCountry(profile.address.country)
					}
					else {
						setCountry("");
					}
					if (profile.address.company) {
						setCompany(profile.address.company);
					}
					else {
						setCompany("")
					}
				}
				
				setLoginStep(1);
				setMatchPasswordCapital(false);
				setMatchPasswordSpace(false);
				setMatchPasswordString(false);
				setMatchPasswordSymbol(false);
				setShowRegisterPassword(false);
			}
		}
		else if (loginStep === 2) {
			const userToken = localStorage.getItem("userToken");
			const credential = { email: email, password: password, token: userToken};
			
			promisify(props.login, credential)
			.then((res) => {
				setProgressing(false);
				if (!res.error) {
					if (res.user) {
						if (!res.user.agree_status) {
							setResponseInfo(res);
							setShowPolicy(true);
						}
						else {
							localStorage.setItem("userToken", res.token);
							props.setDatasetList(new Map());
							if (res.user) {
								props.setUserInfo(res.user);
								if (res.user.project_id)
									sessionStorage.setItem("selected_project_id", res.project_id);
							}
							
							localStorage.setItem("checkTime", new Date().getTime());
							props.history.replace("/map");
						}
					}
					else {
						setErrorMessage("User is invalid");
					}
				}
				else if (res.error && res.verify) {
					setShowVerify(true);
					setUserID(res.id);
					setUserEmail(res.email);
					setVerificationCode("");
					setCodeStatus(0);
				}
				else if (res.error && res.subscription_check) {
					setShowSubscrbe(true);
					setSubscribeMessage(res.error);
				}
				else {
					setErrorMessage(res.error);
				}
			})
			.catch((err) => {
				setProgressing(false);
				setErrorMessage(err);
			})
		}
		else if (loginStep === 1) {
			if (confirmPassword !== newPassword) {
				setPasswordMatched(false);
				return;
			}
			else {
				setPasswordMatched(true);
			}

			if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
				toast("Please check the password strength.");
				return;
			}

			const postData = {
				email: email,
				firstname: firstName,
				lastname: lastName,
				phonenumber: phonenumber,
				password: newPassword,
				company: company,
				country: country,
				agreeTerms: agreeTerms,
				role: 'owner',
			};
	
			promisify(props.register, postData)
				.then((res) => {
					setProgressing(false);
					if (!res.error && res.success) {
						localStorage.removeItem("userToken");
						localStorage.removeItem("checkTime");
						setLoginStep(2);
						setShowPassword(false);
					}
					else {
						setErrorMessage(res.error);
					}
				})
				.catch((err) => {
					setProgressing(false);
					setErrorMessage(err);
				})
		}
	}

	function showResetPasswordDialog() {
		setShowReset(true);
		setVerificationCode("");
		setCodeStatus(0);
		setResetEmail("");
		setResetConfirmPassword("");
		setResetPassword("");
		setPasswordMatched(true);
		setMatchPasswordCapital(false);
		setMatchPasswordSpace(false);
		setMatchPasswordString(false);
		setMatchPasswordSymbol(false);
		setShowResetPassword(false);
	}

	function showSignUp() {
		window.open(api.price_link, '_blank');
	}

	function verificationEmailInputChange(event) { 
		setResetEmail(event.target.value); 
	}

	function onNewPasswordChange(evt) { 
		setResetPassword(evt.target.value);
		checkPasswordCondition(evt.target.value);
	}

	function onConfirmPasswordChange(evt) { 
		setResetConfirmPassword(evt.target.value);
		if (evt.target.value !== resetPassword) {
			setPasswordMatched(false);
		}
		else {
			setPasswordMatched(true);
		}
	}

	function containsUppercase(str) {
		return /[A-Z]/.test(str);
	}

	function containsSpecialChars(str) {
		return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
	}

	function checkPasswordCondition(passwordString) {
		if (passwordString === 0) {
			setMatchPasswordString(false);
			setMatchPasswordCapital(false);
			setMatchPasswordSymbol(false);
			setMatchPasswordSpace(false);
			return;
		}
		if (passwordString.length >= 8 && passwordString.length <= 20) {
			setMatchPasswordString(true);
		}
		else {
			setMatchPasswordString(false);
		}

		if (containsUppercase(passwordString)) {
			setMatchPasswordCapital(true);
		}
		else {
			setMatchPasswordCapital(false);
		}
		
		if (containsSpecialChars(passwordString)) {
			setMatchPasswordSymbol(true);
		}
		else {
			setMatchPasswordSymbol(false);
		}

		if (passwordString.indexOf(" ") < 0) {
			setMatchPasswordSpace(true);
		}
		else {
			setMatchPasswordSpace(false);
		}
	}

	function emailInputChange(evt) { 
		setEmail(evt.target.value); 
		setErrorMessage("");
	}

	function passwordInputChange(evt) {
		setPassword(evt.target.value);
		setErrorMessage("");
	}

	function hideVerifyDialog() {
		setShowVerify(false);
		setUserEmail("");
		setUserID(-1);
		setVerificationCode("");
		setCodeStatus(0);
	}

	async function resendVerification() {
		let response = await api.resendVerification({id: userID});

		if (response.data && response.data.success) {
			toast("Sent Verification Code");
		}
		else if (response.data && response.data.error) {
			toast(response.data.error);
		}
	}

	async function verify() {
		if (verificationCode.length != 6) {
			toast("Please input 6 digits");
			return;
		}

		if (codeStatus === 1) {
			toast("Checking Code");
			return;
		}

		if (codeStatus === 0) {
			toast("Wrong Code");
			return;
		}

		if (codeStatus === 3) {
			toast("Expire Code");
			return;
		}

		let response = await api.verifyAuthentication({code: verificationCode, email: userEmail});

		if (response.data && response.data.success) {
			toast(response.data.success);
			setShowVerify(false);
			setUserEmail("");
			setUserID(-1);
		}
		else if (response.data && response.data.error) {
			toast(response.data.error);
		}
	}

	async function onChangeCode(value) {
		setVerificationCode(value);

		if (value.length === 6) {
			setCodeStatus(1);
			let response = await api.checkAuthenticationCode({code: value, email: userEmail});

			if (response.data && response.data.success) {
				setCodeStatus(2);
			}
			else if (response.data && response.data.failed) {
				setCodeStatus(0);
			}
			else if (response.data && response.data.expired) {
				setCodeStatus(3);
			}
			else {
				setCodeStatus(0);
			}
		}
		else {
			setCodeStatus(0);
		}
	}

	function hidePolicyDialog() {
		setShowPolicy(false);
		setResponseInfo(null);
	}

	async function accept() {
		let response = await api.agreePolicy({id: responseInfo.user.id});

		if (response.data && response.data.success) {
			localStorage.setItem("userToken", responseInfo.token);
			props.setDatasetList(new Map());
			if (responseInfo.user) {
				props.setUserInfo(responseInfo.user);
				if (responseInfo.user.project_id)
					sessionStorage.setItem("selected_project_id", responseInfo.project_id);
			}
			
			localStorage.setItem("checkTime", new Date().getTime());
			props.history.replace("/map");
		}
		else if (response.data && response.data.error) {
			toast(response.data.error);
		}
	}

	function decline() {
		hidePolicyDialog();
		toast("You should accept the Privacy Policy and Terms of Conditions to use PoleHawk.")
	}

	function hideResetDialog() {
		setVerificationCode("");
		setCodeStatus(0);
		setResetEmail("");
		setResetConfirmPassword("");
		setResetPassword("");
		setPasswordMatched(true);
		setMatchPasswordCapital(false);
		setMatchPasswordSpace(false);
		setMatchPasswordString(false);
		setMatchPasswordSymbol(false);
		setShowReset(false);
	}

	async function savePassword() {
		if (resetEmail === "") {
			toast("Verification Email should be not empty.");
			return;
		}
		if (verificationCode.length != 6) {
			toast("Please input 6 digits.");
			return;
		}

		if (resetPassword !== resetConfirmPassword) {
			setPasswordMatched(false);
			return;
		}

		if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
			toast("Please check the password strength.");
			return;
		}

		let response = await api.resetPassword({email: resetEmail, password: resetPassword, code: verificationCode});

		if (response.data && response.data.success) {
			toast(response.data.success);
			hideResetDialog();
		}
		else if (response.data && response.data.error) {
			toast(response.data.error);
		}
	}

	async function sendResetEmail() {
		if (resetEmail === "") {
			toast("Verification Email should be not empty.");
			return;
		}
		
		let response = await api.verifyEmailForReset({email: resetEmail});

		if (response.data && response.data.success) {
			toast(response.data.success);
		}
		else if (response.data && response.data.error) {
			toast(response.data.error);
		}
	}

	function showHelp() {
		toast("To reset your password, please first verify your email by clicking the \"Send Email\" button and then copying the 6 digits security code from your verification email into the boxes below:", {
			autoClose: 10000
		})
	}

	function hideSubscribeDialog() {
		setSubscribeMessage("");
		setShowSubscrbe(false);
	}

	function goToBill() {
		window.open(api.price_link, '_blank');
		hideSubscribeDialog();
	}
	
	function onClickShowPassword() {
		setShowPassword(!showPassword);
	}

	function onClickRegisterShowPassword() {
		setShowRegisterPassword(!showRegisterPassword);
	}

	function onClickResetShowPassword() {
		setShowResetPassword(!showResetPassword);
	}

	return (
		<div>
			<div id="wrapper" className="login-register login-sidebar">
				<div className="login-box card">
					<div className="card-body">
						<form id="loginform" onSubmit={handleSubmit} className="form-horizontal form-material">
							<a className="text-center db"><img src={logo} alt="Home" style={{width: "50%"}}/></a>
							{api.isSharing()?null:
							loginStep === 0 || loginStep === 2?
							<div className={`form-group username-input-area ${loginStep === 2?'hide_component':''}`}>
								<div className="col-xs-12">
									<p className="login-input-label">Email</p>
									<input className="form-control" type="email" name="email" required placeholder="Email" value={email} onChange={emailInputChange} />
								</div>
							</div>
							:null
							}
							{api.isSharing() || loginStep === 2 || loginStep === 0?
							<div className={`form-group username-input-area ${loginStep === 0 && !api.isSharing()?'hide_component':''}`}>
								<div className="col-xs-12">
									<p className="login-input-label">Password</p>
									<div className='flex_part'>
										<input className="form-control" type={showPassword?"text":"password"} name="password" placeholder="Password" onChange={passwordInputChange} />
										<div className={"password_visible_icon can_hover_icon no_boder_line"} onClick={onClickShowPassword} id="show_password">
											{showPassword?
											<div className='icon visible_icon'></div>:
											<div className='icon invisible_icon'></div>}
										</div>
									</div>
								</div>
							</div>
							:null
							}

							{!api.isSharing() && loginStep === 1?
							<div>
								<h3 className="box-title m-t-40 m-b-0">Register Now</h3><small>Create your account and enjoy</small>
								<div className="form-group m-t-20">
									<div className="col-xs-12">
										<input className={`form-control form-control-no-bottom ${firstName === "" ? 'red_text_border' : ''}`} onChange={onFirstNameChange} type="text" required placeholder="First Name" autoComplete="off" value={firstName}/>
									</div>
								</div>

								<div className="form-group ">
									<div className="col-xs-12">
										<input className={`form-control form-control-no-bottom ${lastName === "" ? 'red_text_border' : ''}`} onChange={onLastNameChange} type="text" required placeholder="Last Name" autoComplete="off" value={lastName}/>
									</div>
								</div>

								<div className="form-group ">
									<div className="col-xs-12">
										<input className={`form-control form-control-no-bottom ${phonenumber === "" ? 'red_text_border' : ''}`} onChange={onPhonenumberChange} type="text" required placeholder="Phone Number" autoComplete="off" value={phonenumber}/>
									</div>
								</div>

								<div className="form-group ">
									<div className="col-xs-12">
										<input className={`form-control form-control-no-bottom ${company === "" ? 'red_text_border' : ''}`} onChange={onCompanyChange} type="text" required placeholder="Company" autoComplete="off" value={company}/>
									</div>
								</div>

								<div className="form-group ">
									<div className="col-xs-12">
										<input className={`form-control form-control-no-bottom ${country === "" ? 'red_text_border' : ''}`} onChange={onCountryChange} type="text" required placeholder="Country" autoComplete="off" value={country}/>
									</div>
								</div>

								<div className="form-group ">
									<div className="col-xs-12">
										<div className='flex_part'>
											<input className={`form-control form-control-no-bottom ${newPassword === "" ? 'red_text_border' : showRegisterPassword?'':'password_security'}`} type="text" required placeholder="Password" onChange={onRegisterNewPasswordChange} autoComplete="new-password"/>
											<div className={"password_visible_icon can_hover_icon no_boder_line show_register_password"} onClick={onClickRegisterShowPassword}>
												{showRegisterPassword?
												<div className='icon visible_icon'></div>:
												<div className='icon invisible_icon'></div>}
											</div>
										</div>
									</div>
								</div>

								<div className="form-group">
									<div className="col-xs-12">
										<div className='flex_part'>
											<input className={`form-control form-control-no-bottom  ${confirmPassword === "" ? 'red_text_border' : showRegisterPassword?'':'password_security'}`} type="text" required placeholder="Confirm Password" onChange={onRegisterConfirmPasswordChange} autoComplete="new-password"/>
											<div className={"password_visible_icon can_hover_icon no_boder_line show_register_password"} onClick={onClickRegisterShowPassword}>
												{showRegisterPassword?
												<div className='icon visible_icon'></div>:
												<div className='icon invisible_icon'></div>}
											</div>
										</div>
										
									</div>
								</div>

								<div className='reset_password_condition'>
									<div>Password must include:</div>
									<div className='password_condition_explain'>
										{matchPasswordString?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>8-20 Characters</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordCapital?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>At least one capital letter</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordSymbol?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>At least one symbol</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordSpace?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>No Spaces</div>
									</div>
								</div>

								{/* <div className="form-group">
									<div className="col-xs-12">
										<div className="checkbox checkbox-primary p-t-0">
											<CustomCheckbox className="checkbox-signup" checked={agreeTerms} onChange={handleTermsChange} />
											<label htmlFor="checkbox-signup" onClick={gotoTerms}> I agree to all <a href="#">Terms</a></label>
										</div>
									</div>
								</div> */}

								<div className={clsx("form-group", {
									[classes.show]: !passwordMatched,
									[classes.hide]: passwordMatched
								})}>
									<div className="col-xs-12">
										<label className="error-label">Password Not Matched.</label>
									</div>
								</div>
							</div>
							:null
							}

							<div className={clsx("form-group", {
								[classes.show]: isProgress,
								[classes.hide]: !isProgress
							})}>
								<CircularProgress size={30}/>
							</div>
							
							<div className={clsx("form-group", {
								[classes.show]: errorMessage !== "",
								[classes.hide]: errorMessage === ""
							})}>
								<div className="col-xs-12">
									<label className="error-label">{errorMessage}</label>
								</div>
							</div>
							<div className="form-group text-center m-t-20">
								<div className="col-xs-12">
									<button className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">
										{api.isSharing()?"Log in":loginStep === 0?"Next":loginStep === 1?"Register":"Log in"}
									</button>
								</div>
							</div>
							{api.isSharing()?null:
							<div className="form-group m-b-0">
								<div className="col-sm-12 text-center">
									Don't have an account? <a className="text-primary m-l-5" onClick={showSignUp}><b>Subscribe Here</b></a>
								</div>
							</div>
							}
							{api.isSharing() || loginStep === 1?null:
							<div className="form-group m-b-0">
								<div className="col-sm-12 text-center">
									Forgot your password? <a className="text-primary m-l-5" onClick={showResetPasswordDialog}><b>Reset Password</b></a>
								</div>
							</div>
							}
						</form>
					</div>
				</div>
			</div>

			<Modal show={showVerify} animation={false} className="modal-verify-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideVerifyDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='verification_message'>
						Please verify your account by copying the 6 digits security code from your verification email into the boxes below:
                    </div>
					<div className='verification_info_part'>
						<div className='verification_email_label'>ACCOUNT EMAIL:</div>
						<div className='verification_email'>{userEmail}</div>
						<Button variant="contained" onClick={resendVerification} className="resend_button">
							SEND CODE
						</Button>
                    </div>
					<div className='verification_info_part'>
						<div className='verification_code_label'>VERIFICATION CODE:</div>
						<div className='verification_code'>
							<ReactCodeInput fieldWidth={50} fieldHeight={50} onChange={onChangeCode} className="verification_code_input"/>
						</div>
						<div className='verification_status'>
							{codeStatus === 0 ?
							<div className='code_failed_status'></div>
							:codeStatus === 1 ?
							<div className='code_loading_status'>
								<CircularProgress size={30}/>
							</div>
							:codeStatus === 2 ?
							<div className='code_success_status' title="Success"></div>
							:
							<div className='code_expiration_status' title="Expired"></div>
							}
						</div>
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained" onClick={verify} className="verify_button">
						VERIFY
					</Button>
                </Modal.Footer>
            </Modal>

			<Modal show={showPolicy} animation={false} className="modal-verify-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hidePolicyDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='verification_message'>
						Polehawk's Privacy Policy and Terms And Conditions can be read by clicking the links below. After reading them, please accept or decline.
                    </div>
					<div className='policy_link_part'>
						<a href="https://www.polehawk.com/privacy-policy" target="_blank">https://www.PoleHawk.com/Privacy-Policy</a>
                    </div>
					<div className='policy_link_part'>
						<a href="https://www.polehawk.com/terms-of-service" target="_blank">https://www.PoleHawk.com/Terms-Of-Service</a>
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained" onClick={accept} className="verify_button">
						ACCEPT
					</Button>
					<Button variant="contained" onClick={decline} className="resend_button">
						DECLINE
					</Button>
                </Modal.Footer>
            </Modal>

			<Modal show={showSubscribe} animation={false} className="modal-verify-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideSubscribeDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='verification_message'>
						{subscribeMessage}
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained" onClick={goToBill} className="verify_button">
						SUBSCRIBE
					</Button>
					<Button variant="contained" onClick={hideSubscribeDialog} className="resend_button">
						CLOSE
					</Button>
                </Modal.Footer>
            </Modal>

			<Modal show={showReset} animation={false} className="modal-reset-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo_withhelp'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='help_button' onClick={showHelp}>
								<img src="/help.png" alt="close" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideResetDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<div className='verification_info_part'>
						<div className='verification_email_label'>ACCOUNT EMAIL:</div>
						<input className={`input-email-class ${resetEmail === "" ? 'red_text_border' : ''}`} type="email" name="verification_email" required placeholder="Verification Email" onChange={verificationEmailInputChange} />
						<Button variant="contained" onClick={sendResetEmail} className="send_email_button">
							SEND EMAIL
						</Button>
                    </div>
					<div className='verification_info_part'>
						<div className='verification_code_label'>VERIFICATION CODE:</div>
						<div className='verification_code'>
							<ReactCodeInput fieldWidth={50} fieldHeight={50} onChange={onChangeCode} className="verification_code_input"/>
							</div>
                    </div>
					<div className='verification_info_part'>
						<div className='divider'></div>
                    </div>
					<div className='verification_info_part'>
						<div className='reset_password_part'>
							<div className='flex_part'>
								<p className="verification_password_label">Password:</p>
								<input type="text" className={`input-password-class ${resetPassword === "" ? 'red_text_border' : showResetPassword?'':'password_security'}`} value={resetPassword} onChange={onNewPasswordChange} autoComplete="new-password"/>
								<div className={"password_visible_icon can_hover_icon no_boder_line show_reset_password"} onClick={onClickResetShowPassword}>
									{showResetPassword?
									<div className='icon visible_icon'></div>:
									<div className='icon invisible_icon'></div>}
								</div>
							</div>
							<div className='flex_part'>
								<p className="verification_password_label">Confirm:</p>
								<input type="text" className={`input-password-class ${resetConfirmPassword === "" ? 'red_text_border' : showResetPassword?'':'password_security'}`} value={resetConfirmPassword} onChange={onConfirmPasswordChange} autoComplete="new-password"/>
								<div className={"password_visible_icon can_hover_icon no_boder_line show_reset_password"} onClick={onClickResetShowPassword}>
									{showResetPassword?
									<div className='icon visible_icon'></div>:
									<div className='icon invisible_icon'></div>}
								</div>
							</div>
							<div className="warning_password">
								{passwordMatched?
								null:
								<p>Password Not Matched</p>}
							</div>
						</div>
						<div className='reset_password_condition'>
							<div>Password must include:</div>
							<div className='password_condition_explain'>
								{matchPasswordString?
								<div className='check_image'></div>
								:
								<div className='fail_image'></div>
								}
								<div>8-20 Characters</div>
							</div>
							<div className='password_condition_explain'>
								{matchPasswordCapital?
								<div className='check_image'></div>
								:
								<div className='fail_image'></div>
								}
								<div>At least one capital letter</div>
							</div>
							<div className='password_condition_explain'>
								{matchPasswordSymbol?
								<div className='check_image'></div>
								:
								<div className='fail_image'></div>
								}
								<div>At least one symbol</div>
							</div>
							<div className='password_condition_explain'>
								{matchPasswordSpace?
								<div className='check_image'></div>
								:
								<div className='fail_image'></div>
								}
								<div>No Spaces</div>
							</div>
						</div>
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained" onClick={savePassword} className="verify_button">
						SAVE
					</Button>
					<Button variant="contained" onClick={hideResetDialog} className="resend_button">
						CANCEL
					</Button>
                </Modal.Footer>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
});

SignInScreen.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired
}

export default compose(
	withRouter,
	connect(mapStateToProps, authActionCreators),
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(SignInScreen);