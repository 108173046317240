import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import Select from 'react-select';
import { CircularProgress } from '@mui/material';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Chart } from "react-google-charts";
import * as mapUtils from '../../components/MapView/MapUtils';
import { Menu, MenuItem } from '@material-ui/core';
import { Checkbox, RadioGroup, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import './style.scss';
import { toast } from 'react-toastify';

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        overflow: "auto",
        width: "100%"
	},
    info_section: {
        position: 'absolute',
        marginTop: '5px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 65px)',
        overflow: "auto"
    },
    panel_label : {
        color: "#2196f3",
        fontSize: 20,
        marginTop: 3,
        textAlign: 'left',
        float: 'left'
    },
    circular_progress: {
        width: 40,
        height: 40,
        position: 'absolute',
    },
    analytics_info: {
        fontSize: 15,
        fontWeight: 400,
        display: "flex",
    },
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      border: "1px solid white"
    })
};

function DashboardPanel(props) {
	const classes = useStyles();

    const [projectID, selectProjectID] = React.useState(-1);
    const [projectList, setProjectList] = React.useState([
        {
            value : -1,
            label: "All Projects",
        },
    ]);
    const [progress, setProgress] = React.useState(true);
    const [accountExtended, setAccountExtended] = React.useState(true);
    const [annotationSeverityExtend, setAnnotationSeverityExtend] = React.useState(false);
    const [annotationTypeExtend, setAnnotationTypeExtend] = React.useState(false);
    const [annotationStatusExtend, setAnnotationStatusExtend] = React.useState(false);
    const [imageExtended, setImageExtended] = React.useState(false);
    const [maxSize, setMaxSize] = React.useState(5 * 1024 * 1024 * 1024 * 1024);
    const [annotation_severity, setAnnotationSeverity] = React.useState([]);
    const [image_severity, setImageSeverity] = React.useState([]);

    const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);

    const [isExpandedTypeFilter, setExpandedTypeFilter] = React.useState(false);
	const [isExpandedStatusFilter, setExpandedStatusFilter] = React.useState(false);
	const [isExpandedSeverityFilter, setExpandedSeverityFilter] = React.useState(false);

    const [filterArrays, setFilterArrays] = React.useState([0, 2, 3, 4, 5]);
	const [filterTypeArrays, setFilterTypeArrays] = React.useState([]);
	const [filterStatusArrays, setFilterStatusArrays] = React.useState([""]);

    const [anchorFilter, setAnchorFilter] = React.useState(null);
	const isFilterMenuOpen = Boolean(anchorFilter);
    const [checkedAll, setTypeCheckAll] = React.useState(true);

    const annotation_severity_options= {
        legend: 'none',
        slices: {0: {color: '#ed1b24'}, 1:{color: '#fe7e01'}, 2:{color: '#ffff01'}, 3: {color: '#00ff01'}},
        backgroundColor: '#17181b',
        width:150,
        height:150,
        sliceVisibilityThreshold: 0,
        pieSliceTextSlice:{color:'#000000'},
        chartArea: {
            height: '90%',
            width: '90%'
        },
        // tooltip: { trigger: 'none' },
    };

    const image_severity_options= {
        legend: 'none',
        slices: {0: {color: '#ed1b24'}, 1:{color: '#fe7e01'}, 2:{color: '#ffff01'}, 3: {color: '#00ff01'}, 4:{color: '#9e9e9e'}},
        backgroundColor: '#17181b',
        width:160,
        height:160,
        sliceVisibilityThreshold: 0,
        pieSliceTextSlice:{color:'#000000'},
        chartArea: {
            height: '90%',
            width: '90%'
        },
        // tooltip: { trigger: 'none' },
    };

    function redirectToLogin() {
        props.history.replace("/");
    }

    useEffect(() => {
        if (props.userInfo && props.userInfo.max_storage) {
            setMaxSize(props.userInfo.max_storage);
        }
        let annotationTypeArrays = [];
		let annotationStatusArrays = [];
        if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            annotationTypeArrays = mapUtils.generateAnnotationTypeList();
        }
        else {
			annotationTypeArrays = JSON.parse(props.userInfo.annotation_type_list);
            
        }
		setAnnotationTypeList(annotationTypeArrays);
		setFilterTypeArrays(annotationTypeArrays.map((annotationType) => annotationType.value));

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            annotationStatusArrays = mapUtils.generateAnnotationStatusList();
        }
        else {
			annotationStatusArrays = JSON.parse(props.userInfo.annotation_status_list);
            
        }
		
		setAnnotationStatusList(annotationStatusArrays);
		setFilterStatusArrays(annotationStatusArrays.map((annotationStatus) => annotationStatus.value));
	}, [props.userInfo]);

    useEffect(() => {
        if (props.analyticsInfo && props.allProjects) {
            updateAnalyticsInfo();
        }
	}, [props.allProjects, props.analyticsInfo]);

    useEffect(() => {
        if (props.analyticsInfo && props.allProjects) {
            updatePieChartInfo();
        }
	}, [projectID, filterArrays]);

    function selectProjectList(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				selectProjectID(newValue.value);
			}
		}
	}

    function updateAnalyticsInfo() {
        let optionValues = [
            {
                value : -1,
                label: "All Projects",
            },
        ];

        for (let i = 0; i < props.allProjects.length; i ++) {
            optionValues.push({
              label: props.allProjects[i].name,
              value: props.allProjects[i].id
            })
        }

        optionValues.sort(function(first, end) {
            return first.label.toLowerCase() < end.label.toLowerCase() ? 1 : -1
        })

        updatePieChartInfo();
        setProjectList(optionValues);
        setProgress(false);
    }

    function updatePieChartInfo() {
        let data = [
            ["Task", "Annotation Severity"],
            ["Red", 0],
            ["Orange", 0],
            ["Yellow", 0],
            ["Green", 0],
        ];

        data[1][1] = getAnnotationCountByType("severity_level_1");
        data[2][1] = getAnnotationCountByType("severity_level_2");
        data[3][1] = getAnnotationCountByType("severity_level_3");
        data[4][1] = getAnnotationCountByType("severity_level_4");

        setAnnotationSeverity(data);

        let imageData = [
            ["Task", "Image Severity"],
            ["Red", 0],
            ["Orange", 0],
            ["Yellow", 0],
            ["Green", 0],
            ["Grey", 0],
        ];

        imageData[1][1] = getImageCountByType("inspect_5");
        imageData[2][1] = getImageCountByType("inspect_4");
        imageData[3][1] = getImageCountByType("inspect_3");
        imageData[4][1] = getImageCountByType("inspect_2");
        imageData[5][1] = getImageCountByType("inspect_0");

        setImageSeverity(imageData);
    }

    async function refreshAnalytics() {
        if (!props.isLatestAnalytics) return;
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            props.setLatestAnalytics(false);
            let response = await api.getAnalytics({token: userToken});

            if (response.data && !response.data.error) {
                props.setLatestAnalytics(true);
                props.setAnalyticsInfo(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

    function getTotalStorage() {
        if (!props.analyticsInfo) return 0;
        let calcSize = 0;
        let size_dataset = props.analyticsInfo["size_dataset"];
        for (let i = 0; i < size_dataset.length; i ++) {
            calcSize += parseFloat(size_dataset[i]["dataset_size"]);
            calcSize += parseFloat(size_dataset[i]["process_size"]);
        }

        return Math.min(calcSize, maxSize);
    }

    function getTotalFiles(type) {
        if (!props.analyticsInfo) return 0;
        if (!Array.isArray(props.analyticsInfo["project"])) return 0;
        let size_dataset = props.analyticsInfo["size_dataset"].filter(function(value) {
            if (type === "total") return value.datatype !== "Report" && value.datatype !== "dem";
            else if (projectID === -1) return value.datatype === type;
            
            let dataset_list = [];
            for (let i = 0; i < props.analyticsInfo["project"].length; i ++) {
                if (!props.analyticsInfo["project"][i].id) continue;
                if (props.analyticsInfo["project"][i].id + "" === projectID + "") {
                    if (props.analyticsInfo["project"][i].dataset_ids) {
                        dataset_list = JSON.parse(props.analyticsInfo["project"][i].dataset_ids);
                    } 
                    
                    break;
                } 
            }
            return dataset_list.indexOf(value.id + "") >= 0 && value.datatype === type;
        });
        return size_dataset.length;
        // let totalCount = 0;
        // for (let i = 0; i < size_dataset.length; i ++) {
        //     totalCount += size_dataset[i].files;
        // }
        
        // return totalCount;
    }

    function getStorageSizeLabel(value) {
        if (value < 1024 * 1024 * 1024) {
            return (value / 1024 / 1024).toFixed(1) + "MB";
        }
        else if (value < 1024 * 1024 * 1024 * 1024) {
            return (value / 1024 / 1024 / 1024).toFixed(1) + "GB";
        }
        else {
            return (value / 1024 / 1024 / 1024 / 1024).toFixed(1) + "TB";
        }
    }

    function getAnnotationCountByType(type) {
        if (!props.analyticsInfo || !props.analyticsInfo["annotations"]) return 0;
        if (!Array.isArray(props.analyticsInfo["project"])) return 0;
        if (type !== "total") {
            let result = 0;
            if (projectID === -1) {
                for (let i = 0; i < props.analyticsInfo["project"].length; i ++) {
                    if (props.analyticsInfo["annotations"][props.analyticsInfo["project"][i].id + ""] && props.analyticsInfo["annotations"][props.analyticsInfo["project"][i].id + ""][type]) {
                        result += props.analyticsInfo["annotations"][props.analyticsInfo["project"][i].id + ""][type];
                    }
                    
                }
            }
            else {
                if (props.analyticsInfo["annotations"][projectID + ""] && props.analyticsInfo["annotations"][projectID + ""][type]) {
                    result = props.analyticsInfo["annotations"][projectID + ""][type];
                }
            }
            return result;
        }
        
        return getAnnotationCountByType("severity_level_1") + getAnnotationCountByType("severity_level_2") 
        + getAnnotationCountByType("severity_level_3") +getAnnotationCountByType("severity_level_4");
    }

    function getAnnotationPercentByType(type) {
        if (!props.analyticsInfo) return 0;
        let total = getAnnotationCountByType("total");
        if (total === 0) return 0;
        
        return (getAnnotationCountByType(type) / total * 100).toFixed(1);
    }

    function getImageCountByType(type) {
        if (!props.analyticsInfo || !props.analyticsInfo["image_dataset"]) return 0;
        if (!Array.isArray(props.analyticsInfo["project"])) return 0;
        if (type !== "total") {
            let dataset_list = [];
            for (let i = 0; i < props.analyticsInfo["project"].length; i ++) {
                if (!props.analyticsInfo["project"][i].id) continue;
                if (projectID !== -1) {
                    if (props.analyticsInfo["project"][i].id + "" === projectID + "") {
                        if (props.analyticsInfo["project"][i].dataset_ids) {
                            dataset_list = JSON.parse(props.analyticsInfo["project"][i].dataset_ids);
                        } 
                        
                        break;
                    } 
                }
                else {
                    dataset_list = dataset_list.concat(JSON.parse(props.analyticsInfo["project"][i].dataset_ids));
                }
            }
            dataset_list = dataset_list.filter((item, index) => dataset_list.indexOf(item) === index);

            let result = 0;
            for (let i = 0; i < dataset_list.length; i ++) {
                if (props.analyticsInfo["image_dataset"][dataset_list[i]] && props.analyticsInfo["image_dataset"][dataset_list[i]][type]) {
                    result += props.analyticsInfo["image_dataset"][dataset_list[i]][type]
                }
            }
            return result;
        }

        return getImageCountByType("inspect_0") + getImageCountByType("inspect_2") 
        + getImageCountByType("inspect_3") + getImageCountByType("inspect_4") + getImageCountByType("inspect_5");
    }

    function getImagePercentByType(type) {
        if (!props.analyticsInfo) return 0;
        let total = getImageCountByType("total");
        if (total === 0) return 0;
        
        return (getImageCountByType(type) / total * 100).toFixed(1);
    }

    function getAnnotationStatusList() {
        const items = annotationStatusList.filter((annotationStatus) => filterStatusArrays.indexOf(annotationStatus.value) >= 0).map((annotationStatus, index) => (
            <div key={index} className='analytics_account_info'>
                <div className='analytics_account_info_label'>{"(" + (index + 1) + ") " + annotationStatus.label}</div>
                <div className='analytics_account_info_value'>{getAnnotationCountByType("annotation_status_" + annotationStatus.value)}</div>
            </div>
        ));

        return items;
    }

    function getAnnotationStatusPercentageList() {
        const items = annotationStatusList.filter((annotationStatus) => filterStatusArrays.indexOf(annotationStatus.value) >= 0).map((annotationStatus, index) => (
            <ProgressBar key={index} now={getAnnotationPercentByType("annotation_status_" + annotationStatus.value)} label={getAnnotationPercentByType("annotation_status_" + annotationStatus.value) + "%"} className="analytics_annotation_horiz_progress"/>
        ));

        return items;
    }

    function getAnnotationTypeList() {
        const items = annotationTypeList.filter((annotationType) => filterTypeArrays.indexOf(annotationType.value) >= 0).map((annotationType, index) => (
            <div key={index} className='analytics_account_info'>
                <div className='analytics_account_info_label'>{"(" + (index + 1) + ") " + annotationType.label}</div>
                <div className='analytics_account_info_value'>{getAnnotationCountByType("annotation_type_" + annotationType.value)}</div>
            </div>
        ));

        return items;
    }

    function getAnnotationTypePercentageList() {
        const items = annotationTypeList.filter((annotationType) => filterTypeArrays.indexOf(annotationType.value) >= 0).map((annotationType, index) => (
            <ProgressBar key={index} now={getAnnotationPercentByType("annotation_type_" + annotationType.value)} label={getAnnotationPercentByType("annotation_type_" + annotationType.value) + "%"} className="analytics_annotation_horiz_progress"/>
        ));

        return items;
    }

    function handleFilterMenuClose() {
		setAnchorFilter(null);
	}

    function getAnnotationTypeMenu() {
		let items = annotationTypeList.map((annotationType, index) => (
			<div key={index} onClick={changeFilterType} data-id={annotationType.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterTypeArrays.indexOf(annotationType.value) >= 0}/>
				<div className='filter_image_type'>{"(" + (index + 1) + ") " + annotationType.label}</div>
			</div>
        ));
		
		items.unshift((
			<div key={-1} onClick={checkAllAnnotationType} className='image_filter_annotation_type'>
				<CustomCheckbox checked={checkedAll}/>
				<div className='filter_image_type'>Check/Uncheck All</div>
			</div>
        ));

        return items;
	}

    function getAnnotationStatusMenu() {
		const items= annotationStatusList.map((annotationStatus, index) => (
			<div key={index} onClick={changeFilterStatus} data-id={annotationStatus.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterStatusArrays.indexOf(annotationStatus.value) >= 0}/>
				<div className='filter_image_type'>{"(" + (index + 1) + ") " + annotationStatus.label}</div>
			</div>
        ));

        return items;
	}

    function checkAllAnnotationType() {
		if (checkedAll) {
			setFilterTypeArrays([]);
		}
		else {
			setFilterTypeArrays(annotationTypeList.map((annotationType) => annotationType.value));
		}

		setTypeCheckAll(!checkedAll);
	}

    function changeFilterType(event) {
		let cloneFilterArrays = filterTypeArrays.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterTypeArrays(cloneFilterArrays);
	}

    function changeFilterStatus(event) {
		let cloneFilterArrays = filterStatusArrays.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		
		setFilterStatusArrays(cloneFilterArrays);
	}

    function changeFilterInspect(event) {
		let cloneFilterArrays = filterArrays.filter(function(value) {
			return true;
		});
		let inspect = parseInt(event.currentTarget.dataset.id);
		let index = cloneFilterArrays.indexOf(inspect);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(inspect);
		}
		
		setFilterArrays(cloneFilterArrays);
	}

    function getFilterImageMenu() {
		let renderMenu;
		const menuId = 'primary-action-filtermenu';

		renderMenu = (
			<Menu
				anchorEl={anchorFilter}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={menuId}
				className="sidebar-actionmenu-style"
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				open={isFilterMenuOpen}
				onClose={handleFilterMenuClose}
			>
				<div className='image_filter'>
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedTypeFilter(!isExpandedTypeFilter);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
						}}>
							{!isExpandedTypeFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedTypeFilter(!isExpandedTypeFilter);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
						}}>Type Filter</label>
					</div>
					{isExpandedTypeFilter?
					<div className='filter_menu'>
						{getAnnotationTypeMenu()}
					</div>
					:null}
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedStatusFilter(!isExpandedStatusFilter);
                            setExpandedTypeFilter(false);
                            setExpandedSeverityFilter(false);
						}}>
							{!isExpandedStatusFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedStatusFilter(!isExpandedStatusFilter);
                            setExpandedTypeFilter(false);
                            setExpandedSeverityFilter(false);
						}}>Status Filter</label>
					</div>
					{isExpandedStatusFilter?
					<div className='filter_menu'>
						{getAnnotationStatusMenu()}
					</div>
					:null}
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedSeverityFilter(!isExpandedSeverityFilter);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
						}}>
							{!isExpandedSeverityFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedSeverityFilter(!isExpandedSeverityFilter);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
						}}>Severity Filter</label>
					</div>
					{isExpandedSeverityFilter?
					<div className='filter_menu'>
						<CustomCheckbox checked={filterArrays.indexOf(5) >= 0} className="filter_inspect_5_checkbox" onClick={changeFilterInspect} data-id={5}/>
						<CustomCheckbox checked={filterArrays.indexOf(4) >= 0} className="filter_inspect_4_checkbox" onClick={changeFilterInspect} data-id={4}/>
						<CustomCheckbox checked={filterArrays.indexOf(3) >= 0} className="filter_inspect_3_checkbox" onClick={changeFilterInspect} data-id={3}/>
						<CustomCheckbox checked={filterArrays.indexOf(2) >= 0} className="filter_inspect_2_checkbox" onClick={changeFilterInspect} data-id={2}/>
						<CustomCheckbox checked={filterArrays.indexOf(0) >= 0} className="filter_inspect_0_checkbox" onClick={changeFilterInspect} data-id={0}/>
					</div>
					:null}
				</div>
			</Menu>
		);

		return renderMenu;
	}

    function handleFilterMenu(event) {
        if (event.currentTarget)
		    setAnchorFilter(event.currentTarget);
	}

    function getSeverityLabel(value) {
        let label = "";
        if (value === 0) {
            label = "Grey (not inspected)";
        }
        else if (value === 2) {
            label = "Green (notes)";
        }
        else if (value === 3) {
            label = "Yellow (mild)";
        }
        else if (value === 4) {
            label = "Orange (severe)";
        }
        else if (value === 5) {
            label = "Red (very severe)";
        }

        return label;
    }

    function getSeverityValue(value) {
        let label = "severity_level_1";
        if (value === 0) {
            label = "";
        }
        else if (value === 2) {
            label = "severity_level_4";
        }
        else if (value === 3) {
            label = "severity_level_3";
        }
        else if (value === 4) {
            label = "severity_level_2";
        }
        else if (value === 5) {
            label = "severity_level_1";
        }

        return label;
    }

    function downloadCSV() {
        const rows = [
        ];

        rows.push(["ACCOUNT STATISTICS", "PoleHawk Account", props.userInfo.email]);
        rows.push(["ACCOUNT STATISTICS", "Storage Used", getStorageSizeLabel(getTotalStorage()) + " of " + getStorageSizeLabel(maxSize)]);
        rows.push(["ACCOUNT STATISTICS", "Projects Total", (props.analyticsInfo?props.analyticsInfo["project"].length:0)]);
        rows.push(["ACCOUNT STATISTICS", "Datasets Total", getTotalFiles("total")]);
        rows.push(["ACCOUNT STATISTICS", "Datasets Reports", getTotalFiles("Report")]);
        rows.push(["ACCOUNT STATISTICS", "Datasets DEM", getTotalFiles("dem")]);
        rows.push(["ACCOUNT STATISTICS", "Datasets Images", getTotalFiles("img")]);
        rows.push(["ACCOUNT STATISTICS", "Datasets KMZ/KML", getTotalFiles("kml")]);
        rows.push(["ACCOUNT STATISTICS", "Datasets LiDAR", getTotalFiles("pointcloud")]);
        rows.push(["ACCOUNT STATISTICS", "Datasets Ortho", getTotalFiles("tiff")]);
        
        if (projectID === -1) {
            rows.push(["ACCOUNT STATISTICS", "Projects List", "All Projects"]);
        }
        else {
            let filteredProject = projectList.filter(function (option) {
                return option.value === projectID;
            });
            rows.push(["ACCOUNT STATISTICS", "Projects List", filteredProject[0].name]);
        }

        rows.push(["ACCOUNT STATISTICS", "Annotations Total", getAnnotationCountByType("total")]);
        rows.push(["", "", ""]);

        let items = annotationStatusList.filter((annotationStatus) => filterStatusArrays.indexOf(annotationStatus.value) >= 0);

        for (let i = 0; i < items.length; i ++) {
            rows.push(["ANNOTATION STATUS", "(" + (i + 1) + ") " + items[i].label, getAnnotationCountByType("annotation_status_" + items[i].value)]);
        }

        rows.push(["", "", ""]);

        let filterSeverityValues = filterArrays.sort(function(a, b) {
            return b - a;
        });
        for (let i = 0; i < filterSeverityValues.length; i ++) {
            if (filterSeverityValues[i] === 0) continue;
            rows.push(["ANNOTATION SEVERITY", "(" + (i + 1) + ") " + getSeverityLabel(filterSeverityValues[i]), getAnnotationCountByType(getSeverityValue(filterSeverityValues[i]))]);
        }

        rows.push(["", "", ""]);

        items = annotationTypeList.filter((annotationType) => filterTypeArrays.indexOf(annotationType.value) >= 0);

        for (let i = 0; i < items.length; i ++) {
            rows.push(["ANNOTATION TYPE", "(" + (i + 1) + ") " + items[i].label, getAnnotationCountByType("annotation_type_" + items[i].value)]);
        }

        rows.push(["", "", ""]);

        for (let i = 0; i < filterSeverityValues.length; i ++) {
            rows.push(["IMAGE STATISTICS", "(" + (i + 1) + ") " + getSeverityLabel(filterSeverityValues[i]), getImageCountByType("inspect_" + filterSeverityValues[i])]);
        }

        rows.push(["", "", ""]);

        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");

        var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        var url = URL.createObjectURL(blob);
        
        // Create a link to download it
        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', "Analytics_" + props.userInfo.first_name + ".csv");
        pom.click();
    }

	return (
		<div className={classes.root}>
            <div className='dashboard_title_part'>
                <div className={classes.panel_label}>Dashboard</div>
                <div className='select_project_list'>
                    <Select
                        isMulti={false}
                        onChange={selectProjectList}
                        styles={selectStyle}
                        options={projectList}
                        isSearchable={false}
                        value = {
                            projectList.filter(function (option) {
                                return option.value === projectID;
                            })
                        }
                    ></Select>
                </div>
                <div className="can_hover_icon dataset_refresh no_boder_line analytics_refresh" ><div className='icon refresh_icon' onClick={refreshAnalytics}></div></div>
                <div className="can_hover_icon dataset_refresh no_boder_line analytics_download" ><div className='icon download_img_icon' onClick={downloadCSV}></div></div>
                <div className="can_hover_icon dataset_refresh no_boder_line analytics_filter" ><div className='icon filter_icon' onClick={handleFilterMenu}></div></div>
            </div>

            <div className={classes.info_section}>
                {progress? 
                    <CircularProgress className={classes.circular_progress}/>
                :
                    <div>
                        {!props.isLatestAnalytics?
                        <div className="latest_part">Last Analytics (Updating)</div>
                        :null}
                        <div className="project_dataset_item top_border account_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setAccountExtended(!accountExtended);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(false);
                                }}>
                                    {!accountExtended?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={() => {
                                    setAccountExtended(!accountExtended);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(false);
                                }}>ACCOUNT STATISTICS</div>
                            </div>
                            {accountExtended?
                                <div>
                                    <ProgressBar now={Math.floor(getTotalStorage() / maxSize * 100)} label={Math.floor(getTotalStorage() / maxSize * 100) + "%"} className="analytics_progress"/>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Storage</div>
                                        <div className='analytics_account_info_value'>{getStorageSizeLabel(getTotalStorage())} of {getStorageSizeLabel(maxSize)}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Projects</div>
                                        <div className='analytics_account_info_value'>{(props.analyticsInfo?props.analyticsInfo["project"].length:0)}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Datasets</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("total")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Reports</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("Report")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Lidar DEM</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("dem")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Images</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("img")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>KMZ/KML</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("kml")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Lidar</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("pointcloud")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Ortho</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("tiff")}</div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        <div className="project_dataset_item annotation_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(!annotationSeverityExtend);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(false);
                                }}>
                                    {!annotationSeverityExtend?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(!annotationSeverityExtend);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(false);
                                }}>ANNOTATION SEVERITY STATISTICS</div>
                            </div>
                            {annotationSeverityExtend?
                                <div>
                                    <div className='annotation_severity_part'>
                                        <div className='annotation_type_info_part'>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label blue_color_text'>Severity</div>
                                                <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                            </div>
                                            {filterArrays.indexOf(5) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label red_color'>Red (very severe)</div>
                                                <div className='analytics_account_info_value red_color'>{getAnnotationCountByType("severity_level_1")}</div>
                                            </div>
                                            :null}

                                            {filterArrays.indexOf(4) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label orange_color'>Orange (severe)</div>
                                                <div className='analytics_account_info_value orange_color'>{getAnnotationCountByType("severity_level_2")}</div>
                                            </div>
                                            :null}

                                            {filterArrays.indexOf(3) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label yellow_color'>Yellow (mild)</div>
                                                <div className='analytics_account_info_value yellow_color'>{getAnnotationCountByType("severity_level_3")}</div>
                                            </div>
                                            :null}

                                            {filterArrays.indexOf(2) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label green_color'>Green (notes)</div>
                                                <div className='analytics_account_info_value green_color'>{getAnnotationCountByType("severity_level_4")}</div>
                                            </div>
                                            :null}
                                            <div className='analytics_separate_line'>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>TOTAL</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("total")}</div>
                                            </div>
                                        </div>
                                        <div className='annotation_severity_percent_part'>
                                            <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                            <div className='analytics_account_percent_layout'>
                                                <div className='annotation_severity_percent_real_part'>
                                                    {getAnnotationCountByType("total") !== 0?
                                                        <Chart
                                                            chartType="PieChart"
                                                            data={annotation_severity}
                                                            options={annotation_severity_options}
                                                            width={"150px"}
                                                            height={"150px"}
                                                        />
                                                    :null}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        <div className="project_dataset_item annotation_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(!annotationStatusExtend);
                                    setAnnotationTypeExtend(false);
                                }}>
                                    {!annotationStatusExtend?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(!annotationStatusExtend);
                                    setAnnotationTypeExtend(false);
                                }}>ANNOTATION STATUS STATISTICS</div>
                            </div>
                            {annotationStatusExtend?
                                <div className='annotation_type_part'>
                                    <div className='annotation_type_info_part'>
                                        <div className='analytics_account_info'>
                                            <div className='analytics_account_info_label blue_color_text'>TYPE</div>
                                            <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                        </div>
                                        {getAnnotationStatusList()}
                                        <div className='analytics_separate_line'>
                                        </div>
                                        <div className='analytics_account_info'>
                                            <div className='analytics_account_info_label'>TOTAL</div>
                                            <div className='analytics_account_info_value'>{getAnnotationCountByType("total")}</div>
                                        </div>
                                    </div>
                                    <div className='annotation_type_percent_part'>
                                        <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                        <div className='analytics_account_percent_layout'>
                                            <div className='analytics_separate_line_vertical_line'></div>
                                            <div className='annotation_type_percent_real_part'>
                                                {getAnnotationStatusPercentageList()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        <div className="project_dataset_item annotation_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(!annotationTypeExtend);
                                }}>
                                    {!annotationTypeExtend?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(false);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(!annotationTypeExtend);
                                }}>ANNOTATION TYPE STATISTICS</div>
                            </div>
                            {annotationTypeExtend?
                                <div className='annotation_type_part'>
                                    <div className='annotation_type_info_part'>
                                        <div className='analytics_account_info'>
                                            <div className='analytics_account_info_label blue_color_text'>TYPE</div>
                                            <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                        </div>
                                        {getAnnotationTypeList()}
                                        <div className='analytics_separate_line'>
                                        </div>
                                        <div className='analytics_account_info'>
                                            <div className='analytics_account_info_label'>TOTAL</div>
                                            <div className='analytics_account_info_value'>{getAnnotationCountByType("total")}</div>
                                        </div>
                                    </div>
                                    <div className='annotation_type_percent_part'>
                                        <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                        <div className='analytics_account_percent_layout'>
                                            <div className='analytics_type_separate_line_vertical_line'></div>
                                            <div className='annotation_type_percent_real_part'>
                                                {getAnnotationTypePercentageList()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        <div className="project_dataset_item image_info_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(!imageExtended);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(false);
                                }}>
                                    {!imageExtended?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={() => {
                                    setAccountExtended(false);
                                    setImageExtended(!imageExtended);
                                    setAnnotationSeverityExtend(false);
                                    setAnnotationStatusExtend(false);
                                    setAnnotationTypeExtend(false);
                                }}>IMAGE STATISTICS</div>
                            </div>
                            {imageExtended?
                                <div>
                                    <div className='image_severity_part'>
                                        <div className='image_severity_info_part'>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label blue_color_text'>Severity</div>
                                                <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                            </div>
                                            {filterArrays.indexOf(5) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label red_color'>Red (very severe)</div>
                                                <div className='analytics_account_info_value red_color'>{getImageCountByType("inspect_5")}</div>
                                            </div>
                                            :null}
                                            {filterArrays.indexOf(4) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label orange_color'>Orange (severe)</div>
                                                <div className='analytics_account_info_value orange_color'>{getImageCountByType("inspect_4")}</div>
                                            </div>
                                            :null}
                                            {filterArrays.indexOf(3) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label yellow_color'>Yellow (mild)</div>
                                                <div className='analytics_account_info_value yellow_color'>{getImageCountByType("inspect_3")}</div>
                                            </div>
                                            :null}
                                            {filterArrays.indexOf(2) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label green_color'>Green (notes)</div>
                                                <div className='analytics_account_info_value green_color'>{getImageCountByType("inspect_2")}</div>
                                            </div>
                                            :null}
                                            {filterArrays.indexOf(0) >= 0 ?
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label grey_color'>Grey (not inspected)</div>
                                                <div className='analytics_account_info_value grey_color'>{getImageCountByType("inspect_0")}</div>
                                            </div>
                                            :null}
                                            <div className='analytics_separate_line'>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>TOTAL</div>
                                                <div className='analytics_account_info_value'>{getImageCountByType("total")}</div>
                                            </div>
                                        </div>
                                        <div className='image_severity_percent_part'>
                                            <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                            <div className='analytics_account_percent_layout'>
                                                <div className='annotation_severity_percent_real_part'>
                                                    {getImageCountByType("total") !== 0?
                                                        <Chart
                                                            chartType="PieChart"
                                                            data={image_severity}
                                                            options={image_severity_options}
                                                            width={"160px"}
                                                            height={"160px"}
                                                        />
                                                    :null}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>
                    </div> 
                }
            </div>
            {getFilterImageMenu()}
		</div>
	);
}

const mapStateToProps = state => ({
    userInfo: state.global.userInfo,
    analyticsInfo : state.project.analyticsInfo,
    allProjects : state.project.allProjects,
    isLatestAnalytics : state.project.isLatestAnalytics,
});

DashboardPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(DashboardPanel);
