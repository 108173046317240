import { all, put, call, fork, take } from 'redux-saga/effects';
import * as api from '../../utils/api';
import {
  UPLOAD_FILE_REQUEST,
  globalAction,
} from './actions';

export function* asyncFileUpload({ payload, resolve, reject}) {
  try {
    const response = yield call(api.uploadDatasetByID, payload);
    if (response.data) {
      yield put(globalAction.uploadFileSuccess(response.data));
    }
    resolve(response.data);
  } catch (err) {
    reject(err)
  }
}

export function* watchFileUpload() {
  while (true) {
    const action = yield take(UPLOAD_FILE_REQUEST);
    yield* asyncFileUpload(action);
  }
}

export default function* () {
  yield all([
    fork(watchFileUpload),
  ]);
}
