import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import { Rnd } from "react-rnd";
import ProjectPanel from './ProjectPanel';
import DatasetPanel from './DatasetPanel';
import AssetPanel from './AssetPanel';
import {Modal} from 'react-bootstrap';
import Report from '../../components/Report';
import Button from '@material-ui/core/Button';

import './style.scss';
import SettingsPanel from './SettingsPanel';
import ReportPanel from './ReportPanel';
import { useEffect } from 'react';
import DashboardPanel from './DashboardPanel';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
}));

const resizeStyle = {
	display: "flex",
	background: "rgb(23 24 27)",
	zIndex: "100001",
	cursor: 'pointer',
	border: "1px solid #282b2e"
};

const showStyle = {
	display: "block",
};
const hideStyle = {
	display: "none"
}

function SideBar(props) {
	const classes = useStyles();

	const [showPanel, setShowPanel] = React.useState(api.getParameterByName("show_panel"));

	function hideReportDialog() {
        setShowingReportTool(false);
    }

	const [isShowingReportTool, setShowingReportTool] = React.useState(false);
    const [currentReportType, setCurrentReportType] = React.useState("image");

	const [isDelete, setDeleteDialog] = React.useState(false);
	const [deleteTitle, setDeleteTitle] = React.useState("");
	const [deleteMessage, setDeleteMessage] = React.useState("");
	const [deleteButtonName, setDeleteButtonName] = React.useState("Delete");

	const [isMinimize, setMinimize] = React.useState(false);
	const [menuWidth, setMenuWidth] = React.useState(600);
	const [menuHeight, setMenuHeight] = React.useState('100%');

	useEffect(() => {
		document.addEventListener('keydown', keyDownListener, false);
		if (showPanel !== "") {
			props.setShowMenu(true);
		}
		return () => {
			document.removeEventListener('keydown', keyDownListener);
		}
	}, []);

	useEffect(() => {
		if (!props.isShowMenu && api.getParameterByName("show_panel") === "") {
			updatePanel("");
		}
	}, [props.isShowMenu]);

	function keyDownListener(event) {
		if (event.keyCode === 27) {
			if (window.addingPole) {
				if (window.addingPole) {
					window.addingPole = false;
					props.setAddingPosition(undefined);
		
					if (window.addingPoleItem && window.MAIN_CESIUM_VIEWER) {
						window.MAIN_CESIUM_VIEWER.entities.remove(window.addingPoleItem);
					}
					window.addingPoleItem = undefined;
				}
			}
			else {
				updatePanel("");
			}
		}
	}

	function displayReportGenerator(reportType) {
		updatePanel("");
		setCurrentReportType(reportType);
        setShowingReportTool(true);
	}

	function updatePanel(value) {
		setShowPanel(value);
		api.setParameter("show_panel", value);
		if (value !== "") {
			props.setShowMenu(true);
		}
	}

	function showProjectPanelForce() {
		if (showPanel !== "project") {
			updatePanel("project");
		}
	}

	function showProjectPanel() {
		if (showPanel === "project") {
			updatePanel("");
		}
		else {
			updatePanel("project");
		}
	}

	function showDatasetPanel() {
		if (showPanel === "dataset") {
			updatePanel("");
		}
		else {
			updatePanel("dataset");
		}
	}

	function showReportPanel() {
		if (showPanel === "report") {
			updatePanel("");
		}
		else {
			updatePanel("report");
		}
	}

	function showDashboardPanel() {
		if (showPanel === "dashboard") {
			updatePanel("");
		}
		else {
			updatePanel("dashboard");
		}
	}

	function showSettingsPanel() {
		if (showPanel === "settings") {
			updatePanel("");
		}
		else {
			updatePanel("settings");
		}
	}

	function showAssetPanel() {
		if (showPanel === "assets") {
			updatePanel("");
		}
		else {
			updatePanel("assets");
		}
	}

	function showHelpPanel() {
		window.open("https://www.polehawk.com/support");
	}

	function hideDeleteDialog() {
		setDeleteDialog(false);
		setDeleteMessage("");
		setDeleteTitle("");
		setDeleteButtonName("");
		window.deleteCallback = null;
	}

	function deleteRun() {
		setDeleteDialog(false);
		setDeleteMessage("");
		setDeleteTitle("");
		setDeleteButtonName("");

		if (window.deleteCallback) {
			window.deleteCallback();
		}
	}

	function getDeleteDialog() {
        return (
            <Modal show={isDelete} animation={false} className="modal-delete-dialog">
                <Modal.Header>
					<div className='delete_dialog_title'>
						<Modal.Title>{deleteTitle}</Modal.Title>
						<div className='close_button' onClick={hideDeleteDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='delete_dialog_message'>
						{deleteMessage}
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained"
						color="secondary" onClick={hideDeleteDialog} className={classes.button}>
						Close
					</Button>
					<Button variant="contained"
						color="primary" onClick={deleteRun} className={classes.button}>
						{deleteButtonName}
					</Button>
                </Modal.Footer>
            </Modal>
        );
    }

	return (
		<div className={classes.root}>
			<CssBaseline />
			<div id='side_menu'>
				<div className="project_menu menu_icon" onClick={showProjectPanel}>
					<div className={`icon ${(showPanel === "project")?'highlight_icon':null}`}></div>
				</div>
				{api.canEditItem(props.userInfo)?
				<div className="dataset_menu menu_icon" onClick={showDatasetPanel}>
					<div className={`icon ${(showPanel === "dataset")?'highlight_icon':null}`}></div>
				</div>
				:null}
				{!api.isSharing()?
				<div className="asset_menu menu_icon" onClick={showAssetPanel}>
					<div className={`icon ${(showPanel === "assets")?'highlight_icon':null}`}></div>
				</div>
				:null}
				{api.canEditItem(props.userInfo)?
				<div className="dashboard_menu menu_icon" onClick={showDashboardPanel}>
					<div className={`icon ${(showPanel === "dashboard")?'highlight_icon':null}`}></div>
				</div>
				:null}
				{api.canEditItem(props.userInfo)?
				<div className="report_menu menu_icon" onClick={showReportPanel}>
					<div className={`icon ${(showPanel === "report")?'highlight_icon':null}`}></div>
				</div>
				:null}
				{!api.isSharing()?
				<div className="setting_menu menu_icon" onClick={showSettingsPanel}>
					<div className={`icon ${(showPanel === "settings")?'highlight_icon':null}`}></div>
				</div>
				:null}
				<div className="help_menu menu_icon" onClick={showHelpPanel}>
					<div className={`icon ${(showPanel === "help")?'highlight_icon':null}`}></div>
				</div>
			</div>
			
			<div className="panel_menu" style={showPanel === "project"?showStyle:hideStyle}>
				<Rnd
					style={resizeStyle}
					size={{ width: (isMinimize)?170:menuWidth,  height: menuHeight }}
					default={{
						x: 0,
						y: 0,
						height: "100%",
					}}
					bounds="parent"
					minWidth={(isMinimize)?170:600}
					minHeight={300}
					cancel=".MuiPopover-root, .modal"
					onResize={(e, direction, ref, delta, position) => {
						setMenuWidth(Math.max(600, ref.offsetWidth));
						setMenuHeight(ref.offsetHeight);
					}}
					enableResizing={!isMinimize}
				>
					<ProjectPanel
						isMinimize={isMinimize}
						onDeleteDialog={(title, message, callback, buttonName) => {
							setDeleteTitle(title);
							setDeleteMessage(message);
							window.deleteCallback = callback;
							if (buttonName) {
								setDeleteButtonName(buttonName);
							}
							else {
								setDeleteButtonName("Delete");
							}
							setDeleteDialog(true);
						}}
					></ProjectPanel>
					<div className="minimize_project can_hover_icon" onClick={(event) => {
						setMinimize(!isMinimize);
					}}>
						{isMinimize?
						<div className='icon right_arrow_expand'></div>
						:
						<div className='icon left_arrow_expand'></div>
						}
					</div>
				</Rnd>
			</div>
			{api.canEditItem(props.userInfo)?
			<div className="panel_menu" style={showPanel === "dataset"?showStyle:hideStyle}>
				<Rnd
					style={resizeStyle}
					default={{
						x: 0,
						y: 0,
						width: 850,
						height: "100%",
					}}
					bounds="parent"
					minWidth={550}
					minHeight={300}
					cancel=".modal"
				>
					<DatasetPanel
						onDeleteDialog={(title, message, callback, buttonName) => {
							setDeleteTitle(title);
							setDeleteMessage(message);
							if (buttonName) {
								setDeleteButtonName(buttonName);
							}
							else {
								setDeleteButtonName("Delete");
							}
							window.deleteCallback = callback;
							setDeleteDialog(true);
						}}
					></DatasetPanel>
				</Rnd>
			</div>
			:null}
			{!api.isSharing()?
			<div className="panel_menu" style={showPanel === "settings"?showStyle:hideStyle}>
				<Rnd
					style={resizeStyle}
					default={{
						x: 0,
						y: 0,
						width: 600,
						height: "100%",
					}}
					bounds="parent"
					minWidth={600}
					minHeight={400}
					cancel=".MuiSlider-root, .modal"
				>
					<SettingsPanel></SettingsPanel>
				</Rnd>
			</div>
			:null}
			{api.canEditItem(props.userInfo)?
			<div className="panel_menu" style={showPanel === "report"?showStyle:hideStyle}>
				<Rnd
					style={resizeStyle}
					default={{
						x: 0,
						y: 0,
						width: 400,
						height: "100%",
					}}
					bounds="parent"
					minWidth={400}
					minHeight={400}
					cancel=".MuiSlider-root, .modal"
				>
					<ReportPanel
						onShowReportGenerator={displayReportGenerator}
					></ReportPanel>
				</Rnd>
			</div>
			:null}
			{api.canEditItem(props.userInfo)?
			<div className="panel_menu" style={showPanel === "dashboard"?showStyle:hideStyle}>
				<Rnd
					style={resizeStyle}
					default={{
						x: 0,
						y: 0,
						width: 600,
						height: "100%",
					}}
					bounds="parent"
					minWidth={600}
					minHeight={400}
					cancel=".MuiSlider-root, .modal"
				>
					<DashboardPanel
					></DashboardPanel>
				</Rnd>
			</div>
			:null}
			{!api.isSharing()?
			<div className="panel_menu" style={showPanel === "assets"?showStyle:hideStyle}>
				<Rnd
					style={resizeStyle}
					default={{
						x: 0,
						y: 0,
						width: 500,
						height: "100%",
					}}
					bounds="parent"
					minWidth={400}
					minHeight={400}
					cancel=".MuiSlider-root, .modal"
				>
					<AssetPanel
						onDeleteDialog={(title, message, callback, buttonName) => {
							setDeleteTitle(title);
							setDeleteMessage(message);
							if (buttonName) {
								setDeleteButtonName(buttonName);
							}
							else {
								setDeleteButtonName("Delete");
							}
							window.deleteCallback = callback;
							setDeleteDialog(true);
						}}
					></AssetPanel>
				</Rnd>
			</div>
			:null}
			<Modal show={isShowingReportTool} animation={false} className="modal-report-generator">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideReportDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Report
                        type={currentReportType} 
						onClose={hideReportDialog}
						showProjectPanel={showProjectPanelForce}
						onDeleteDialog={(title, message, callback, buttonName) => {
							setDeleteTitle(title);
							setDeleteMessage(message);
							window.deleteCallback = callback;
							if (buttonName) {
								setDeleteButtonName(buttonName);
							}
							else {
								setDeleteButtonName("Delete");
							}
							setDeleteDialog(true);
						}}
					/>
                </Modal.Body>
            </Modal>
			{getDeleteDialog()}
		</div>
	);
}

const mapStateToProps = state => ({
	allDatasets : state.project.allDatasets,
	allProjects : state.project.allProjects,
	selectedProject : state.project.selectedProject,
	isShowMenu : state.global.isShowMenu,
	userInfo: state.global.userInfo
});

SideBar.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(SideBar);
