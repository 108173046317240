import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { authActionCreators } from '../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import './style.scss'
import logo from '../../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
	
}));

function MaintenanceLayout(props) {
  return (
		<div>
			<div id="wrapper" className="login-register login-sidebar">
				<div className="login-box card">
					<div className="card-body">
            <form id="loginform" className="form-horizontal form-material">
              <a className="text-center db"><img src={logo} alt="Home" style={{width: "50%"}}/></a>
              <div className='maintenance-layout'>Sorry... PoleHawk is currently down for maintenance. Please check back later or contact support for assistance...</div>
            </form>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
});

MaintenanceLayout.propTypes = {
}

export default compose(
  withRouter,
  connect(mapStateToProps, authActionCreators),
)(MaintenanceLayout);