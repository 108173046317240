import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import { authActionCreators } from '../../store/actions';
import './style.scss';
import Button from '@material-ui/core/Button';
import * as api from '../../utils/api';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Modal} from 'react-bootstrap';
import Profile from '../Profile';
import Select from 'react-select';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	view: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	inline: {
		display: "inline-block",
	},
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
	show: {
		display: 'block !important',
	},
	hide: {
		display: 'none !important',
	},
	update_button : {
		fontSize: 15,
		fontWeight: 400,
		marginTop: 10,
		float: "right",
		color: 'white !important'
	},
	profile_ui: {
		padding: 10,
		display: 'inline-block'
	}
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      border: "1px solid white"
    })
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box>
			{children}
		  </Box>
		)}
	  </div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
}

function UserManagement(props) {
	const classes = useStyles();

	const [tabValue, setTabValue] = React.useState(0);

	const [userList, setUserList] = React.useState([]);
	const [linkList, setLinkList] = React.useState([]);

	const [isShowingProfile, setShowingProfile] = React.useState(false);
	const [isUpdate, setUpdate] = React.useState(false);
	const [isOther, setOther] = React.useState(false);
	const [profileInfo, setProfileInfo] = React.useState(null);

	const [isUpdatingShare, setUpdatingShare] = React.useState(0);
	const [sharingToken, setSharingToken] = React.useState("");
	const [sharingProjectID, setSharingProjectID] = React.useState("");
	const [sharingID, setSharingID] = React.useState("");
	const [sharingPassword, setSharingPassword] = React.useState("");
	const [sharingConfirmPassword, setSharingConfirmPassword] = React.useState("");
    const [sharingExpire, setSharingExpire] = React.useState(new Date());
	const [projectList, setProjectList] = React.useState([]);

	const [showPassword, setShowPassword] = React.useState(false);
	const [passwordMatched, setPasswordMatched] = React.useState(true);

	const [matchPasswordString, setMatchPasswordString] = React.useState(false);
	const [matchPasswordCapital, setMatchPasswordCapital] = React.useState(false);
	const [matchPasswordSymbol, setMatchPasswordSymbol] = React.useState(false);
	const [matchPasswordSpace, setMatchPasswordSpace] = React.useState(false);

	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phonenumber, setPhonenumber] = React.useState("");
	const [company, setCompany] = React.useState("");
	const [country, setCountry] = React.useState("");

	const [showWarning, setShowWarning] = React.useState(false);


	function containsUppercase(str) {
		return /[A-Z]/.test(str);
	}

	function containsSpecialChars(str) {
		return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
	}

	function checkPasswordCondition(passwordString) {
		if (passwordString === 0) {
			setMatchPasswordString(false);
			setMatchPasswordCapital(false);
			setMatchPasswordSymbol(false);
			setMatchPasswordSpace(false);
			return;
		}
		if (passwordString.length >= 8 && passwordString.length <= 20) {
			setMatchPasswordString(true);
		}
		else {
			setMatchPasswordString(false);
		}

		if (containsUppercase(passwordString)) {
			setMatchPasswordCapital(true);
		}
		else {
			setMatchPasswordCapital(false);
		}
		
		if (containsSpecialChars(passwordString)) {
			setMatchPasswordSymbol(true);
		}
		else {
			setMatchPasswordSymbol(false);
		}

		if (passwordString.indexOf(" ") < 0) {
			setMatchPasswordSpace(true);
		}
		else {
			setMatchPasswordSpace(false);
		}
	}

	function onFirstNameChange(evt) { setFirstName(evt.target.value); }

	function onLastNameChange(evt) { setLastName(evt.target.value); }

	function onEmailChange(evt) { 
		setEmail(email);
		setShowWarning(true);
	}

	function onPhonenumberChange(evt) { setPhonenumber(evt.target.value); }

	function onCompanyChange(evt) { setCompany(evt.target.value); }

	function onCountryChange(evt) { setCountry(evt.target.value); }

	function hideWarningDialog() {
		setShowWarning(false);
	}

	useEffect(() => {
		if (props.userInfo && props.userInfo.first_name !== "") {
			updateUserList();

			setEmail(props.userInfo.email);
			setFirstName(props.userInfo.first_name);
			setLastName(props.userInfo.last_name);
			setPhonenumber(props.userInfo.phone_number);
			setCompany(props.userInfo.company);
			setCountry(props.userInfo.country);
		}
		if (props.allProjects.length !== 0) {
			updateLinkList();
			setProjectListOptions(props.allProjects)
		}
	}, [props.userInfo, props.allProjects]);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	function redirectToLogin() {
        props.history.replace("/");
    }

	function getCreatedDate(created_at) {
		return created_at.substring(0, 10);
	}

	function setProjectListOptions(projects) {
        let optionValues = [];
        for (let i = 0; i < projects.length; i ++) {
          optionValues.push({
            label: projects[i].name,
            value: projects[i]
          })
        }
        optionValues.sort(function(first, end) {
            return first.label.toLowerCase() < end.label.toLowerCase() ? 1 : -1
        })
        setProjectList(optionValues);
    }

	async function updateUserList() {
		if (userList.length === 0) {
			setUserList([props.userInfo]);
		}

		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let response = await api.getUsers({token: userToken});
			if (response.data && !response.data.error) {
				setUserList(response.data);
			}
			else if (response.data && response.data.error) {
				toast(response.data.error);
				redirectToLogin();
			}
		}
		else {
			redirectToLogin();
		}
	}

	async function updateLinkList() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let response = await api.getLinkList({token: userToken});
			if (response.data && !response.data.error) {
				let links = [];

				for (let i = 0; i < response.data.links.length; i ++) {
					links.push(response.data.links[i]);
					links[links.length - 1].expired = false;
				}
				for (let i = 0; i < response.data.expire_links.length; i ++) {
					links.push(response.data.expire_links[i]);
					links[links.length - 1].expired = true;
				}
				
				setLinkList(links);
			}
			else if (response.data && response.data.error) {
				toast(response.data.error);
				redirectToLogin();
			}
		}
		else {
			redirectToLogin();
		}
	}

	function getRoleName(role) {
		if (role === "viewer") return "Viewer";
		if (role === "owner") return "Owner";
		if (role === "user") return "User";
		if (role === "admin") return "Admin";

		return "";
	}

	function editUser(event) {
		let user = userList.filter(function(value) {
			return value.id + "" === event.target.dataset.id + "";
		});

		if (user.length > 0) {
			setProfileInfo(user[0]);
			setUpdate(true);
			setOther(true);
			setShowingProfile(true);
		}
	}

	function editSelfUser(event) {
		setUpdate(true);
		setOther(false);
		setShowingProfile(true);
	}

	function deleteUser(event) {
		let user = userList.filter(function(value) {
			return value.id + "" === event.target.dataset.id + "";
		});

		if (user.length > 0) {
			props.onDeleteDialog("User", "Do you want to delete " + user[0].first_name + "?", function() {
				deleteUserFromServer(user[0].id);
			}, "Delete");
		}
	}

	async function deleteUserFromServer(user_id) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let response = await api.deleteUser({token: userToken, user_id: user_id});
			if (response.data && !response.data.error) {
				updateUserList();
			}
			else if (response.data && response.data.error) {
				toast(response.data.error);
			}
		}
		else {
			redirectToLogin();
		}
	}

	function deleteLink(event) {
		let link = linkList.filter(function(value) {
			return value.id + "" === event.target.dataset.id + "";
		});

		if (link.length > 0) {
			props.onDeleteDialog("Link", "Do you want to delete sharing of " + getProjectName(link[0]) + "?", function() {
				deleteLinkFromServer(link[0].id);
			}, "Delete");
		}
	}

	async function deleteLinkFromServer(link_id) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let response = await api.deleteLink({token: userToken, id: link_id});
			if (response.data && !response.data.error) {
				updateLinkList();
			}
			else if (response.data && response.data.error) {
				toast(response.data.error);
			}
		}
		else {
			redirectToLogin();
		}
	}

	function extendLink(event) {
		let link = linkList.filter(function(value) {
			return value.id + "" === event.target.dataset.id + "";
		});

		if (link.length > 0) {
			props.onDeleteDialog("Link", "Do you want to extend 30 days sharing of " + getProjectName(link[0]) + "?", function() {
				extendLinkFromServer(link[0].id);
			}, "Extend");
		}
	}
	
	async function extendLinkFromServer(link_id) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let response = await api.extendLink({token: userToken, id: link_id});
			if (response.data && !response.data.error) {
				updateLinkList();
			}
			else if (response.data && response.data.error) {
				toast(response.data.error);
			}
		}
		else {
			redirectToLogin();
		}
	}

	function copyAddressLink(event) {
		let link = linkList.filter(function(value) {
			return value.id + "" === event.target.dataset.id + "";
		});

		if (link.length > 0) {
			let text = getLinkAddress(link[0]);
			if (text === "") {
				toast("No Address");
				return;
			}
			window.navigator.clipboard.writeText(text).then(function() {
				toast("Copy Address Done");
			}, function(error) {
				toast(error);
			});
		}
	}

	function editLink(event) {
		let link = linkList.filter(function(value) {
			return value.id + "" === event.target.dataset.id + "";
		});

		if (link.length > 0) {
			setSharingID(link[0].id);
			setSharingProjectID(link[0].project_id);
			setSharingToken(link[0].token);
			setSharingExpire(new Date(link[0].link_expired));
			setSharingPassword("");
			setMatchPasswordString(false);
			setMatchPasswordCapital(false);
			setMatchPasswordSymbol(false);
			setMatchPasswordSpace(false);
			setUpdatingShare(2);
			setShowPassword(false);
		}
	}

	function createLink() {
		setSharingProjectID(-1);
		setSharingToken("");
		setSharingExpire(new Date(new Date().setDate(new Date().getDate() + 30)));
		setSharingPassword("");
		setMatchPasswordString(false);
        setMatchPasswordCapital(false);
        setMatchPasswordSymbol(false);
        setMatchPasswordSpace(false);
		setUpdatingShare(1);
		setShowPassword(false);
	}

	function createSharing() {
        if (sharingToken === "") {
            toast("Share Address should be not empty");
            return;
        }

		if (sharingPassword !== sharingConfirmPassword) {
			setPasswordMatched(false);
			return;
		}

		if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
			toast("Please check the password strength.");
			return;
		}

        createSharingOnServer(sharingToken, sharingExpire, sharingPassword, sharingProjectID);
    }

	async function createSharingOnServer(token, expire_date, password, project_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createSharingLink({token: userToken, link_token:token, link_expired:expire_date.getTime() / 1000, project_id:project_id, password:password});
            if (response.data && !response.data.error) {
                if (response.data.fail_message) {
                    toast(response.data.fail_message);
                }
                else {
                    toast("Sharing Link Created");
					updateLinkList();
                }
            }
            else if (response.data) {
                toast(response.data.error);
            }
        }
        else {
            redirectToLogin();
        }
    }

	function updateSharing() {
        if (sharingToken === "") {
            toast("Share Address should be not empty");
            return;
        }

		if (sharingPassword !== sharingConfirmPassword) {
			setPasswordMatched(false);
			return;
		}

		if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
			toast("Please check the password strength.");
			return;
		}

        updateSharingOnServer(sharingID, sharingPassword, sharingExpire);
    }

	async function updateSharingOnServer(sharingID, sharingPassword, sharingExpire) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.updateLink({token: userToken, id:sharingID, link_expired:sharingExpire.getTime() / 1000, password:sharingPassword});
            if (response.data && !response.data.error) {
                if (response.data.fail_message) {
                    toast(response.data.fail_message);
                }
                else {
                    toast("Sharing Link Updated");
					updateLinkList();
                }
            }
            else if (response.data) {
                toast(response.data.error);
            }
        }
        else {
            redirectToLogin();
        }
    }

	function generateSharingAddress() {
        if (sharingToken !== "" && sharingProjectID !== "") {
            return api.appPath + "map?show_panel=project&project_id=" + sharingProjectID + "&share_token=" + sharingToken;
        }

        return "";
    }

	function onChangeSharingPassword(evt) {
        setSharingPassword(evt.target.value);
		checkPasswordCondition(evt.target.value);
		setPasswordMatched(true);
    }

	function onChangeSharingConfirmPassword(evt) {
        setSharingConfirmPassword(evt.target.value);
		setPasswordMatched(true);
    }

    function hideSharingDialog() {
		setSharingPassword("");
        setSharingToken("");
		setSharingProjectID(-1);
        setUpdatingShare(0);
    }

	function getUserManageAction(user_id) {
		return (
			<div className='user_manage_action'>
				<div className="table_icon" onClick={editUser} title="Edit">
					<div className='edit_icon icon hover_icon' data-id={user_id}></div>
				</div>
				<div className="table_icon" onClick={deleteUser} title="delete">
					<div className='delete_icon icon hover_icon' data-id={user_id}></div>
				</div>
			</div>
		);
	}

	function getSelfManageAction() {
		return (
			<div className='user_manage_action'>
				<div className="table_icon" onClick={editSelfUser} title="Edit">
					<div className='edit_icon icon hover_icon'></div>
				</div>
			</div>
		);
	}

	function getLinkManageAction(link_id) {
		return (
			<div className='user_link_action'>
				<div className="table_icon" onClick={extendLink} title="Reset Expiration">
					<div className='reset_icon icon hover_icon' data-id={link_id}></div>
				</div>
				<div className="table_icon" onClick={editLink} title="Edit">
					<div className='edit_icon icon hover_icon' data-id={link_id}></div>
				</div>
				<div className="table_icon" onClick={deleteLink} title="delete">
					<div className='delete_icon icon hover_icon' data-id={link_id}></div>
				</div>
				<div className="table_icon" onClick={copyAddressLink} title="Copy Clipboard">
					<div className='clipboard_icon icon hover_icon' data-id={link_id}></div>
				</div>
			</div>
		);
	}

	function createUser() {
		setProfileInfo(null);
		setUpdate(false);
		setOther(true);
		setShowingProfile(true);
	}

	function getLinkAddress(link) {
		return api.appPath + "map?show_panel=project&project_id=" + link.project_id + "&share_token=" + link.token;
	}

	function getProjectName(link) {
		let project = props.allProjects.filter(function(value) {
			return value.id + "" === link.project_id + "";
		});

		if (project.length > 0) {
			return project[0].name;
		}

		return "";
	}

	function getProfileUI() {
		return (
			<div className={classes.profile_ui}>
				<form autoComplete="off">
					<p className="label">First Name</p>
					<input type="text" className={`input-class ${firstName === "" ? 'red_text_border' : ''}`} value={firstName} required onChange={onFirstNameChange} autoComplete="off"/>

					<p className="label">Last Name</p>
					<input type="text" className={`input-class ${lastName === "" ? 'red_text_border' : ''}`} value={lastName} required onChange={onLastNameChange} autoComplete="off"/>
					
					<p className="label">Email Address</p>
					<input type="email" className={`input-class ${email === "" ? 'red_text_border' : ''}`} value={email} required onChange={onEmailChange} autoComplete="off"/>

					<p className="label">Phone Number</p>
					<input type="phonenumber" className={`input-class ${phonenumber === "" ? 'red_text_border' : ''}`} required value={phonenumber} onChange={onPhonenumberChange} autoComplete="off"/>

					<p className="label">Organization</p>
					<input type="text" className={`input-class ${company === "" ? 'red_text_border' : ''}`} value={company} required onChange={onCompanyChange} autoComplete="off"/>

					<p className="label">Country</p>
					<input type="text" className={`input-class ${country === "" ? 'red_text_border' : ''}`} value={country} required onChange={onCountryChange} autoComplete="off"/>

					<Button
						variant="contained"
						color="secondary"
						onClick={updateProfile}
						className={classes.update_button}
					>
						Update Profile
					</Button>
				</form>
			</div>
		)
	}

	function getManageUserUI() {
		return (
			<div>
				<div className="image_button_icon can_hover_icon no_boder_line" id="user_add" title="Create User" onClick={createUser}><div className='icon add_button_icon'></div></div>
				<TableContainer>
					<Table sx={{ width: "100%" }} aria-label="simple table">
						<TableHead>
						<TableRow>
							<TableCell>Last Name</TableCell>
							<TableCell>First Name</TableCell>
							<TableCell>Organization</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Phone</TableCell>
							<TableCell>Created</TableCell>
							<TableCell>Role</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{userList.map((user) => (
							<TableRow
							key={user.id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell>
								{user.last_name}
							</TableCell>
							<TableCell>{user.first_name}</TableCell>
							<TableCell>{user.company}</TableCell>
							<TableCell>{user.email}</TableCell>
							<TableCell>{user.phone_number}</TableCell>
							<TableCell width="80px"><div style={{width:80}}>{getCreatedDate(user.created_at)}</div></TableCell>
							<TableCell>{getRoleName(user.role)}</TableCell>
							<TableCell width="50px">{user.role === "viewer" || user.role === "user" ? getUserManageAction(user.id):getSelfManageAction()}</TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		)
	}
	
	function getManageLinksUI() {
		return (
			<div>
				<div className="image_button_icon can_hover_icon no_boder_line" id="user_add" title="Create Link" onClick={createLink}><div className='icon add_button_icon'></div></div>
				<TableContainer>
					<Table sx={{ width: "100%" }} aria-label="simple table">
						<TableHead>
						<TableRow>
							<TableCell>Link Address</TableCell>
							<TableCell>Link Project</TableCell>
							<TableCell>Link Created</TableCell>
							<TableCell>Link Expires</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{linkList.map((link) => (
							<TableRow
							key={link.id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell width="250px">
								{link.expired?
								<a href={getLinkAddress(link)} className="project-name-link link_expire" target="_blank">{getLinkAddress(link)}</a>
								:
								<a href={getLinkAddress(link)} className="project-name-link" target="_blank">{getLinkAddress(link)}</a>
								}
								
							</TableCell>
							<TableCell>{getProjectName(link)}</TableCell>
							<TableCell width="80px"><div style={{width:80}}>{link.link_created.substring(0, 10)}</div></TableCell>
							<TableCell width="80px"><div style={{width:80}}>{link.link_expired.substring(0, 10)}</div></TableCell>
							<TableCell width="100px">{getLinkManageAction(link.id)}</TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		)
	}
	
	function getManageRoleUI() {
		return (
			<TableContainer>
				<Table sx={{ width: "80%", marginLeft:"10%", marginTop:"20px" }} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>PERMISSIONS</TableCell>
						<TableCell>LINK</TableCell>
						<TableCell>VIEWER</TableCell>
						<TableCell>USER</TableCell>
						<TableCell>OWNER</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
						<TableRow
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell>
								Access to single projects
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
						</TableRow>
						<TableRow
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell>
								Full access to view all projects
							</TableCell>
							<TableCell align="center">
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
						</TableRow>
						<TableRow
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell>
								Upload, create, edit and delete data
							</TableCell>
							<TableCell align="center">
							</TableCell>
							<TableCell align="center">
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
						</TableRow>
						<TableRow
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
							<TableCell>
								User Management and account control
							</TableCell>
							<TableCell align="center">
							</TableCell>
							<TableCell align="center">
							</TableCell>
							<TableCell align="center">
							</TableCell>
							<TableCell align="center">
								X
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	function getManageBillingUI() {
		return (
			<TableContainer>
				<div className='manage_biling_ui'>
					To manage your account billing and subscription settings, please login to your billing portal from <a href="https://www.PoleHawk.com" target="_blank">https://www.PoleHawk.com</a>
				</div>
			</TableContainer>
		)
	}

	function hideProfileDialog() {
		setShowingProfile(false);
	}

	function onHideProfile() {
		updateUserList();
		setOther(false);
		setUpdate(false);
		hideProfileDialog();
	}

	function selectProject(newValue, actionMeta) {
		setSharingProjectID(newValue.value.id)
		getSharingTokenFromServer(newValue.value.id);
    }

	async function getSharingTokenFromServer(project_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getSharingToken({token: userToken, project_id: project_id});
            if (response.data && !response.data.error) {
                setSharingToken(response.data);
            }
            else if (response.data) {
                toast(response.data.error);
            }
        }
        else {
            redirectToLogin();
        }
    }

	function copyAddress() {
        let text = generateSharingAddress();
        if (text === "") {
            toast("No Address");
            return;
        }
        window.navigator.clipboard.writeText(text).then(function() {
            toast("Copy Address Done");
        }, function(error) {
            toast(error);
        });
    }

	function onClickShowPassword() {
		setShowPassword(!showPassword);
	}

	function checkInput() {
		if (email === "") {
			toast("Email Should be not empty");
			return true;
		}

		if (firstName === "") {
			toast("First Name Should be not empty");
			return true;
		}

		if (lastName === "") {
			toast("Last Name Should be not empty");
			return true;
		}

		if (phonenumber === "") {
			toast("Phone Number Should be not empty");
			return true;
		}

		if (company === "") {
			toast("Organization Should be not empty");
			return true;
		}

		if (country === "") {
			toast("Country Should be not empty");
			return true;
		}
	}

	async function updateProfile() {
		let userToken = localStorage.getItem("userToken");
		if (checkInput()) return;
		
        if (userToken) {
			let response = await api.updateUser({token: userToken, first_name : firstName, last_name: lastName, phone_number: phonenumber, email: email, company: company, country: country});
			if (response.data && !response.data.error) {
				props.setUserInfo(response.data);
				toast("Update Profile succeed");
			}
			else {
				redirectToLogin();
			}
        }
        else {
            redirectToLogin();
        }
	}

	return (
		<div className={clsx(classes.view)}
		>
			<Box sx={{ width: '100%' }}>
				<Box>
					<Tabs value={tabValue} onChange={handleChange} aria-label="Tabs">
					<Tab label="Profile" {...a11yProps(0)} />
					<Tab label="Users" {...a11yProps(1)} />
					<Tab label="Links" {...a11yProps(2)} />
					<Tab label="Roles" {...a11yProps(3)} />
					<Tab label="Billing" {...a11yProps(4)} />
					</Tabs>
				</Box>
				<TabPanel value={tabValue} index={0}>
					{getProfileUI()}
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					{getManageUserUI()}
				</TabPanel>
				<TabPanel value={tabValue} index={2}>
					{getManageLinksUI()}
				</TabPanel>
				<TabPanel value={tabValue} index={3}>
					{getManageRoleUI()}
				</TabPanel>
				<TabPanel value={tabValue} index={4}>
					{getManageBillingUI()}
				</TabPanel>
			</Box>
			<Modal show={isShowingProfile} animation={false} className="modal-profile-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideProfileDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Profile 
						onClose = {onHideProfile}
						isUpdate = {isUpdate}
						isOther = {isOther}
						profileInfo = {profileInfo}
					/>
                </Modal.Body>
            </Modal>
			<Modal show={isUpdatingShare !== 0} animation={false}>
				<Modal.Header>
					<Modal.Title>
						<div className="dialog_title">
							<div className='logo_title'>
								{isUpdatingShare === 1?"Create Link":"Update Link"}
							</div>
							<div className='close_button' onClick={hideSharingDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete="off">
						{isUpdatingShare === 1?
						<div className='project-name-box'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectProject}
								styles={selectStyle}
								options={projectList}
								value = {
									projectList.filter(option => 
									option.value.id + "" === sharingProjectID + "")
								}
							></Select>
						</div>
						:null}
						<div className='project-name-box'>
							<div className='link-name-label'>
								<p className="project-name-title address_label">Address</p>
								<div className="medium_icon can_hover_icon no_boder_line" title="Copy Clipboard" onClick={copyAddress}><div className='icon clipboard_icon'></div></div>
								<a href={generateSharingAddress()} className="project-name-link" target="_blank">{generateSharingAddress()}</a>
							</div>
						</div>
						<div className='project-name-box'>
							<p className="project-name-title">Expire Date</p>
							<DatePicker selected={sharingExpire} onChange={(date) => setSharingExpire(date)} />
						</div>
						<div className='project-name-box'>
							<div className='flex_part'>
								<div className='passwort_input_part'>
									<p className="project-name-title">Passsword</p>
									<div className='flex_part'>
										<input type="text" className={`project-name-input ${showPassword?'':'password_security'}`} value={sharingPassword} onChange={onChangeSharingPassword} autoComplete="off"/>
										<div className={"password_visible_icon can_hover_icon no_boder_line input-usermanagement-password"} onClick={onClickShowPassword}>
											{showPassword?
											<div className='icon visible_icon'></div>:
											<div className='icon invisible_icon'></div>}
										</div>
									</div>

									<p className="project-name-title">Confirm Password</p>
									<div className='flex_part'>
										<input type="text" className={`project-name-input ${showPassword?'':'password_security'}`} value={sharingConfirmPassword} onChange={onChangeSharingConfirmPassword} autoComplete="off"/>
										<div className={"password_visible_icon can_hover_icon no_boder_line input-usermanagement-password"} onClick={onClickShowPassword}>
											{showPassword?
											<div className='icon visible_icon'></div>:
											<div className='icon invisible_icon'></div>}
										</div>
									</div>

									<div className={clsx("form-group", {
										[classes.show]: !passwordMatched,
										[classes.hide]: passwordMatched
									})}>
										<label className="error-label">Password Not Matched.</label>
									</div>
								</div>

								<div className='reset_password_condition share_password_condition'>
									<div>Password must include:</div>
									<div className='password_condition_explain'>
										{matchPasswordString?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>8-20 Characters</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordCapital?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>At least one capital letter</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordSymbol?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>At least one symbol</div>
									</div>
									<div className='password_condition_explain'>
										{matchPasswordSpace?
										<div className='check_image'></div>
										:
										<div className='fail_image'></div>
										}
										<div>No Spaces</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="contained"
					color="secondary" onClick={hideSharingDialog} className={classes.button}>
					Close
				</Button>
				<Button variant="contained"
					color="primary" onClick={isUpdatingShare === 1?createSharing:updateSharing} className={classes.button}>
					{isUpdatingShare === 1?"Create":"Update"}
				</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showWarning} animation={false} className="modal-verify-viewer">
                <Modal.Header>
                    <Modal.Title>
						<div className="dialog_title">
							<div className='logo'>
								<img src="/symbol.png" alt="symbol" style={{height: "100%"}}/>
							</div>
							<div className='close_button' onClick={hideWarningDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='verification_message'>
						To change primary email address, please sign into your billing portal from <a href="http://www.PoleHawk.com" target="_blank">http://www.PoleHawk.com</a>
                    </div>
                </Modal.Body>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
	userInfo: state.global.userInfo,
	allProjects : state.project.allProjects,
});

UserManagement.propTypes = {
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
	connect(mapStateToProps, authActionCreators),
)(UserManagement);