import { createAction } from 'redux-actions';

export const VOLUME_AREA = 'volume/VOLUME_2D_AREA';
export const VOLUME_PERIMETER = 'volume/VOLUME_2D_PERIMETER';
export const VOLUME_MIN_ELEVATION = 'volume/VOLUME_MIN_ELEVATION';
export const VOLUME_MAX_ELEVATION = 'volume/VOLUME_MAX_ELEVATION';
export const VOLUME_ELEVATION_DIFFERENCE = 'volume/VOLUME_ELEVATION_DIFFERENCE';
export const VOLUME_BASE_SURFACE = 'volume/VOLUME_BASE_SURFACE';
export const VOLUME_CUT_VOLUME = 'volume/VOLUME_CUT_VOLUME';
export const VOLUME_CUT_ERROR = 'volume/VOLUME_CUT_ERROR';
export const VOLUME_FILL_VOLUME = 'volume/VOLUME_FILL_VOLUME';
export const VOLUME_FILL_ERROR = 'volume/VOLUME_FILL_ERROR';
export const VOLUME_DIFFERENCE = 'volume/VOLUME_DIFFERENCE';

export const volumeAction = {
    setVolumeArea:         createAction(VOLUME_AREA),
    setVolumePerimeter:    createAction(VOLUME_PERIMETER),
    setVolumeMinElevation:   createAction(VOLUME_MIN_ELEVATION),
    setVolumeMaxElevation:   createAction(VOLUME_MAX_ELEVATION),
    setVolumeElevationDifference: createAction(VOLUME_ELEVATION_DIFFERENCE),
    setVolumeBaseSurface:    createAction(VOLUME_BASE_SURFACE),
    setVolumeCutVolume:      createAction(VOLUME_CUT_VOLUME),
    setVolumeCutError:       createAction(VOLUME_CUT_ERROR),
    setVolumeFillVolume:     createAction(VOLUME_FILL_VOLUME),
    setVolumeFillError:      createAction(VOLUME_FILL_ERROR),
    setVolumeDifference: createAction(VOLUME_DIFFERENCE),
};