import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ProcessLayout from '../Process';
import MapLayout from '../MapLayout';
import ImageLayout from '../ImageLayout';
import LoginLayout from '../Login';
// import SignupLayout from '../Signup';
// import VerifyLayout from '../VerifyLayout';
import MaintenanceLayout from '../MaintenanceLayout';
import DownloadLayout from '../DownloadLayout';
import PropTypes from 'prop-types';
import { globalAction, projectAction } from '../../store/actions';
import { mapAction } from '../../store/actions';
import { authAction } from '../../store/actions';
import * as api from '../../utils/api';

class MainLayout extends React.Component {
	componentDidUpdate() {
		if (this.props.history.location.pathname === "/" && window.loaded) {
			let userToken = localStorage.getItem("userToken");
			if (userToken) {
				// Reset the Login Data for redirect to login
				localStorage.removeItem("userToken");
				localStorage.removeItem("checkTime");
				this.props.setUserInfo({
					"first_name" : "",
					"last_name" : "",
					point_cloud_density: 70,
					enable_annotation_popup: 1,
					measurement_units: "imperial",
					label_property: "default",
					pole_icon: 0,
					style_color: "#929292",
					measurement_snap_on: 1,
					profile_name: "profile1",
					profile1_classification: "",
					profile2_classification: "",
					profile3_classification: "",
					annotation_type_list: "",
					annotation_status_list: "",
				});
				this.props.setDatasetList(new Map());
				this.props.selectProject({
					project : {
						id : -1
					}
				});
			}
		}
		else {
			window.loaded = true;
		}
	}

	componentDidMount() {
		this.checkToken();
	}

	checkClassificationAndUpdate = () => {
		let _classificationList = [...this.props.classificationList];

		for (let i = 0; i <= 255; i ++) {
			if (!_classificationList[i] || _classificationList[i].index !== i) {
				_classificationList.splice(i, 0, { index : i, visible: true, name: 'Feature-' + i, color: "#FFFFFF" });
			}
		}

		this.props.setClassificationList(_classificationList);
	}

	checkToken = async () =>{
		this.checkClassificationAndUpdate();
		if (api.isSharing()) {
			let response = await api.checkMaintenance();
			if (response.data && response.data.maintenance) {
				localStorage.removeItem("userToken");
				localStorage.removeItem("checkTime");
				this.props.history.replace("/maintenance");
			}
			return;
		}
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			let response = await api.checkExpire({token: userToken});
			if (response.data && response.data === "no expire") {
				if (this.props.history.location.pathname === "/") {
					this.props.history.replace("/map");
				}
				return;
			}
			else {
				if (response.data && response.data.verify) {
					localStorage.removeItem("userToken");
					localStorage.removeItem("checkTime");
					this.props.history.replace("/");
					return;
				}
				else if (response.data && response.data.maintenance) {
					localStorage.removeItem("userToken");
					localStorage.removeItem("checkTime");
					this.props.history.replace("/maintenance");
					return;
				}
			}
		}
		else {
			let response = await api.checkMaintenance();
			if (response.data && response.data.maintenance) {
				localStorage.removeItem("userToken");
				localStorage.removeItem("checkTime");
				this.props.history.replace("/maintenance");
				return;
			}
		}
		
		// if (this.props.history.location.pathname !== "/signup") {
		// 	// this.props.history.location.pathname !== "/verify"
		// 	this.props.history.replace("/");
		// }

		this.props.history.replace("/");
	}

	render() {
		return (
			<div className="App">
				<Switch>
					<Route exact path='/' render={() => <LoginLayout />} />
					{/* <Route exact path='/signup' render={() => <SignupLayout />} /> */}
					{/* <Route exact path='/verify' render={() => <VerifyLayout />} />  */}
					<Route exact path='/maintenance' render={() => <MaintenanceLayout />} /> 
					<Route exact path='/upload' render={() => <ProcessLayout />} />
					<Route exact path='/download' render={() => <DownloadLayout />} />
					<Route exact path='/map' render={() => <MapLayout />} />
					<Route exact path='/image/viewer' render={() => <ImageLayout />} />
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	drawerVisibility: false,
	graphCreated: state.map.graphCreated,
	isAuthenticated: state.auth.isAuthenticated,
	classificationList: state.project.classificationList
});

MainLayout.propTypes = {
	drawerVisibility: PropTypes.bool.isRequired,
	graphCreated: PropTypes.bool.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, mapAction),
	connect(mapStateToProps, projectAction),
	connect(mapStateToProps, authAction),
)(MainLayout);