export const initialState = {
	mapType: 1,
	toolType: 0,
	drawingMode: 0,
	imagery3DToggled: false,
	imageryToggled: false,
	terrainToggled: false,
	pointCloudToggled: false,
	selectedColor: "blue",
	selectedGroupName: "",
	isNameChanged: false,
	isElevationActivated: false,
	len2D: "",
	len3D: "",
	minElevation: "",
	maxElevation: "",
	diffElevation: "",
	minSlope: "",
	maxSlope: "",
	diffSlope: "",
	slopePercentage: false,
	graphShow: false,
	graphCreated: false,
	lineSpacing: 1.0,
};

export const graphShow = (state = initialState) => (
	state.graphShow || initialState.graphShow
);

export const graphCreated = (state = initialState) => (
	state.graphCreated || initialState.graphCreated
);

export const selectedMapType = (state = initialState) => (
	state.mapType || initialState.mapType
);

export const selectedToolType = (state = initialState) => (
	state.toolType || initialState.toolType
);

export const selectedDrawingMode = (state = initialState) => (
	state.drawingMode || initialState.drawingMode
);

export const isElevationActivated = (state = initialState) => (
	state.isElevationActivated || initialState.isElevationActivated
);

export const len2D = (state = initialState) => (
	state.len2D !== "" ? state.len2D : initialState.len2D
);

export const len3D = (state = initialState) => (
	state.len3D !== "" ? state.len3D : initialState.len3D
);

export const minElevation = (state = initialState) => (
	state.minElevation !== "" ? state.minElevation : initialState.minElevation
);

export const maxElevation = (state = initialState) => (
	state.maxElevation !== "" ? state.maxElevation : initialState.maxlevation
);

export const diffElevation = (state = initialState) => (
	state.diffElevation !== "" ? state.diffElevation : initialState.diffElevation
);

export const minSlope = (state = initialState) => (
	state.minSlope !== "" ? state.minSlope : initialState.minSlope
);

export const maxSlope = (state = initialState) => (
	state.maxSlope !== "" ? state.maxSlope : initialState.maxSlope
);

export const diffSlope = (state = initialState) => (
	state.diffSlope !== "" ? state.diffSlope : initialState.diffSlope
);

export const slopePercentage = (state = initialState) => (
	state.slopePercentage || initialState.slopePercentage
);