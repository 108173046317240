
import { createAction } from 'redux-actions';

export const TOGGLE_MAP = 'map/TOGGLE_MAP';
export const TOGGLE_TOOL = 'map/TOGGLE_TOOL';
export const SELECT_DRAWING_MODE = 'map/SELECT_DRAWING_MODE';
export const IMAGERY_3D_TOGGLE = 'map/IMAGERY_3D_TOGGLE';
export const IMAGERY_TOGGLE = 'map/IMAGERY_TOGGLE';
export const TERRAIN_TOGGLE = 'map/TERRAIN_TOGGLE';
export const POINT_CLOUD_TOGGLE = 'map/POINT_CLOUD_TOGGLE';
export const SELECT_TINT_COLOR = 'map/SELECT_TINT_COLOR';
export const SELECTED_GROUP_NAME = "map/SELECTED_GROUP_NAME";
export const NOTIFY_NAMES_CHANGED = "map/NOTIFY_NAMES_CHANGED";
export const NOTIFY_ELEVATION_LINE = "map/NOTIFY_ELEVATION_LINE";
export const LEN_2D = "map/LEN_2D";
export const LEN_3D = "map/LEN_3D";
export const MIN_ELEVATION = "map/MIN_ELEVATION";
export const MAX_ELEVATION = "map/MAX_ELEVATION";
export const DIFF_ELEVATION = "map/DIFF_ELEVATION";
export const MIN_SLOPE = "map/MIN_SLOPE";
export const MAX_SLOPE = "map/MAX_SLOPE";
export const DIFF_SLOPE = "map/DIFF_SLOPE";
export const SLOPE_PERCENTAGE = "map/SLOPE_PERCENTAGE";
export const GRAPH_SHOW = "map/GRAPH_SHOW";
export const GRAPH_CREATED = "map/GRAPH_CREATED";
export const LINE_SPACING = "map/LINE_SPACING";

export const mapAction = {
    toggleMap: createAction(TOGGLE_MAP),
    toggleTool: createAction(TOGGLE_TOOL),
    selectDrawingMode: createAction(SELECT_DRAWING_MODE),
    imagery3DToggle: createAction(IMAGERY_3D_TOGGLE),
    imageryToggle: createAction(IMAGERY_TOGGLE),
    terrainToggle: createAction(TERRAIN_TOGGLE),
    pointCloudToggle: createAction(POINT_CLOUD_TOGGLE),
    selectColorMode: createAction(SELECT_TINT_COLOR),
    selectGroupName: createAction(SELECTED_GROUP_NAME),
    notifyNameChanged: createAction(NOTIFY_NAMES_CHANGED),

    /* annotations line related actions */
    notifyElevationCalculate: createAction(NOTIFY_ELEVATION_LINE),
    setLen2D: createAction(LEN_2D),
    setLen3D: createAction(LEN_3D),
    setMinElevation: createAction(MIN_ELEVATION),
    setMaxElevation: createAction(MAX_ELEVATION),
    setDiffElevation: createAction(DIFF_ELEVATION),
    setMinSlope: createAction(MIN_SLOPE),
    setMaxSlope: createAction(MAX_SLOPE),
    setDiffSlope: createAction(DIFF_SLOPE),
    setSlopePercentage: createAction(SLOPE_PERCENTAGE),
    setGraphShow: createAction(GRAPH_SHOW),
    setGraphCreated: createAction(GRAPH_CREATED),
    setLineSpacing: createAction(LINE_SPACING),
};